import React, { Fragment } from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextField,
  BooleanInput,
  TextInput,
  EditButton,
  DeleteButton,
  TabbedForm, 
  FormTab,
  ImageInput,
  ImageField,
  ArrayInput, 
  SimpleFormIterator,
  BooleanField,
  BulkDeleteButton
  // TabbedFormTabs
} from 'react-admin'

const required = (message = 'Some fields are missing') =>
    (value, allValues) => value ? undefined : message;

const less_than_five = (message = 'Rating must be less than 5.0') =>
    (value, allValues) => parseFloat(value) <= 5.0 ? undefined : message;

const isAlpha = str => {
  return /^[a-zA-Z0-9]+$/.test(str);
}

const titleCase = str => {
  var splitStr = '';
  var flag = 0;
  for (var i = 0; i < str.length; i++) {
      if (isAlpha(str[i])) {
        splitStr += flag === 0 ? str[i].toUpperCase() : str[i];
        flag = 1;
      } else {
        flag = 0;
        splitStr += str[i];
      }
  }
  // Directly return the joined string
  return splitStr;
}

const MyBooleanInput = (({ ...props }) => (
  <BooleanInput {...props} label={titleCase(props.source)}/>
));
  

const ProviderFilter = props => (
  <Filter {...props}>
    <TextField label="Search" source="name" alwaysOn />
  </Filter>
)

const PostBulkActionButtons = props => (
  <Fragment>
      {/* Add the default bulk delete action */}
      <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);

export const ProviderList = props => (
  <List {...props} filters={<ProviderFilter />} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="avgRating" />
      <TextField source="numReviews" />
      <BooleanField source="isApproved" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} undoable={false}/>
    </Datagrid>
  </List>
)

export const ProviderCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
      <TextInput source="phone" validate={required()} />
      <TextInput source="avgRating" validate={less_than_five()} />
      <TextInput source="numReviews" validate={required()} />
    </SimpleForm>
  </Create>
)

export const ProviderEdit = props => (
  <Edit {...props}>
    <TabbedForm /*tabs={<TabbedFormTabs scrollable={true} />}*/>
      <FormTab label="About Provider">
        <TextInput source="name" validate={required()} />
        <TextInput source="phone" validate={required()} />
        <TextInput source="avgRating" validate={less_than_five()} />
        <TextInput source="numReviews" validate={required()} />
        <TextInput source="address" validate={required()} />
        <TextInput source="website" />
        <TextInput source="School name" />
        <TextInput source="Year of graduation" />
        <TextInput source="RCDSO certification year" />
        <TextInput source="Specialty" />
        <TextInput source="Memberships and certifications" />
        <MyBooleanInput source="isSmileConnectVerified" />
        <MyBooleanInput source="Biography Card" />
        <MyBooleanInput source="Why Love Card" />
        <MyBooleanInput source="Top 3 Card" />
        <MyBooleanInput source="Procedures Card" />
        <MyBooleanInput source="About Card" />
        <MyBooleanInput source="Office Amenities Card" />
        <MyBooleanInput source="Insurance and Payments Card" />
        <MyBooleanInput source="Badge Card" />
        <MyBooleanInput source="Our location Card" />
        <MyBooleanInput source="Office photos Card" />
        <MyBooleanInput source="Reviews Card" />
        <MyBooleanInput source="Practice Technology Card" />
        <MyBooleanInput source="Hours Card" />
        <TextInput source="weight" />
        <BooleanInput source="isApproved" />
      </FormTab>
      <FormTab label="Important Texts">
        <TextInput multiline source="Biography" label="About US"/>
        <TextInput multiline source="Why do patients love your practice?" label="Why patients love our practice?"/>
        <TextInput multiline source="Top 3 treatments you offer in your practice1"/>
        <TextInput multiline source="Top 3 treatments you offer in your practice2"/>
        <TextInput multiline source="Top 3 treatments you offer in your practice3"/>
      </FormTab>
      <FormTab label="Checkboxes">
        <MyBooleanInput source="Cosmetic (veneers, whitening)" />
        <MyBooleanInput source="Fillings" />
        <MyBooleanInput source="Emergency dental care" />
        <MyBooleanInput source="Roots canals" />
        <MyBooleanInput source="Invisalign" />
        <MyBooleanInput source="Braces" />
        <MyBooleanInput source="Pediatric dentistry" />
        <MyBooleanInput source="Crowns" />
        <MyBooleanInput source="Bridges" />
        <MyBooleanInput source="Dentures" />
        <MyBooleanInput source="Oral surgery- wisdom teeth, impacted teeth" />
        <MyBooleanInput source="Specialists Endo" />
        <MyBooleanInput source="Specialists OMFS" />
        <MyBooleanInput source="Specialists Perio" />
        <MyBooleanInput source="Specialists Prostho" />
        <MyBooleanInput source="Specialists Ortho" />
        <MyBooleanInput source="Specialists Pedo" />
        <MyBooleanInput source="Dental Implants" />
        <MyBooleanInput source="Surgical implant placement" />
        <MyBooleanInput source="bone grafting" />
        <MyBooleanInput source="sinus lifts" />
        <MyBooleanInput source="implant restoration" />
        <MyBooleanInput source="Full-mouth Rehabilitation" />
        <MyBooleanInput source="TMJ Treatment" />
        <MyBooleanInput source="Sleep Apnea Treatment" />
        <MyBooleanInput source="Sedation" />
        <MyBooleanInput source="Periodontal surgery" />
        <MyBooleanInput source="Parking" />
        <MyBooleanInput source="Transit Access" />
        <MyBooleanInput source="Beverages" />
        <MyBooleanInput source="Tv in waiting room" />
        <MyBooleanInput source="Tv in Operatories" />
        <MyBooleanInput source="blankets or pillows" />
        <MyBooleanInput source="Wifi" />
        <MyBooleanInput source="Nitrous sedation" />
        <MyBooleanInput source="Hot towel" />
        <MyBooleanInput source="lip balm" />
        <MyBooleanInput source="handicap accessible washroom" />
        <MyBooleanInput source="kids play area" />
        <MyBooleanInput source="Digital x-rays" />
        <MyBooleanInput source="IntraOral Camera" />
        <MyBooleanInput source="Panoramic X-rays" />
        <MyBooleanInput source="Dental Laser" />
        <MyBooleanInput source="CBCT" />
        <MyBooleanInput source="Digital Scanner (itero)" />
        <MyBooleanInput source="Cerec crowns (same-day crowns)" />
        <MyBooleanInput source="Laser Whitening" />
        <MyBooleanInput source="Paperless practice" />
        <MyBooleanInput source="Direct Billing | Assignment of Benefits" />
        <MyBooleanInput source="In house payment plans" />
        <MyBooleanInput source="3rd party payment plans" />
        <MyBooleanInput source="Ontario Fee guide followed" />
        {/* <MyBooleanInput source="Discounts given with discretion" /> */}
      </FormTab>

      <FormTab label="Credential">
        <TextInput source="email" validate={required()} />
        <TextInput source="password" type="password"/>
      </FormTab>

      <FormTab label="Images">
        <ImageInput source="images" label="Profile Image" placeholder={<p>Click here to upload photos</p>} accept="image/*" multiple>
          <ImageField source="url" title="pathname" />
        </ImageInput>
        <ImageInput source="office_images" label="Office Image" placeholder={<p>Click here to upload photos</p>} accept="image/*" multiple>
          <ImageField source="url" title="pathname" />
        </ImageInput>
      </FormTab>

      <FormTab label="Hours">
        <MyBooleanInput source="weekend appointments" />
        <MyBooleanInput source="evening appointments" />
        <ArrayInput source="hours">
          <SimpleFormIterator disableRemove disableAdd>
              <MyBooleanInput source="availability" />
              <TextInput source="start" />
              <TextInput source="end" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Reviews">
        <ArrayInput source="review">
          <SimpleFormIterator>
              <TextInput source="reviewRating" />
              <TextInput source="reviewerName" />
              <TextInput multiline source="reviewText"/>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>


      <FormTab label="Provider Badge">
        <MyBooleanInput source="Invisalign Gold" />
        <MyBooleanInput source="Invisalign Platinum" />
        <MyBooleanInput source="Invisalign Diamond" />
        <MyBooleanInput source="Patient Favourite" />
        <MyBooleanInput source="Office Amenities" />
        <MyBooleanInput source="High Tech Office" />
        <MyBooleanInput source="Insurance Friendly" />
        <MyBooleanInput source="Kid Friendly" />
        <MyBooleanInput source="Friendly Staff" />
        <MyBooleanInput source="Convenient" />
        <MyBooleanInput source="Advanced Cosmetic Training" />
        <MyBooleanInput source="Advanced Implant Traning" />
        <MyBooleanInput source="Experienced Doctor" />
      </FormTab>

    </TabbedForm>
  </Edit>
)
