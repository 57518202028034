import React from 'react'
import { Box, Paper, Grid } from '@material-ui/core'
import uuid from 'uuid'

const Dot = () => (
  <div style={{
    color: "#EC9545",
    fontSize: "16px",
    display: 'inline',
    marginRight: '16px',

  }}>
    ★
  </div>
)


function ReviewCard(props)
{    
    return (
    <Box my={2}>
      <Paper style={{ backgroundColor: "#F9F9F9"}}>
        <Box p={2}>
          <p style={{ fontSize: '18px', lineHeight: '27px' }}>
            {props.reviewText}
          </p>
          <Grid justify="space-between" container>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              { Array(Math.round(props.reviewRating)).fill().map((_) => (<Dot key={uuid.v4()} />)) }
            </Grid>
            <Grid item>
              <p>{props.reviewerName}</p>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default ReviewCard


