import React from 'react'
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import * as geofirex from 'geofirex'

export const FirebaseContext = React.createContext(null)

export default class Firebase {
  constructor() {
    app.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    })

    this.auth = app.auth()
    this.geo = geofirex.init(app)
    this.db = app.firestore()
    this.storageRef = app.storage().ref()
    this.functions = app.functions()
  }

  getPatient = email => this.db.doc(`patients/${email}`)

  getPatients = () => this.db.collection('patients')

  createPatient = patient => {
    this.getPatient(patient.email).set(patient)
  }

  getAppointment = uid => this.db.doc(`appointments/${uid}`)

  getProvider = email => {
    return this.getProviders().where('email', '==', email).limit(1).get().then(snap => {
      return (snap.docs[0].ref);
    })
  }

  removeEmail = email_remove => {
    this.getProviders()
    .get()
    .then(snap => {
      if (snap.size) {
        snap.forEach(doc => {
          if (doc.data().email === email_remove) {
            var data = doc.data()
            doc.ref.delete()
            this.getProviders().add(data)
          }
        })
      }
    })
  }

  getProviders = () => this.db.collection('providers')

  getSearchedDentist = id => this.db.doc(`search/${id}`)

  getSearchedDentists = () => this.db.collection('search')

  createSearchedDentist = provider => {
    this.getSearchedDentist(provider.id).set(provider)
  }

  createAuthUser = (email, password) => {
    this.auth.createUserWithEmailAndPassword(email, password)
  }

  getUserByEmail = (email) => {
    this.auth.getUserByEmail(email)
  }

  addImagesToModel = (images = [], modelName, id, type = 0) => {
    images.forEach(image => {
      const storageRef = this.storageRef
      const path = `images/${id}/${image.name}`
      const ref = storageRef.child(path)
      ref.put(image).then(() => {
        ref.getDownloadURL().then(
          url =>
            new Promise(resolve => {
              if (type === 0) {
                this.db
                  .doc(`${modelName}/${id}`)
                  .update({
                    images: app.firestore.FieldValue.arrayUnion({
                      ...image,
                      url,
                    }),
                  })
                  .then(() => resolve([...(images || []), { ...image, url }]))
                } else {
                  this.db
                  .doc(`${modelName}/${id}`)
                  .update({
                    office_images: app.firestore.FieldValue.arrayUnion({
                      ...image,
                      url,
                    }),
                  })
                  .then(() => resolve([...(images || []), { ...image, url }]))
                }
              }
            )
        )
      })
    })
  }

  removeImageFromModel = async ({ email }, image) => {
    const storageRef = this.storageRef
    storageRef.child(`images/${email}/${image.path}`).delete()
    const pro = await this.getProvider(email);
    await pro.update({
      images: app.firestore.FieldValue.arrayRemove(image),
    })
  }
}
