import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab
        variant="extended"
        style={{ marginTop: 20, color: 'black', width: '200px', backgroundColor: '#FDED5C', fontWeight: 'bold' }}
        onClick={handleClickOpen}>
        Free $100 Gift Card!
        </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"FREE $100 GIFT CARD!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Claim your $100 gift card when you proceed with Invisalign treatment with a Smile Connect verified doctor!
            Simply log into your account, upload your receipt and we'll send you your gift card!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Grid container justify="flex-end">
                <Grid item xs={12} sm={4}>
                <Button onClick={handleClose} variant="contained" style={{
                    width: '100%',
                    backgroundColor: '#0D1C86',
                    color: 'white',
                    }} autoFocus>
                    Got it
                </Button>
                </Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}