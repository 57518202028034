import React from 'react'
import { Box, ButtonBase } from '@material-ui/core'

function OfficePhoto({ image, width = 120, onDelete }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width={'100%'}
      height={'100%'}
      style={{ position: 'relative', overflow: 'hidden' }}>
      <img
        alt="alt"
        src={typeof image === 'string' ? image : image.url}
        title="alt"
        style={{ width: '100%', height: 'auto' }}
      />
      {onDelete && (
        <ButtonBase
          onClick={onDelete}
          color="primary"
          style={{width: '100px', height: '20px', fontSize: '20px'}}>
          <i className="fa fa-remove"></i>
          Delete
        </ButtonBase>
      )}
    </Box>
  )
}

export default OfficePhoto
