import React, { useState, useRef } from 'react'
import { Button, Box, Typography } from '@material-ui/core'
import { PlacesInput, geocodeByAddress, getLatLng } from '../components/Places'
import './SignupButton.css'

const SignupButton = ({
  onSearch,
  placeholder,
  children,
  style,
  maxWidth = 600,
  label_color = 'white',
  center,
  currentLocation,
}) => {
  const [location, setLocation] = useState('')
  const ref = useRef()

  const onSubmit = e => {
    e.preventDefault()
    location ? onSearch(location) : alert('Enter a location to get started')
  }

  return (
    <form
      onSubmit={onSubmit}
      className={center === true ? "form__width" : ""}>
      <Box
        my={2}
        display="flex"
        maxWidth={maxWidth}
        style={style}
        className="flex-column-mobile">
        <PlacesInput
          placeholder={placeholder}
          onSelect={(location, placeId, redirect) => {
            if (redirect === false) {
              setLocation(location)
            }
            else {
              onSearch(location)
            }
          }}
          onSubmit={onSubmit}
          style={{ flex: 2 }}
        />

        <Button
          variant="contained"
          type="submit"
          className={center === true ? "search_button1" : "search_button"}
          >
          {children}
        </Button>
      </Box>
      <Typography
        align={center ? "center" : "left"}
        variant="body1"
        style={{ color: label_color, fontSize: '12px', cursor: 'pointer', width: 'fit-content', margin: center ? '0 auto' : '' }}
        onClick={() => {
          if (currentLocation) {
            geocodeByAddress(currentLocation)
            .then((results = []) => {
              ref.current = {
                address: results[0],
              }
              return getLatLng(results[0])
            })
            .then(latLng => {
              setLocation({ ...(ref.current || {}), ...latLng })
              onSearch({ ...(ref.current || {}), ...latLng })
            })
          } else {
            //alert('Enter a location to get started')
            var options = {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0
            };
            
            function success(pos) {
              var crd = pos.coords;

              setLocation({ lat: crd.latitude, lng: crd.longitude })
              onSearch({ lat: crd.latitude, lng: crd.longitude })
            }
            
            function error(err) {
              alert(`Cannot get location: ERROR(${err.code}): ${err.message}`);
            }
            
            navigator.geolocation.getCurrentPosition(success, error, options);
          }
        }}>
        Use my current location
      </Typography>
    </form>
  )
}

export default SignupButton
