import React, { useContext } from 'react'
import { Typography, Box, Paper, Button, Grid } from '@material-ui/core'
import { AddPhotoThumbnail } from '../components/QuizQuestion'
import ProfileImage from '../components/ProfileImage'
import { FirebaseContext } from '../utils/base'
import WhiteCard from '../components/WhiteCard'
import { PersonalCard } from '../components/PersonalCard'

export function AppointmentUpload({
  appointments,
  match,
  history,
  providers, 
  searchedDentists, 
  results,
}) {

  const firebase = useContext(FirebaseContext)

  const renderNextSteps = match.params.nextSteps

  if (appointments.length === 0 || providers.length === 0 || searchedDentists.length === 0) {
    return null;
  }
  
  const t_appointment = appointments.filter(
    appointment => appointment.uid === renderNextSteps
  )

  if (!t_appointment) {
    return null
  }

  const appointment = t_appointment[0]

  const provider =
    providers.find(r => r.email === appointment.provider) ||
    results.find(r => r.name === appointment.provider) || searchedDentists.find(r => r.name === appointment.provider)

  if (!provider) {
    return null
  }

  return (
    <Box textAlign="center" pt={3} maxWidth={800} mx="auto">
      <Typography variant="h1" style={{fontWeight: 'bold', fontSize: '40px', lineHeight: '51px', textAlign: 'center', color: '#301EA1', width: '100%'}}>
        Receipt Verification
      </Typography>
      <Typography variant="body1" style={{ fontSize: '20px', lineHeight: '32px', textAlign: 'center',  letterSpacing: '0.01em',  color: '#333333', marginTop: '18px', width: '100%'}}>
      Awesome! Congratulations on taking care of your smile. Take a pic of your receipt from the dentist, click on the “Add photo” button below and upload it here. Press the “SUBMIT RECEIPTS” button and you’re golden!
      </Typography>
      <Box>
        <AppointmentConfirmUpload
          provider={provider}
          appointment={appointment}
          firebase={firebase}
          history={history}
          onSubmit={() => {
            firebase
              .getAppointment(appointment.uid)
              .update({ isUserConfirmed: true })
            history.push(`/appointments`)
          }}
          upload={true}
        />
      </Box>
    </Box>
  )
}

export default function Appointments({
  currentUser = {},
  appointments,
  match,
  history,
  providers, 
  searchedDentists, 
  results,
}) {

  const theirAppointments = appointments.filter(
    appointment => appointment.patient === currentUser.email
  )

    console.log(theirAppointments)
  const renderNextSteps = match.params.nextSteps

  return (
    <Box textAlign="center" pt={3} maxWidth={800} mx="auto">
      {renderNextSteps === 'nextSteps' ? (
        <>
          <WhiteCard>
            <Typography variant="h1" style={{fontWeight: 'bold', fontSize: '40px', lineHeight: '51px', textAlign: 'center', color: '#301EA1', width: '100%'}}>Request Confirmed</Typography>
            <Typography variant="body1" style={{ fontSize: '20px', lineHeight: '32px', textAlign: 'center',  letterSpacing: '0.01em',  color: '#333333', marginTop: '18px', width: '100%'}}>Congratulations on taking the next step on your Smile Journey, your dental professional will be reaching out to book an appointment with you very soon!
            </Typography>
            <Grid container spacing={3} justify="center" style={{marginTop: 30}}>
              <Grid item md={6} sm={6} xs={12}>
                <Button variant="contained" style={{
                  width: '100%',
                  height: '60px',
                  backgroundColor: '#0D1C86',
                  color: 'white',
                }}
                onClick={() => history.push(`/appointments`)}>View request</Button>
              </Grid>

              {/* <Grid item md={6} sm={6} xs={12}>
                  <Button variant="contained" style={{
                    width: '100%',
                    height: '60px',
                    color: '#0D1C86',
                    backgroundColor: 'white',
                    border: '1px solid #0D1C86',
                  }}
                  onClick={() => {
                    history.push(`/quiz`)
                  }}
                  >Retake quiz</Button>
              </Grid> */}
            </Grid>
          </WhiteCard>
          {/* <div style={{marginTop: -15}}>
            <GiftForm/>
          </div> */}
        </>
      ) : ( renderNextSteps === undefined ? (
        
        <>
          <Typography variant="h1" style={{fontWeight: 'bold', fontSize: '40px', lineHeight: '51px', textAlign: 'center', color: '#301EA1', width: '100%'}}>
              My Appointment Requests
          </Typography>
          <Typography variant="body1" style={{ fontSize: '20px', lineHeight: '32px', textAlign: 'center',  letterSpacing: '0.01em',  color: '#333333', marginTop: '18px', width: '100%'}}>
              { "You're a walk-the-walk, talk-the-talk kinda person. We like that! Sit tight and someone from your chosen practice will contact you to book an appointment that fits your schedule." }
          </Typography>
        { 
          theirAppointments.map((appointment, index) => {
            const provider =
              providers.find(provider => provider.email === appointment.provider) ||
              results.find(r => r.name === appointment.provider) || searchedDentists.find(r => r.name === appointment.provider)
          
              if (!provider) {
                return null
              }
              return (<Box key={`appointment-${index}`}>
                <AppointmentConfirmUpload
                  provider={provider}
                  appointment={appointment}
                  history={history}
                />
              </Box>)
          })
        }
        </>
      ) : (
        <AppointmentUpload 
          appointments={appointments}
          match={match}
          history={history}
          providers={providers} 
          searchedDentists={searchedDentists}
          results={results}
        />
      ))}
    </Box>
  )
}

export const AppointmentConfirmUpload = ({
  provider,
  appointment,
  history,
  upload,
  firebase,
  onSubmit,
}) => {
  const images = appointment.images || []

  return (
    <>
    { upload === true && (
      <Box mt={5}>
        <Paper>
          <Box p={2} textAlign="center">
          
            <Box p={3} display="flex" justifyContent="center" flexWrap="wrap">
              {images.map((image, index) => (
                <ProfileImage key={`image-${index}`} image={image} />
              ))}

              {Array((images.length < 5 ? 4 : 8) - images.length)
                .fill()
                .map((_, index) => (
                  <AddPhotoThumbnail
                    key={`photoupload-${index}`}
                    callback={(files = []) => {
                      firebase.addImagesToModel(
                        files,
                        'appointments',
                        appointment.uid
                      )
                    }}
                  />
                ))}
            </Box>
          </Box>

          <Grid container spacing={3} style={{padding: '0 16px'}}>
            <Grid item md={6} sm={6} xs={12}>
              <Button variant="contained" 
                disabled={images.length === 0}
                style={{
                  width: '100%',
                  height: '60px',
                  backgroundColor: '#0D1C86',
                  color: 'white',
                }}
                onClick={onSubmit}
              >Submit receipts</Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )}

    <Box mt={5}>
      <WhiteCard key={`provider-${provider.uid}`}>
        <PersonalCard 
        provider={provider}
        >
          <Grid container spacing={3}>
            <Grid item md={6} sm={6} xs={12}>
              <Button variant="contained" style={{
                width: '100%',
                height: '60px',
                backgroundColor: '#0D1C86',
                color: 'white',
              }}
              onClick={() => history.push(`/search/${provider.name}&-&${provider.address.replace(/#/g, '')}`)}>View full profile</Button>
            </Grid>

            {upload !== true && (<Grid item md={6} sm={6} xs={12}>
                <Button variant="contained" style={{
                  width: '100%',
                  height: '60px',
                  color: '#0D1C86',
                  backgroundColor: 'white',
                  border: '1px solid #0D1C86',
                }}
                onClick={() =>
                  history.push(`/appointments/${appointment.uid}`)}
                >Upload receipt</Button>
            </Grid>
            )}
          </Grid>
        </PersonalCard>
    </WhiteCard>
    </Box>
    </>
  )
}
