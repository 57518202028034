import React, { Fragment } from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  SelectInput,
  ImageInput,
  BooleanField,
  Toolbar,
  SaveButton,
  DeleteButton,
  BulkDeleteButton,
  BooleanInput,
  ImageField,
  FunctionField
} from 'react-admin'

import { withStyles } from '@material-ui/core';


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const imageFieldStyles = {
  removeButton: {
      display: 'none',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const CustomImageInput = withStyles(imageFieldStyles)(props => (
  <ImageInput {...props}>
    {props.children}
  </ImageInput>
))

const AppointmentFilter = props => (
  <Filter {...props}>
    <TextField label="Search" source="q" alwaysOn />
  </Filter>
)

const PostBulkActionButtons = props => (
  <Fragment>
      {/* Add the default bulk delete action */}
      <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);

export const AppointmentList = props => (
  <List {...props} filters={<AppointmentFilter />} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="provider" />
      <TextField source="patient" />
      <TextField source="date"/>
      <BooleanField source="isVerified" />
      <FunctionField label="Patient Status by Doctor" render={record => choices[record.pending].name}/>
      <FunctionField label="Patient Status by Admin" render={record => record.status === undefined ? "" : st_choices[record.status].name}/>
      <BooleanField source="billed" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} undoable={false}/>
    </Datagrid>
  </List>
)

const choices = [
  { _id: 0, name: 'Not Booked'},
  { _id: 1, name: 'Booked Consult'},
  { _id: 2, name: "Patient No Show"},
  { _id: 3, name: "Patient Considering Treatment"},
  { _id: 4, name: "Paid Consult Completed"},
  { _id: 5, name: "Free Consult Completed"},
  { _id: 6, name: "Patient Accepted Treatment"},
  { _id: 7, name: "Treatment Accepted for Post-Paid Consult"},
  { _id: 8, name: "Denied Treatment"},
];

const st_choices = [
  { _id: 0, name: 'Paid Consult Completed'},
  { _id: 1, name: 'Free Consult Completed'},
  { _id: 2, name: 'Verified Invisalign'},
  { _id: 3, name: 'Verified Invisalign Post-Paid Consult'},
];

export const AppointmentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="provider" label="Provider Email"/>
      <TextInput source="patient" label="Patient Email"/>
      <TextInput source="date"/>
    </SimpleForm>
  </Create>
)

export const AppointmentEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="provider" label="Provider Email"/>
      <TextInput source="patient" label="Patient Email"/>
      <SelectInput source="status" label="Patient Status by Admin" choices={st_choices}  optionText="name" optionValue="_id" />
      <CustomImageInput source="images" label="Receipts" placeholder={<p>Click here to upload photos</p>} accept="image/*" multiple>
        <ImageField source="url" title="path"/>
      </CustomImageInput>
      <TextInput source="date"/>
      <BooleanInput source="billed"/>
      <TextInput source="approver"/>
    </SimpleForm>
  </Edit>
)
