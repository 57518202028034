import React, { Fragment } from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextField,
  BooleanInput,
  TextInput,
  EditButton,
  DeleteButton,
  RadioButtonGroupInput,
  Toolbar,
  SaveButton,
  BulkDeleteButton
} from 'react-admin'
import {QUESTIONS} from '../constants'
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const PatientFilter = props => (
  <Filter {...props}>
    <TextField label="Search" source="name" alwaysOn />
  </Filter>
)

const PostBulkActionButtons = props => (
  <Fragment>
      {/* Add the default bulk delete action */}
      <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);

export const PatientList = props => (
  <List {...props} filters={<PatientFilter />} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="email" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} undoable={false}/>
    </Datagrid>
  </List>
)

export const PatientCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="password" type="password"/>
      <TextField source="address" />
    </SimpleForm>
  </Create>
)

export const PatientEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="password" type="password"/>
      <TextInput source="address" />
      {
        QUESTIONS.map((question, index) => {
          return <RadioButtonGroupInput key={`questionIndex-${index}`} source={question.name} choices={question.choices.map((choice, index) => ({choice, index: index.toString()}))} optionText="choice" optionValue="index" />
        })
      }
      <BooleanInput source="completedQuiz" />
      <BooleanInput label="isApproved" source="isApproved" />
    </SimpleForm>
  </Edit>
)
