import React from 'react'
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'

const ProviderSignUpContainer = ({ history }) => {
  return (
    <>
      <Typography variant="h6">
        We have created a simple pricing structure for you
      </Typography>

      <Typography variant="body1">
        Grow your practice by joining a network of dentists that deliver
        exceptional patient experiences.
      </Typography>
      <Box my={4} display="flex" className="flex-column-mobile">
        <Package
          heading="Listing fee"
          title="$100/month"
          body="Grow your practice by joining a network of dentists that deliver exceptional patient experiences."
        />
        <Package
          heading="Invisalign patient"
          title="$XX/month"
          body="Grow your practice by joining a network of dentists that deliver exceptional patient experiences."
        />
        <Package
          heading="General dentist patient"
          title="$XX/month"
          body="Grow your practice by joining a network of dentists that deliver exceptional patient experiences."
        />
      </Box>

      <Typography variant="body1">
        You can click below to proceed for now, payment will be added in the
        near future
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push('/pro/dashboard')}
        style={{ marginTop: 30 }}>
        Continue
      </Button>
    </>
  )
}

export default ProviderSignUpContainer

const Package = ({ heading, title, body }) => (
  <Card style={{ margin: 10 }}>
    <CardContent>
      <Typography variant="h5">{heading}</Typography>
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body1">{body}</Typography>
    </CardContent>
    <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions>
  </Card>
)
