import React, { useState, useContext, useEffect } from 'react'
import { format } from 'date-fns'
import ProfileImage from '../components/ProfileImage'
import TextField from '../components/FilledTextField'
import MultiLineTextField from '../components/FilledMultilineTextField'
import { FirebaseContext } from '../utils/base'
import { 
  Paper, 
  Typography, 
  Box, 
  Button, 
  Grid, 
  TextField as CoreTextField,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
// import ProviderOfficeCard, {
//   OfficeCreator,
// } from '../components/ProviderOfficeCard'
import pick from 'lodash/pick'
import Checkboxes from '../components/Checkboxes'
import { AddPhotoThumbnail } from '../components/QuizQuestion'
import WhiteCard from '../components/WhiteCard'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import OfficePhoto from '../components/OfficePhoto'
import { 
  PROCEDURES, 
  OFFICE_AMENITIES, 
  INSECURE_AND_PAYMENTS,
  PRACTICE_TECHNOLOGY,
  HOURS_APPOINTMENTS,
  QUESTIONS,
  ABOUT_DENTIST,
} from '../constants'
import './ProviderDashboard.css'
import { DefaultRadio, ImageRadio, ImageCheck } from './Quiz'
import GrayCard from '../components/GrayCard'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#EDEDED',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 120,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '80%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
 }));

const ProviderDashboardContainer = ({
  currentUser = {},
  appointments,
  offices,
  patients,
  match,
  history,
}) => {

  console.log(currentUser, appointments)

  return (
    <Box maxWidth={1400} mx="auto">
      <Box textAlign="center" pt={3} maxWidth={800} mx="auto" style={{display: !currentUser.isApproved ? 'block' : 'none'}}>
        <WhiteCard>
          <Typography variant="h1" style={{fontWeight: 'bold', fontSize: '40px', lineHeight: '51px', textAlign: 'center', color: '#301EA1', width: '100%'}}>
          Thanks for signing up
          </Typography>
          <Typography variant="body1" style={{ fontSize: '20px', lineHeight: '32px', textAlign: 'center',  letterSpacing: '0.01em',  color: '#333333', marginTop: '18px', width: '100%'}}>
          Thank-you for signing up, you'll be notified once your profile is approved
          </Typography>
        </WhiteCard>
      </Box>
      <ProviderDashboard
        provider={currentUser}
        appointments={appointments.filter(a => a.provider === currentUser.email)}
        patients={patients}
        offices={offices.filter(office => office.provider === currentUser.email)}
        match={match}
        history={history}
      />
    </Box>
  )
}

export default ProviderDashboardContainer

const UpdateField = ({ provider, keyName, handleChange, children }) => {

  const spacedLabel = keyName.replace(/([A-Z])/g, ' $1')
  const capitalizedLabel =
    spacedLabel.charAt(0).toUpperCase() + spacedLabel.slice(1)

  return children({
    value: provider[keyName],
    label: capitalizedLabel,
    onChange: handleChange(`${keyName}`)
  })
}

function ProviderDashboard({ patients, provider = {}, appointments, offices, match, history, }) {
  const firebase = useContext(FirebaseContext)

  const [state, setState] = useState({ value: 0, id: -1 })

  useEffect(() => {
    const renderRedirect = match.params.appointments
    if (renderRedirect === 'appointments') {
      setState({value: 1, id: -1})
    } else if (renderRedirect === undefined) {
      setState({value: 0, id: -1})
    } else if (renderRedirect.includes('id=')) {
      setState({value: 1, id: Number(renderRedirect.split('id=')[1])})
    }
  }, [match.params.appointments, state.value, state.id])


  // const addLocation = ({ name, lat, lng, ...props }) => {
  //   const offices = firebase.geo.collection('offices')
  //   const point = firebase.geo.point(lat, lng)
  //   offices.add({ position: point.data, ...props })
  // }

  // const updateState = update => setState(state => ({ ...state, ...update }))


  return (
    <div style={{position: 'relative'}}>
      <div style={{height: '36px'}}>
        &nbsp;
        {/* <Button onClick={() => history.push('/pro/dashboard')}>Profile</Button> */}
      </div>

      {state.value === 0 && (
        <ProviderEdit
          sprovider={provider}
          onDeleteImage={image => {
            firebase.removeImageFromModel(provider, image)
          }}
        />
      )}
      {state.value === 1 && (
        <ProviderAppointmentsList
          patients={patients}
          firebase={firebase}
          provider={provider}
          appointments={appointments}
          appointment_id={state.id}
          history={history}
        />
      )}
      {/* {state.value === 2 && (
        <ProviderOfficeList
          state={state}
          offices={offices}
          provider={provider}
          addLocation={addLocation}
          onSelect={update => updateState(update)}
          onDelete={office => firebase.db.doc(`offices/${office.uid}`).delete()}
        />
      )} */}
    </div>
  )
}

const ImageUploader = ({ provider, circle, type = 0 }) => {
  const firebase = useContext(FirebaseContext)

  return (
    <AddPhotoThumbnail
      callback={acceptedFiles => {
        firebase.addImagesToModel(acceptedFiles, 'providers', provider.uid, type)
      }}
      circle={circle}
    />
  )
}

function ProviderAppointmentsList({
  patients,
  provider,
  firebase,
  appointments,
  appointment_id,
  history,
}) {

  const classes = useStyles();
  const [step, setStep] = useState(appointment_id)
  const [patientStatus, setPatientStatus] = useState(step >= 0 ? (appointments[step].pending === undefined ? 0 : appointments[step].pending) : -1)

  useEffect(() => {
    setStep(appointment_id)
    setPatientStatus(appointment_id >= 0 ? (appointments[appointment_id].pending === undefined ? 0 : appointments[appointment_id].pending) : -1)
  }, [appointment_id, appointments])


  const status = [
    "Not Booked",
    "Booked Consult",
    "Patient No Show",
    "Patient Considering Treatment",
    "Paid Consult Completed",
    "Free Consult Completed",
    "Patient Accepted Treatment",
    "Treatment Accepted for Post-Paid Consult",
    "Patient Denied Treatment",
  ];

  function handleChange(event) {
    setPatientStatus(event.target.value)
  }

  if (step === -1) {
  return (
    <Box>
      <Typography
        variant="h5"
        style={{
          marginBottom: 15,
        }}>
        Invisalign patients
      </Typography>

      <Box display="flex">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Patient Name</StyledTableCell>
              <StyledTableCell>Date requested</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone number</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.map((row, index) => {
              const patient = patients.find(patient => patient.email === row.patient);
              return patient ? (
              <StyledTableRow key={row.uid} onClick= {() => {
                                                              // setStep(index)
                                                              // setPatientStatus(row.pending)
                                                              history.push(`/pro/dashboard/id=${index}`)
                                                              }}>
                <StyledTableCell component="th" scope="row">
                  {patient.name}
                </StyledTableCell>
                <StyledTableCell>{format(row.date ? new Date(row.date) : new Date(), 'MMMM do, yyyy')}</StyledTableCell>
                <StyledTableCell>{patient.email}</StyledTableCell>
                <StyledTableCell>{patient.phone}</StyledTableCell>
                <StyledTableCell>{status[row.pending]}</StyledTableCell>
              </StyledTableRow>) : null
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
  } 
  
  const appointment = appointments[step]
  const currentPatient = patients.find(patient => patient.email === appointment.patient)
  const questions = QUESTIONS
  // const patient_photos = appointment.images || []

  return (
    <div>
      <div>
      <Typography
          variant="h4"
          style={{
            marginBottom: 15,
          }}>
          {currentPatient.name}
        </Typography>
      <p className="gray_paragraph">{appointment.date}</p>
      <p className="gray_paragraph">{currentPatient.email}</p>
      <p className="gray_paragraph">{currentPatient.phone}</p>

      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container alignItems="center">
            <Grid item md={4} sm={6} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="patient-status">Set patient status</InputLabel>
                <Select
                  value={patientStatus}
                  onChange={handleChange}
                  inputProps={{
                    name: 'status',
                    id: 'patient-status',
                  }}
                >
                  {
                    status.map((text, index) => <MenuItem value={index} key={`patient-status-item-${index}`}>{text}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Button variant="contained" style={{
                backgroundColor: '#0D1C86',
                color: 'white',
              }}
              onClick={() => {
                // SEND EMAIL TO USER ABOUT GIFTCARD

                firebase
                  .getAppointment(appointment.uid)
                  .update({ 
                    isConfirmed: true,
                    pending: patientStatus,
                  })
                history.push('/pro/dashboard/appointments')
            }}>Save Changes</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          {/* <WhiteCard title="Patient photos">
            <Grid container spacing={4}>
                {patient_photos.map((image, index) => (
                  <Grid item lg={3} md={3} sm={6} xs={12} key={`image-${index}`}>
                    <OfficePhoto
                      image={image}
                    />
                  </Grid>
                ))}
              </Grid>
          </WhiteCard> */}
          <WhiteCard title="Invisalign quiz answers">
            <GrayCard title={questions[0].heading}>
              <Grid container spacing={2}>
                {
                  questions[0].choices.map((choice, index) => (
                    <DefaultRadio key={`radios__${choice}__${index}`} questions={questions} checked={currentPatient.status} i={0} j={index} />
                  ))
                }
              </Grid>
            </GrayCard>

            <GrayCard title={questions[1].heading}>
              <Grid container spacing={2}>
              <ImageCheck questions={questions} onChange={handleChange} checked={currentPatient.solution} i={1} j={0}>
                <img alt="teeth" src="/images/image1.png" width="250" height="150"/>
              </ImageCheck>
              <ImageCheck questions={questions} onChange={handleChange} checked={currentPatient.solution} i={1} j={1}>
                <img alt="teeth" src="/images/image2.png" width="250" height="150"/>
              </ImageCheck>
              <ImageCheck questions={questions} onChange={handleChange} checked={currentPatient.solution} i={1} j={2}>
                <img alt="teeth" src="/images/image3.png" width="250" height="150"/>
              </ImageCheck>
              <ImageCheck questions={questions} onChange={handleChange} checked={currentPatient.solution} i={1} j={3}>
                <img alt="teeth" src="/images/image4.png" width="250" height="150"/>
              </ImageCheck>
              <ImageCheck questions={questions} onChange={handleChange} checked={currentPatient.solution} i={1} j={4}>
                <img alt="teeth" src="/images/image5.png" width="250" height="150"/>
              </ImageCheck>
              <ImageCheck questions={questions} onChange={handleChange} checked={currentPatient.solution} i={1} j={5}>
                <img alt="teeth" src="/images/image6.png" width="250" height="150"/>
              </ImageCheck>
              <ImageCheck questions={questions} onChange={handleChange} checked={currentPatient.solution} i={1} j={6}>
                <div style={{width: '93%', height: '150px', paddingRight: '10px', textAlign: 'center', backgroundColor: '#23221e'}}>
                  <img alt="teeth" src="/images/unsure.png" height="120" style={{paddingTop: '15px'}}/>
                </div>
              </ImageCheck>
              </Grid>
            </GrayCard>

            <GrayCard title={questions[2].heading}>
              <Grid container spacing={2}>
                {
                  questions[2].choices.map((choice, index) => (
                    <DefaultRadio key={`radios__${choice}__${index}`} questions={questions} checked={currentPatient.status} i={2} j={index} />
                  ))
                }
              </Grid>
            </GrayCard>

            {/* <GrayCard title={questions[3].heading}>
              <Grid container spacing={2}>
                {
                  questions[3].choices.map((choice, index) => (
                    <DefaultCheck key={`radios__${choice}__${index}`} questions={questions} checked={currentPatient.plan} i={3} j={index} />
                  ))
                }
              </Grid>
            </GrayCard> */}

            <GrayCard title={questions[4].heading}>
              <Grid container spacing={2}>
                {
                  questions[4].choices.map((choice, index) => (
                    <DefaultRadio key={`radios__${choice}__${index}`} questions={questions} checked={currentPatient.experience} i={4} j={index} />
                  ))
                }
              </Grid>
            </GrayCard>

            <GrayCard title={questions[5].heading}>
              <Grid container spacing={2}>
                {
                  questions[5].choices.map((choice, index) => (
                    <DefaultRadio key={`radios__${choice}__${index}`} questions={questions} checked={currentPatient.history} i={5} j={index} />
                  ))
                }
              </Grid>
            </GrayCard>

            {/* <GrayCard title={questions[6].heading}>
              <Grid container spacing={2}>
                {
                  questions[6].choices.map((choice, index) => (
                    <DefaultRadio key={`radios__${choice}__${index}`} questions={questions} checked={currentPatient.time} i={6} j={index} />
                  ))
                }
              </Grid>
            </GrayCard> */}

            <GrayCard title={questions[7].heading}>
              <Grid container spacing={2}>
                {
                  questions[7].choices.map((choice, index) => (
                    <DefaultRadio key={`radios__${choice}__${index}`} questions={questions} checked={currentPatient.insurance} i={7} j={index} />
                  ))
                }
              </Grid>
            </GrayCard>

            {currentPatient.insurance !== '1' && <GrayCard title={questions[8].heading}>
              <Grid container spacing={2}>
                <ImageRadio questions={questions} checked={currentPatient.provider} i={8} j={0} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                    <img alt="teeth" src="/images/insurance1.png"/>
                  </div>
                </ImageRadio>
                <ImageRadio questions={questions} checked={currentPatient.provider} i={8} j={1} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                  <img alt="teeth" src="/images/insurance2.png"/>
                  </div>
                </ImageRadio>
                <ImageRadio questions={questions} checked={currentPatient.provider} i={8} j={2} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                  <img alt="teeth" src="/images/insurance3.png"/>
                  </div>
                </ImageRadio>
                <ImageRadio questions={questions} checked={currentPatient.provider} i={8} j={3} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                  <img alt="teeth" src="/images/insurance4.svg"/>
                  </div>
                </ImageRadio>
                <ImageRadio questions={questions} checked={currentPatient.provider} i={8} j={4} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                  <img alt="teeth" src="/images/insurance5.svg"/>
                  </div>
                </ImageRadio>
                <ImageRadio questions={questions} checked={currentPatient.provider} i={8} j={5} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                  <img alt="teeth" src="/images/insurance6.svg"/>
                  </div>
                </ImageRadio>
                <ImageRadio questions={questions} checked={currentPatient.provider} i={8} j={6} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                  <img alt="teeth" src="/images/icon-question.svg"/>
                  </div>
                </ImageRadio>
                <ImageRadio questions={questions} checked={currentPatient.provider.length === 1 ? currentPatient.provider : 7} i={8} j={7} insurace={true}>
                  <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
                  <img width="50px" alt="teeth" src="/images/other.png"/>
                  </div>
                </ImageRadio>
              </Grid>
            </GrayCard>}
          </WhiteCard>
        </Grid>
      </Grid>
      
      </div>
    </div>
  )
}

// function ProviderOfficeList({
//   provider,
//   offices,
//   addLocation,
//   onDelete,
//   onSelect,
//   state,
// }) {
//   return (
//     <Box>
//       <Typography variant="h5" style={{ marginBottom: 15 }}>
//         Offices
//       </Typography>

//       <Box display="flex">
//         {offices.map(office => (
//           <ProviderOfficeCard
//             key={`office-${office.uid}`}
//             onDelete={() => onDelete(office)}>
//             {office.formatted_address}
//           </ProviderOfficeCard>
//         ))}
//         <OfficeCreator
//           onSubmit={e => {
//             e.preventDefault()

//             if (state.address && state.lat && state.lng) {
//               addLocation({
//                 address_components: JSON.stringify(
//                   state.address.address_components
//                 ),
//                 formatted_address: state.address.formatted_address,
//                 place_id: state.address.place_id,
//                 lat: state.lat,
//                 lng: state.lng,
//                 provider: provider.email,
//               })
//             }
//           }}
//           onSelect={onSelect}
//         />
//       </Box>
//     </Box>
//   )
// }

function ProviderEdit({ sprovider ,onDeleteImage }) {
  const firebase = useContext(FirebaseContext)
  const [provider, setProvider] = useState({...sprovider, website: sprovider.website ? sprovider.website : ''})
  
  Object.keys(ABOUT_DENTIST).forEach(key => {
    if (provider[key] === undefined) {
      setProvider({ ...provider, [key]: ''})
    }
  })
  const images = provider.images || []
  const office_images = provider.office_images || []
  const classes = useStyles();
  const weekday = [
    "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"
  ]

  const state = {
    checked_weekday: [
      provider.hours[0].availability === false || provider.hours[0].availability === 'false' ? false : true,
      provider.hours[1].availability === false || provider.hours[1].availability === 'false' ? false : true,
      provider.hours[2].availability === false || provider.hours[2].availability === 'false' ? false : true,
      provider.hours[3].availability === false || provider.hours[3].availability === 'false' ? false : true,
      provider.hours[4].availability === false || provider.hours[4].availability === 'false' ? false : true,
      provider.hours[5].availability === false || provider.hours[5].availability === 'false' ? false : true,
      provider.hours[6].availability === false || provider.hours[6].availability === 'false' ? false : true,
    ],
    start: [
      formatted_time(provider.hours[0].start),
      formatted_time(provider.hours[1].start),
      formatted_time(provider.hours[2].start),
      formatted_time(provider.hours[3].start),
      formatted_time(provider.hours[4].start),
      formatted_time(provider.hours[5].start),
      formatted_time(provider.hours[6].start),
    ],
    end: [
      formatted_time(provider.hours[0].end),
      formatted_time(provider.hours[1].end),
      formatted_time(provider.hours[2].end),
      formatted_time(provider.hours[3].end),
      formatted_time(provider.hours[4].end),
      formatted_time(provider.hours[5].end),
      formatted_time(provider.hours[6].end),
    ]
  }

  const isAlpha = str => {
    return /^[a-zA-Z0-9]+$/.test(str);
  }

  const titleCase = str => {
    var splitStr = '';
    var flag = 0;
    for (var i = 0; i < str.length; i++) {
        if (isAlpha(str[i])) {
          splitStr += flag === 0 ? str[i].toUpperCase() : str[i];
          flag = 1;
        } else {
          flag = 0;
          splitStr += str[i];
        }
    }
    // Directly return the joined string
    return splitStr;
 }

  const handleChange = name => event => {
    setProvider({ ...provider, [name]: event.target.value });
  };

  const handleChangeCheck = name => event => {
    setProvider({ ...provider, [name]: event.target.checked });
  };

  const UpdateWeekday = (provider, index) => {
    var copy_value = provider.hours
    return {
      onChange: e => {
        copy_value[index].availability = !copy_value[index].availability
        setProvider({ ...provider, hours: copy_value})
      },
      onChangeStart: e => {
        copy_value[index].start = unformatted_time(e.target.value);
        setProvider({ ...provider, hours: copy_value})
      },
      onChangeEnd: e => {
        copy_value[index].end = unformatted_time(e.target.value);
        setProvider({ ...provider, hours: copy_value})
      },
    }
  }

  return (
    <>
    <Button className="save_button"
    onClick={async () => {
      const pro = await firebase.getProvider(provider.email)
      await pro.update({...provider})
    }}>Save</Button>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <WhiteCard title={"Profile Image"}>
          <Box display="flex">
            {images.map((image, index) => (
              <ProfileImage
                key={`profile_image-${index}`}
                image={image}
                onDelete={() => onDeleteImage(image, 0)}
              />
            ))}
            <ImageUploader provider={provider} circle={true}/>
          </Box>
        </WhiteCard>

        {/* About you */}
        <WhiteCard title={"About you"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <UpdateField provider={provider} keyName="name" handleChange={handleChange}>
                {props => <TextField fullWidth {...props} />}
              </UpdateField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <UpdateField provider={provider} keyName="email" handleChange={handleChange}>
                {props => <TextField fullWidth {...props} />}
              </UpdateField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <UpdateField provider={provider} keyName="address" handleChange={handleChange}>
                {props => <TextField fullWidth {...props} />}
              </UpdateField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <UpdateField provider={provider} keyName="website" handleChange={handleChange}>
                {props => <TextField fullWidth {...props} />}
              </UpdateField>
            </Grid>
          </Grid>
        </WhiteCard>
        
        {/* Biography Card */}
        <WhiteCard title={"About US"}>
          <UpdateField provider={provider} keyName="Biography" handleChange={handleChange}>
            {props => <MultiLineTextField fullWidth {...props} />}
          </UpdateField>
        </WhiteCard>
        
        {/* Why Card */}
        <WhiteCard title={"Why patients love our practice?"}>
          <UpdateField provider={provider} keyName="Why do patients love your practice?" handleChange={handleChange}>
            {props => <MultiLineTextField fullWidth {...props} />}
          </UpdateField>
        </WhiteCard>

        {/* Top3 Card */}
        <WhiteCard title={"Top Treatments Offered"}>
          <UpdateField provider={provider} keyName="Top 3 treatments you offer in your practice1" handleChange={handleChange}>
            {props => <MultiLineTextField row={3} fullWidth {...props} />}
          </UpdateField>
          <UpdateField provider={provider} keyName="Top 3 treatments you offer in your practice2" handleChange={handleChange}>
            {props => <MultiLineTextField row={3} fullWidth {...props} />}
          </UpdateField>
          <UpdateField provider={provider} keyName="Top 3 treatments you offer in your practice3" handleChange={handleChange}>
            {props => <MultiLineTextField row={3} fullWidth {...props} />}
          </UpdateField>
        </WhiteCard>
          
        {/* Procedures Card */}
        <WhiteCard title={"Procedures"}>
          <Checkboxes
            boxes={Object.entries(pick(provider, Object.keys(PROCEDURES))).map(
              ([label, value]) => ({ label: titleCase(label), value: value === 'true' || value === true, onChange: handleChangeCheck(label), checked: provider[label] === 'true' || provider[label] === true})
            )}
          />
        </WhiteCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>

        {/* Office photos Card */}
        <WhiteCard title={"Office photos"}>
          <Grid container spacing={4}>
            {office_images.map((image, index) => (
              <Grid item lg={6} md={6} sm={6} xs={12} key={`grid-${index}`}>
                <OfficePhoto
                  key={`office_image-${index}`}
                  image={image}
                  onDelete={() => onDeleteImage(image, 1)}
                />
              </Grid>
            ))}
            <Grid item lg={6} md={6} sm={6} xs={12} key={`imageuploader`}>
              <ImageUploader provider={provider} type={1}/>
            </Grid>
          </Grid>
        </WhiteCard>


        {/* Practice Technology Card */}
        <WhiteCard title={"Practice Technology"}>
          <Checkboxes
            boxes={Object.entries(pick(provider, Object.keys(PRACTICE_TECHNOLOGY))).map(
              ([label, value]) => ({ label: titleCase(label), value: value === 'true' || value === true, onChange: handleChangeCheck(label), checked: provider[label] === 'true' || provider[label] === true})
            )}
          />
        </WhiteCard>

        {/* About the dentist Card */}
        <WhiteCard title={"About the dentist"}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <UpdateField provider={provider} keyName="Year of graduation" handleChange={handleChange}>
                  {props => <TextField fullWidth {...props} />}
                </UpdateField>
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <UpdateField provider={provider} keyName="School name" handleChange={handleChange}>
                  {props => <TextField fullWidth {...props} />}
                </UpdateField>
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <UpdateField provider={provider} keyName="RCDSO certification year" handleChange={handleChange}>
                  {props => <TextField fullWidth {...props} />}
                </UpdateField>
              </Grid>

              <Grid item lg={4} md={12} sm={12} xs={12}>
                <UpdateField provider={provider} keyName="Specialty" handleChange={handleChange}>
                  {props => <TextField fullWidth {...props} />}
                </UpdateField>
              </Grid>
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <UpdateField provider={provider} keyName="Memberships and certifications" handleChange={handleChange}>
                  {props => <TextField fullWidth {...props} />}
                </UpdateField>
              </Grid>
            </Grid>
          </WhiteCard>

          {/* Office Amenities Card */}
          <WhiteCard title={"Office Amenities"}>
            <Checkboxes
              boxes={Object.entries(pick(provider, Object.keys(OFFICE_AMENITIES))).map(
                ([label, value]) => ({ label: titleCase(label), value: value === 'true' || value === true, onChange: handleChangeCheck(label), checked: provider[label] === 'true' || provider[label] === true})
              )}
            />
          </WhiteCard>
        
          {/* Insurance and Payments Card */}
          <WhiteCard title={"Insurance and Payments"}>
            <Checkboxes
              boxes={Object.entries(pick(provider, Object.keys(INSECURE_AND_PAYMENTS))).map(
                ([label, value]) => ({ label: titleCase(label), value: value === 'true' || value === true, onChange: handleChangeCheck(label), checked: provider[label] === 'true' || provider[label] === true})
              )}
            />
          </WhiteCard>
        {/* Hours Card */}
        <WhiteCard title={"Hours"}>
            <Checkboxes
                boxes={Object.entries(pick(provider, Object.keys(HOURS_APPOINTMENTS))).map(
                  ([label, value]) => ({ label: titleCase(label), value: value === 'true' || value === true, onChange: handleChangeCheck(label), checked: provider[label] === 'true' || provider[label] === true})
                )}
                onlyone={true}
            />

            <Grid container direction="column">
            {
              weekday.map((day, index) => (
                <Grid item key={`day-${index}`}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={state.checked_weekday[index]} onChange={UpdateWeekday(provider, index).onChange} value={day} color="primary"/>
                    }
                    label={day}
                    style={{width: '80px'}}
                  />

                  <CoreTextField
                    key={`day-hour-${index}`}
                    label="Open Time"
                    type="time"
                    disabled={!state.checked_weekday[index]}
                    value={state.start[index]}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    onChange={UpdateWeekday(provider, index).onChangeStart}
                  />

                  -

                  <CoreTextField
                    key={`day-hour1-${index}`}
                    label="Close Time"
                    type="time"
                    disabled={!state.checked_weekday[index]}
                    value={state.end[index]}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    onChange={UpdateWeekday(provider, index).onChangeEnd}
                  />
                </Grid>
              ))
            }
          </Grid>
        </WhiteCard>
      </Grid>

    </Grid>
    </>
  )
}

function formatted_time(str) {
  const timeString = str;
  const d = new Date('1970-01-01 '+ timeString);

  var hr = d.getHours();
  var min = d.getMinutes();
  if (hr < 10) {
    hr = '0' + hr;
  }
  if (min < 10) {
    min = '0' + min;
  }

  return hr + ':' + min;
}

function unformatted_time(str) {
  const timeString = str;
  const d = new Date('1970-01-01 '+ timeString);

  var hr = d.getHours();
  var min = d.getMinutes();
  var ampm = 'am';
  if (hr > 12) {
    hr -= 12;
    ampm = 'pm';
  } else if (hr === 12) {
    ampm = 'pm';
  }

  if (min < 10) {
    min = '0' + min;
  }

  return hr + ':' + min + ' ' + ampm;
}

export const AppointmentProviderConfirm = ({
  appointment,
  patient,
  onSubmit,
}) => {
  const images = appointment.images || []

  if (appointment.isConfirmed) {
    return (
      <Box mt={5} maxWidth={800} mx="auto">
        <Paper>
          <Box p={2} textAlign="center">
            <Typography variant="body1">
              Your appointment with {patient.name} at{' '}
              {format(appointment.date.toDate(), 'MMMM do hh:mm a')} was
              confirmed
            </Typography>

            <Box p={3} display="flex" justifyContent="center" flexWrap="wrap">
              {images.map((image, index) => (
                <ProfileImage key={`image-${index}`} image={image} />
              ))}
            </Box>
          </Box>
        </Paper>
      </Box>
    )
  }

  return (
    <Box mt={5} maxWidth={800} mx="auto">
      <Paper>
        <Box p={2} textAlign="center">
          <Typography variant="h5">
            Your appointment with {patient.name} at{' '}
            {format(appointment.date.toDate(), 'MMMM do hh:mm a')}
          </Typography>

          <Box p={3} display="flex" justifyContent="center" flexWrap="wrap">
            {images.map((image, index) => (
              <ProfileImage key={`image-${index}`} image={image} />
            ))}
          </Box>
        </Box>
      </Paper>

      <Box display="flex" my={3} justifyContent="space-between">
        <Button
          fullWidth
          disabled={!appointment.isUserConfirmed}
          variant="contained"
          color="primary"
          onClick={onSubmit}>
          Confirm appointment
        </Button>
      </Box>
    </Box>
  )
}
