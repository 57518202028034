import React from 'react'
import { Box } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'

function render(map, maps, address) {
    const geocoder = new maps.Geocoder();
    geocoder.geocode({'address': address}, function(results, status)  {
        if (status === 'OK') {
            // eslint-disable-next-line
            let marker = new maps.Marker({
                position: results[0].geometry.location,
                map,
            });

            map.setCenter(results[0].geometry.location);
        } else {
            console.log("Geocoder failed")
        }
    })
}

function LocationCard(props) {
    const { location } = props
    const defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33,
        },
        zoom: 14,
    }

    return (
        <Box>
            <p>{location}</p>
            <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_FIREBASE_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onGoogleApiLoaded={({map, maps}) => render(map, maps, location)}
                />
            </div>
        </Box>
  )
}

export default LocationCard


