import React from 'react'
import { Link } from 'react-router-dom'
import { 
  Button, 
  AppBar, 
  Toolbar,
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import { SIDE_BAR_WIDTH } from '../constants'
import './TopBar.css'

function TopBar({
  currentUser,
  sidebar = false,
  forProviders,
  color = 'default',
}) {

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/" style={{color: 'black'}}>
          Home
        </Link>
      </MenuItem>
      {currentUser && currentUser.isProvider && !currentUser.isAdmin && (
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/pro/dashboard/appointments" style={{color: 'black'}}>
          Appointments
        </Link>
      </MenuItem>
      )}
      {!forProviders && !currentUser && (
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/pro" style={{color: 'black'}}>
          For Providers
        </Link>
      </MenuItem>
      )}
      {currentUser && currentUser.isAdmin && (
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/admin/providers" style={{color: 'black'}}>
          Admin
        </Link>
      </MenuItem>
      )}
      {currentUser && currentUser.isProvider && !currentUser.isAdmin && (
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/pro/dashboard" style={{color: 'black'}}>
          Dashboard
        </Link>
      </MenuItem>
      )}
      {/* {currentUser && !currentUser.isProvider && (
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/quiz" style={{color: 'black'}}>
          Retake Quiz
        </Link>
      </MenuItem>
      )} */}
      {currentUser && !currentUser.isProvider && (
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/search" style={{color: 'black'}}>
          Search
        </Link>
      </MenuItem>
      )}
      {currentUser && !currentUser.isProvider && (
      <MenuItem onClick={handleMobileMenuClose}>
        <Link color="inherit" to="/appointments" style={{color: 'black'}}>
          My Requests
        </Link>
      </MenuItem>
      )}
      <MenuItem onClick={handleMobileMenuClose}>
        <Link
          color="inherit"
          to={currentUser ? '/logout' : '/login'}
          style={{color: 'black'}}>
          {currentUser ? 'Logout' : 'Login'}
        </Link>
      </MenuItem>
    </Menu>
  );
  
  return (
    <div>
    <AppBar
      position="fixed"
      style={
        sidebar
          ? {
              width: `calc(100% - ${SIDE_BAR_WIDTH}px)`,
              marginLeft: SIDE_BAR_WIDTH,
              background: '#301EA1'
            }
          : {
            background: '#301EA1'
          }
      }>
      <Toolbar style={{
        padding: '0 calc((100% - 1400px) / 2)'
      }}>
        <div style={{ flexGrow: 1 }}>
          <Button component={Link} color="inherit" to="/">
            <img alt="logo" src="/images/SmileConnect Logo.png"/>
          </Button>
        </div>
        <div className="sectionDesktop">
          <Link color="inherit" to="/">
            Home
          </Link>
          {currentUser && currentUser.isProvider && !currentUser.isAdmin && (
          <Link  color="inherit" to="/pro/dashboard/appointments">
            Appointments
          </Link>
          )}
          {!forProviders && !currentUser && (
            <Link color="inherit" to="/pro">
              For Providers
            </Link>
          )}
          {currentUser && currentUser.isAdmin && (
            <Link color="inherit" to="/admin/providers">
              Admin
            </Link>
          )}
          {currentUser && currentUser.isProvider && !currentUser.isAdmin && (
            <Link color="inherit" to="/pro/dashboard">
              Dashboard
            </Link>
          )}
          {/* {currentUser && !currentUser.isProvider && (
            <Link color="inherit" to="/quiz">
              Retake Quiz
            </Link>
          )} */}
          {currentUser && !currentUser.isProvider && (
            <Link color="inherit" to="/search">
              Search
            </Link>
          )}
          {currentUser && !currentUser.isProvider && (
            <Link color="inherit" to="/appointments">
              My Requests
            </Link>
          )}
          <Link
            color="inherit"
            to={currentUser ? '/logout' : '/login'}>
            {currentUser ? 'Logout' : 'Login'}
          </Link>
        </div>

        <div className="sectionMobile">
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
    {renderMobileMenu}
    </div>
  )
}

export default TopBar
