import React from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'

function CustomRadio(props) {
  return (
    <>
      {props.children}
      <FormControlLabel
          value={props.value}
          label={props.label}
          control={<Radio checked={props.checked} onClick={props.onClick}/>}
      />
    </>
  )
}

export default CustomRadio


