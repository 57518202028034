import React from 'react'
import { Admin, Resource } from 'react-admin'
import { PatientList, PatientEdit, PatientCreate } from './AdminPatients'
import { ProviderList, ProviderEdit, ProviderCreate } from './AdminProviders'
import {
  AppointmentList,
  AppointmentEdit,
  AppointmentCreate,
} from './AdminAppointments'
import {
  SearchList,
  SearchEdit,
} from './AdminSearch'
import { firestoreProvider } from '../utils/firestoreDataAdminProvider'
import { FirebaseAuthProvider } from 'ra-auth-firebase'

const AdminView = ({ firebase }) => {
  return (
    <Admin
      dataProvider={firestoreProvider(firebase)}
      authProvider={FirebaseAuthProvider({
        keys: {
          permissions: 'user',
          token: 'firebase',
        },
      })}>
      <Resource
        name="admin/providers"
        list={ProviderList}
        edit={ProviderEdit}
        create={ProviderCreate}
      />
      <Resource
        name="admin/patients"
        list={PatientList}
        edit={PatientEdit}
        create={PatientCreate}
      />
      <Resource
        name="admin/appointments"
        list={AppointmentList}
        edit={AppointmentEdit}
        create={AppointmentCreate}
      />
      <Resource
        name="admin/search"
        list={SearchList}
        edit={SearchEdit}
      />
    </Admin>
  )
}

export default AdminView
