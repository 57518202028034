import React from 'react'
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core'

function Checkboxes({ className, heading, onlyone,  boxes, style }) {
  let halfWayThough = boxes.length % 2 ? boxes.length / 2 + 1 : boxes.length / 2

  let boxes1 = boxes.slice(0, halfWayThough)
  let boxes2 = boxes.slice(halfWayThough, boxes.length)
  if (onlyone) {
    return (
      <FormControl
      className={className}
      component="fieldset"
      style={{...style}}
      >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {boxes.map(({ label, onChange, value, checked }) => (
            <FormControlLabel
              key={`group1-${label}`}
              label={label}
              classes={{ label: 'small-label' }}
              style={{ minWidth: 180, minHeight: 60 }}
              control={
                <Checkbox
                  name={label}
                  checked={checked}
                  onChange={onChange}
                  value={value}
                  color="primary"
                />
              }
            />
          ))}
        </Grid>
      </Grid>
    </FormControl>
    )
  }

  return (
    <FormControl
      className={className}
      component="fieldset"
      style={{...style}}
      >
      {heading && (
        <FormLabel component="legend">{heading}</FormLabel>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {boxes1.map(({ label, onChange, value, checked }) => (
            <FormControlLabel
              key={`group1-${label}`}
              label={label}
              classes={{ label: 'small-label' }}
              style={{ minWidth: 180 }}
              control={
                <Checkbox
                  name={label}
                  checked={checked}
                  onChange={onChange}
                  value={value}
                  color="primary"
                />
              }
            />
          ))}
        </Grid>
        <Grid item xs={6}>
          {boxes2.map(({ label, onChange, value, checked }) => (
            <FormControlLabel
              key={`group2-${label}`}
              label={label}
              classes={{ label: 'small-label' }}
              style={{ minWidth: 180 }}
              control={
                <Checkbox
                  name={label}
                  checked={checked}
                  onChange={onChange}
                  value={value}
                  color="primary"
                />
              }
            />
          ))}
        </Grid>
      </Grid>
    </FormControl>
  )
}

export default Checkboxes
