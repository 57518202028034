import React, { useEffect, useState, useContext } from 'react'
import WhiteCard from '../components/WhiteCard'
import { PersonalCard } from '../components/PersonalCard'
import { Typography, Box, Button, Grid } from '@material-ui/core'
import Loader from '../components/Loader'
import GoogleMapReact from 'google-map-react'
import { FirebaseContext } from '../utils/base'
import ReviewCard from '../components/ReviewCard'
import BadgeItem from '../components/BadgeItem'
import pick from 'lodash/pick'
import { 
  BADGES,
} from '../constants'
import './Search.css'
import { geocodeByAddress, getLatLng } from '../components/Places'
import GiftForm from '../components/GiftCardForm'

const BOOTSTRAP_KEYS = {
  key: process.env.REACT_APP_FIREBASE_KEY,
  libraries: ['places', 'distance-matrix'],
}

function SearchView({
  history,
  providers,
  currentUser = {},
  patientData = {},
  setResults,
  searchedDentists,
}) {

  const [listings, setListings] = useState(null)

  const firebase = useContext(FirebaseContext)

  useEffect(() => {

    window.scrollTo(0, 0);

    if (providers && searchedDentists) {
      var location = currentUser.location || patientData.location

      GoogleMapReact.googleMapLoader(BOOTSTRAP_KEYS).then(async maps => {
        const placesService = new maps.places.PlacesService(
          new maps.Map(document.createElement('div'), {
            zoom: 8,
            center: { lat: -34.397, lng: 150.644 },
          })
        )

        if (!location) {
          const results = await geocodeByAddress(currentUser.address)
          const latlng = await getLatLng(results[0])
          location = { ...results[0], ...latlng}
        }


        /* #### Calculate distance by google api ####
        const service = new maps.DistanceMatrixService();

        const getDist = (origin, dest) => {
          return new Promise((resolve, reject) => service.getDistanceMatrix(
            {
              origins: [origin],
              destinations: [dest],
              travelMode: maps.TravelMode.DRIVING,
              unitSystem: maps.UnitSystem.METRIC,
              avoidHighways: false,
              avoidTolls: false
            }, 
            (response, status) => {
              if (response.rows.length > 0) {
                resolve(response.rows[0].elements[0].distance.value / 1610)
              } else {
                console.log("Google Map API call failed")
                resolve(9999999)
              }
            }
          ))
        }

        const calcDistance = async dest => {
          if (dest.length === 0) {
            return 9999999;
          }
          var origin = new maps.LatLng(location.lat, location.lng)
          var distance = 1;

          distance = await getDist(origin, dest)

          return distance;
        }
        */

        /* Calculate distance by lat and lng */
        const toRadians = num => {
          return num * Math.PI / 180
        }

        const calcDistanceMath = dest => {
          var lat1 = location.lat;
          var lon1 = location.lng;
          var lat2 = dest.lat;
          var lon2 = dest.lng;
          var R = 6371e3;
          var φ1 = toRadians(lat1);
          var φ2 = toRadians(lat2);
          var Δφ = toRadians(lat2-lat1);
          var Δλ = toRadians(lon2-lon1);

          var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ/2) * Math.sin(Δλ/2);
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

          var d = R * c;
          return d/1609.344;
        }

        placesService.textSearch(
          { location, radius: 5000 , type: ['dentist'] },
            placesResults => {
            var parsedResults = placesResults.map(result => ( 
              {
              address: result.formatted_address,
              name: result.name,
              avgRating: result.rating,
              numReviews: result.user_ratings_total,
              uid: result.id,
              isProvider: true,
              isApproved: searchedDentists.filter(dentist => dentist.uid === result.id).length === 0 ? true : searchedDentists.filter(dentist => dentist.uid === result.id)[0].isApproved,
              id: result.id,
              place_id: result.place_id,
              images: result.photos
                ? result.photos.map(p => ({ width: p.width, height: p.height, pathname: result.name, url: p.getUrl() }))
                : [],
              }
            ))
            placesService.getDetails({placeId: parsedResults[0].place_id}, async placeResult => {
              parsedResults[0].review = placeResult.reviews.map(p => ({reviewRating: p.rating,reviewText: p.text, reviewerName: p.author_name}));

              setResults(parsedResults)
              parsedResults.forEach(result => {
                if (searchedDentists.filter(dentist => dentist.uid === result.uid).length === 0) {
                  firebase.createSearchedDentist(result)
                }
              })
              var list = []
              for (var i = 0; i< providers.length; i ++) {
                const p = providers[i];

                if (p.isApproved && p.address.length > 0)
                {
                  var dest = {lat: p.lat, lng: p.lng}
                  if (dest.lat === undefined) {
                    const result = await geocodeByAddress(p.address)
                    dest = await getLatLng(result[0])
                    const pro = await firebase.getProvider(p.email);
                    await pro.update({...p, ...dest})
                  }
                  // TODO (Axel): Algorhythm basic
                  // sort by weight in asc
                  // sort by distance in asc

                  const distance = calcDistanceMath(dest)
                  if (distance <= 5) {
                    const weight = p.weight === undefined ? 999999 : Number(p.weight)
                    var flag = false;

                    for (var j = 0; j < list.length; j ++) {
                      if (list[j].weight > weight ||  (list[j].weight === weight && list[j].distance > distance)) {
                        list.splice(j, 0, {...p, distance, weight})
                        flag = true
                        break;
                      }
                    }
                    if (!flag) {
                      list = [...list, {...p, distance, weight}]
                    }
                  }
                }
              }

              // console.log("list", list)
              // setTimeout(() => setListings([
              //   ...list,
              //   ...parsedResults.filter(p => p.isApproved),
              // ]), 0);

              var google_providers = parsedResults.filter(p => p.isApproved);
              for (i =0; i < google_providers.length; i ++) {
                for (j = i + 1; j< google_providers.length; j ++) {
                  if (google_providers[i].avgRating < google_providers[j].avgRating) {
                    var tmp = google_providers[i];
                    google_providers[i] = google_providers[j];
                    google_providers[j] = tmp;
                  }
                }
              }

              setListings([
                ...list,
                ...google_providers])
            })
          }
        )
      })
    }
    // eslint-disable-next-line
  }, [])

  if (!providers || !searchedDentists) return (<Loader />)

  var badge_arr = listings ? Object.entries(pick(listings[0], Object.keys(BADGES))).filter(([label, value]) => value === 'true' || value === true) : []

  const badge_key = Object.keys(BADGES)

  const badge_count = badge_arr.length;

  if (badge_arr.length > 0) {
    badge_arr = badge_arr.map(([label, value]) => [label, value, badge_key.indexOf(label)])
  }

  var badge_available_count = 4;
  if (window.innerWidth > 1280) {
    badge_available_count = 5;
  } 
  
  if (window.innerWidth > 1440) {
    badge_available_count = 6;
  } 

  return listings ? (
    <Box maxWidth={1400} mx="auto">

      <h1 style={{ marginBottom: 20, color: '#301EA1' }}>
      Our Recommended Invisalign Provider
      </h1>

      <Grid container spacing={3}>
      {listings.map((provider, index) => {
        if (index === 0) {
          return (
          <Grid item md={12} sm={12} xs={12} key={`provider-${provider.uid}`}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <WhiteCard>
                    <PersonalCard 
                    provider={provider}
                    isFirst={true}
                    >

                      <div className="hide-mobile">
                      <Grid container justify="space-between" style={{flexDirection: 'row', marginBottom: '10px', color: '#301EA1'}}>
                        {
                          badge_count > badge_available_count ? 
                          badge_arr.slice(0, badge_available_count - 1).map(([label, value, val]) => (
                            <Grid item key={`badge_${label}_${val}`} style={{width: `calc(100% / ${badge_available_count}`}}>
                              <BadgeItem badge={val}/>
                            </Grid>
                          )) : 
                          badge_arr.map(([label, value, val]) => (
                            <Grid item key={`badge_${label}_${val}`} style={{width: `calc(100% / ${badge_available_count}`}}>
                              <BadgeItem badge={val}/>
                            </Grid>
                          ))
                        }
                        {
                          badge_arr.length > badge_available_count &&
                          <Grid item style={{width: `calc(100% / ${badge_available_count}`}}>
                            <BadgeItem badge={-1} other={badge_count-badge_available_count + 1}/>
                          </Grid>
                        }
                      </Grid>
                      </div>

                      <div className="show-mobile">
                      <Grid container style={{flexDirection: 'row', marginBottom: '10px', color: '#301EA1'}}>
                        {
                          badge_arr.slice(0, badge_count - 1).map(([label, value, val]) => (
                            <Grid item sm={4} xs={4} md={4} key={`badge_${label}_${val}`}>
                              <BadgeItem badge={val}/>
                            </Grid>
                          ))
                        }
                      </Grid>
                      </div>

                      <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={12}>
                          <Button variant="contained" style={{
                            width: '100%',
                            height: '60px',
                            backgroundColor: '#301EA1',
                            color: 'white',
                          }}
                          onClick={() => history.push(`/search/${provider.name}&-&${provider.address.replace(/#/g, '')}`)}>View full profile</Button>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <Button variant="contained" style={{
                              width: '100%',
                              height: '60px',
                              color: '#0D1C86',
                              backgroundColor: 'white',
                              border: '1px solid #0D1C86',
                            }}
                            onClick={() =>
                              history.push(`/search/${provider.name}&-&${provider.address.replace(/#/g, '')}/appointment`)}
                            >Request an Appointment</Button>
                        </Grid>
                      </Grid>
                    </PersonalCard>
                  </WhiteCard>
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} className="rightCol">
                  {
                    provider.review && (<div style={{marginTop: '-8px'}}>
                      <ReviewCard
                      reviewText= {provider.review[0].reviewText.length > 250 ? provider.review[0].reviewText.slice(0, 250).concat("...") : provider.review[0].reviewText}
                      reviewRating={provider.review[0].reviewRating}
                      reviewerName={provider.review[0].reviewerName}/>
                    </div>)
                  }
                  <Grid container spacing={3}>
                    <Grid item md={6} sm={6} xs={12}>
                      <Button variant="contained" style={{
                          width: '100%',
                          height: '60px',
                          color: '#0D1C86',
                          backgroundColor: 'white',
                          border: '1px solid #0D1C86',
                      }}
                      onClick={() => history.push(`/search/${provider.name}&-&${provider.address.replace(/#/g, '')}`)}
                      >View all reviews</Button>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                      <div style={{ lineHeight: '3', float: 'right', color: '#301EA1'}}>
                      {provider.numReviews} reviews
                      </div>
                    </Grid>
                  </Grid>

                  <GiftForm/>
                  <Typography
                    align="left"
                    variant="body1"
                    style={{ marginTop: 10, color: 'black', fontSize: '12px'  }}>
                    <a style={{ color: 'blue'}} href="/#/terms">Terms and conditions</a> apply. Upon successful treatment completion
                  </Typography>
                </Grid>
              </Grid>
              <h1 style={{ marginTop: 20,  color: '#301EA1'}}>
              Other Invisalign providers
              </h1>
            </Grid>
            )
      } else {
          return (
            <Grid item xs={12} sm={12} md={6} key={`provider-${provider.uid}`}>  
            <WhiteCard key={`provider-${provider.uid}`}>
              <PersonalCard 
              provider={provider}
              >
                <Grid container spacing={3}>
                  <Grid item md={6} sm={6} xs={12}>
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '60px',
                      backgroundColor: '#0D1C86',
                      color: 'white',
                    }}
                    onClick={() => history.push(`/search/${provider.name}&-&${provider.address.replace(/#/g, '')}`)}>View full profile</Button>
                  </Grid>

                  <Grid item md={6} sm={6} xs={12}>
                      <Button variant="contained" style={{
                        width: '100%',
                        height: '60px',
                        color: '#0D1C86',
                        backgroundColor: 'white',
                        border: '1px solid #0D1C86',
                      }}
                      onClick={() =>
                        history.push(`/search/${provider.name}&-&${provider.address.replace(/#/g, '')}/appointment`)}
                      >Request an Appointment</Button>
                  </Grid>
                </Grid>
              </PersonalCard>
          </WhiteCard>
        </Grid>
          )
        }
      })}
      </Grid>
    </Box>
  ) : (
    <Loader />
  )
}

export default SearchView
