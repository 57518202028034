import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export const useFilledTextStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}))

function FilledTextField(props) {
  const classes = useFilledTextStyles()
  return (
    <TextField
      fullWidth
      variant="filled"
      name={props.label}
      InputProps={{ classes, disableUnderline: true, readOnly: props.readOnly === true ? true : false, /*startAdornment: <InputAdornment position="start"><img src="/images/marker.png" alt="marker"/></InputAdornment> */}}
      style={{ margin: '8px 0' }}
      {...props}
    />
  )
}

export default FilledTextField
