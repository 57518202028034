import React from 'react'
import {
  FormLabel,
  Grid
} from '@material-ui/core'

function Dropdowns({ className, heading, onlyone,  boxes, style }) {
  let halfWayThough = boxes.length % 2 ? boxes.length / 2 + 1 : boxes.length / 2

  let boxes1 = boxes.slice(0, halfWayThough)
  let boxes2 = boxes.slice(halfWayThough, boxes.length)
  if (onlyone) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {boxes.map(({ label }) => (
            <div 
            key={`group1-${label}`}
            style={{ 
              color: '#2f1ea0',
              borderRadius: 10,
              background: '#2f1ea00a',
              border: '1px solid #ccc',
              padding: '15px 10px',
              margin: 10}}>
              {label}
            </div>
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <div
      >
      {heading && (
        <FormLabel component="legend">{heading}</FormLabel>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {boxes1.map(({ label }) => (
            <div 
            key={`group1-${label}`}
            style={{ 
              color: '#2f1ea0',
              borderRadius: 10,
              background: '#2f1ea00a',
              border: '1px solid #ccc',
              padding: '15px 10px',
              margin: 10}}>
              {label}
            </div>
          ))}
        </Grid>
        <Grid item xs={6}>
          {boxes2.map(({ label}) => (
            <div 
            key={`group2-${label}`}
            style={{ 
              color: '#2f1ea0',
              borderRadius: 10,
              background: '#2f1ea00a',
              border: '1px solid #ccc',
              padding: '15px 10px',
              margin: 10}}>
              {label}
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

export default Dropdowns
