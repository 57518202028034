export const APP_BAR_HEIGHT = 65
export const SIDE_BAR_WIDTH = 240
export const QUESTIONS = [
  {
    name: 'age',
    heading: 'What would you like to improve about your smile?',
    body: '',
    choices: ['The look (cosmetic)', 'The bite (functional)', 'I’m unsure'],
  },

  {
    name: 'solution',
    heading: 'What’s your biggest concern?',
    body: 'What’s your biggest concern?',
    choices: ['Overbite', 'Underbite', 'Gap Teeth', 'Crowding', 'Open Bite', 'Cross Bite', 'Not Sure']
  },

  {
    name: 'status',
    heading: 'Where are you in your Smile Journey?',
    body: '',
    choices: ['I’ve just started doing research', 'I’m ready for a consultation', 'I’ve had a consultation and want another opinion', 'I’m ready to start treatment']
  },

  {
    name: 'plan',
    heading: `The treatment I'm interested in:`,
    body: '',
    choices: [/*'Clear Aligners',*/ 'Invisalign', 'Braces', 'Unsure']
  },

  {
    name: 'experience',
    heading: 'Have you worn braces or aligners in the past?',
    body: '',
    choices: ['Yes', 'No']
  },

  {
    name: 'history',
    heading: 'How long has it been since you last visited the dentist?',
    body: '',
    choices: ['Less than 1 Year', '1-3 Years', '3-5 Years', '+5 Years', 'Never']
  },

  {
    name: 'time',
    heading: 'What is your preferred appointment time?',
    body: '',
    choices: ['Morning', 'Afternoon', 'Evening', 'No Preference']
  },

  {
    name: 'insurance',
    heading: 'Do you have dental insurance?',
    body: '',
    choices: ['Yes', 'No', 'I’m unsure']
  },

  {
    name: 'provider',
    heading: 'Who is your insurance provider?',
    body: '',
    choices: ['Manulife Financial', 'Sun Life Financial', 'Great-West Life', 'green shield canada', 'BLUE CROSS', 'Desjardins', 'I am unsure', 'Other provider']
  }
  // {
  //   name: 'lastExam',
  //   heading: 'When was your last dental exam?',
  //   body: 'Chartreuse air plant whatever, ramps roof party.',
  //   choices: ['1 month ago', '6 months ago', '> 1 year ago'],
  // },
  // {
  //   name: 'budget',
  //   heading: 'How much are you expecting to pay for treatment?',
  //   body: 'Twee typewriter next level, pitchfork glossier.',
  //   choices: ['$1000', '$2000', '$3000'],
  // },

  // {
  //   name: 'schedule',
  //   heading: 'What type of payment structure do you prefer?',
  //   body: 'Blog umami fam sartorial cronut slow-carb.',
  //   choices: ['One Time', 'Weekly', 'Monthly'],
  // },

  // {
  //   name: 'insured',
  //   heading: 'Do you have insurance?',
  //   body: 'Retro woke cloud bread yuccie selfies.',
  //   choices: ['Yes', 'No', 'Not sure'],
  // },
]

export const DEFAULT_BIRTHDATE = new Date('2001-01-02')

export const SERVICES = {
  Invisalign: false,
  'Traditional Braces': false,
  'General Dentistry': false,
  'Lingual Braces': false,
}

export const PROCEDURES = {
  'Cosmetic (veneers, whitening)': false,
  Fillings: false,
  'Emergency dental care': false,
  'Roots canals': false,
  Invisalign: false,
  Braces: false,
  'Pediatric dentistry': false,
  Crowns: false,
  Bridges: false,
  Dentures: false,
  'Oral surgery- wisdom teeth, impacted teeth': false,
  'Specialists Endo': false,
  'Specialists OMFS': false,
  'Specialists Perio': false,
  'Specialists Prostho': false,
  'Specialists Ortho': false,
  'Specialists Pedo': false,
  'Dental Implants': false,
  'Surgical implant placement': false,
  'bone grafting': false,
  'sinus lifts': false,
  'implant restoration': false,
  'Full-mouth Rehabilitation': false,
  'TMJ Treatment': false,
  'Sleep Apnea Treatment': false,
  Sedation: false,
  'Periodontal surgery': false,
}

export const FEATURES = {
  'Digital Scanner': false,
  'Panoramic X-Ray': false,
  CBCT: false,
  CEPH: false,
}

export const OFFICE_AMENITIES = {
  Parking: false,
  'Transit Access': false,
  'Beverages': false,
  'Tv in waiting room': false,
  'Tv in Operatories': false,
  'blankets or pillows': false,
  'Wifi': false,
  'Nitrous sedation': false,
  'Hot towel': false,
  'lip balm': false,
  'handicap accessible washroom': false,
  'kids play area': false,
}

export const INSECURE_AND_PAYMENTS = {
  'Direct Billing | Assignment of Benefits': false,
  'In house payment plans': false,
  '3rd party payment plans': false,
  'Ontario Fee guide followed': false,
  // 'Discounts given with discretion': false,
}

export const PRACTICE_TECHNOLOGY = {
  'Digital x-rays': false,
  'IntraOral Camera': false,
  'Panoramic X-rays': false,
  'Dental Laser': false,
  CBCT: false,
  'Digital Scanner (itero)': false,
  'Cerec crowns (same-day crowns)': false,
  'Laser Whitening': false,
  'Paperless practice': false
}

export const HOURS_APPOINTMENTS = {
  'weekend appointments': false,
  'evening appointments': false
}

export const BADGES = {
  'Invisalign Gold': false,
  'Invisalign Platinum': false,
  'Invisalign Diamond': false,
  'Patient Favourite': false,
  'Office Amenities': false,
  'High Tech Office': false,
  'Insurance Friendly': false, 
  'Kid Friendly': false, 
  'Friendly Staff': false,
  'Convenient': false,
  'Advanced Cosmetic Training': false,
  'Advanced Implant Traning': false,
  'Experienced Doctor': false,
}

export const ABOUT_DENTIST = {
  'Year of graduation': false,
  'School name': false,
  'RCDSO certification year': false,
  'Specialty': false,
  'Memberships and certifications': false,
}

export const HIDE_CARD = {
  isSmileConnectVerified: false,
  'Biography Card': false,
  'Why Love Card': false,
  'Top 3 Card': false,
  'Procedures Card': false,
  'About Card': false,
  'Office Amenities Card': false,
  'Insurance and Payments Card': false,
  'Our location Card': false,
  'Office photos Card': false,
  'Reviews Card': false,
  'Practice Technology Card': false,
  'Hours Card': false,
}
