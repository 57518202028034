import React from 'react'
import { ButtonBase, Box } from '@material-ui/core'

function ProfileImage({ onDelete, image, width = 120 }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width={width}
      height={width}
      m={2}
      style={{ position: 'relative', overflow: 'hidden' }}>
      <img
        alt="alt"
        src={typeof image === 'string' ? image : image.url}
        title="alt"
        style={{ width: '100%', height: 'auto' }}
      />
      {onDelete && (
        <ButtonBase
          onClick={onDelete}
          size="small"
          color="primary"
          style={{ position: 'absolute', top: 4, right: 4 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="-5 0 28 28">
            <path
              fill="#fff"
              d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </ButtonBase>
      )}
    </Box>
  )
}

export default ProfileImage
