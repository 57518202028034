import React from 'react'
import MuiPhoneNumber from 'material-ui-phone-number'
import { Box } from '@material-ui/core'

function PhoneInput(props) {
  return (
    <Box style={{ margin: '8px 0' }}>
      <MuiPhoneNumber
        fullWidth
        variant="filled"
        defaultCountry={'us'}
        inputClass="phone-input"
        {...props}
      />
    </Box>
  )
}

export default PhoneInput
