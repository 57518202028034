import React from 'react'
import { LinearProgress, Typography, Box } from '@material-ui/core'
import './Loader.css'

export default function Loader({ label = 'Finding you the best specialists for you...' }) {

  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop={30}>
      {/* <CircularProgress variant="indeterminate" color="primary"/> */}

      <LinearProgress variant="indeterminate" color="primary" value={completed} className="progress__width"/>

      <Box my={1} />

      <Typography align="center">{label}</Typography>
    </Box>
  )
}
