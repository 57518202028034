import React, { useContext, useState } from 'react'
import { FirebaseContext } from '../utils/base'
import { Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'
import TextField from '../components/FilledTextField'

const LoginContainer = ({ currentUser, providers, patients, history, match }) => {
  const firebase = useContext(FirebaseContext)
  const [error, setError] = useState('')

  if (currentUser) {
    history.push(currentUser.isProvider ? '/pro/dashboard' : '/search')
  }

  console.log(match)
  var redirectEmail = match.params.username === undefined ? '' : match.params.username

  return (
    <>
      <h1>Welcome back</h1>

      <Typography variant="body1">Enter your credentials below</Typography>

      <form
        style={{ margin: '30px 0' }}
        onSubmit={async event => {
          event.preventDefault()
          const { email, password } = event.target.elements

          if (email.value === '') {
            setError("Input email address!")
          } else if (password.value === '') {
            setError("Input password!")
          }
          else if (providers.filter(p => p.email === email.value).length !== 0 || patients.filter(p => p.email === email.value).length !== 0) {
            firebase.auth.signInWithEmailAndPassword(
              email.value,
              password.value
            ).then(() => {
              const current = providers.filter(p => p.email === email.value)

              if (current.length !== 0) {
                if (current[0].isAdmin) {
                  setTimeout(() => history.push('/admin/providers'), 500)
                } else {
                  setTimeout(() => history.push('/pro/dashboard'), 500)
                }
              }
              else {
                setTimeout(() => history.push('/search'), 500)
              }
            }).catch((error) => {
              setError("Credentials are wrong!")
            })
          } else {
            setError("User does not exist!")
          }
        }}>
        <TextField label="EMAIL" name="email" placeholder="EMAIL" defaultValue={redirectEmail} />

        <TextField
          type="password"
          label="PASSWORD"
          name="password"
          placeholder="PASSWORD"
        />

        <p style={{opacity: error === '' ? 0 : 1, color: 'red'}}>{error}</p>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ marginTop: 30 }}>
          Submit
        </Button>
      </form>
    </>
  )
}

export default LoginContainer
