import React, { useContext, useState } from 'react'
import { FirebaseContext } from '../utils/base'
import { Typography, Button } from '@material-ui/core'
import TextField from '../components/FilledTextField'
import PhoneInput from '../components/PhoneInput'

const PatientSignupContainer = ({ history, patientData, providers, patients, ...props }) => {
  const firebase = useContext(FirebaseContext)
  const [error, setError] = useState('')

  const emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  return (
    <>
      <Typography variant="h1">Finalize your appointment</Typography>

      <Typography variant="body1">
        Tell us more about yourself to create your appointment
      </Typography>

      <SignUpForm
        error={error}
        onSubmit={async patient => {
          const { password, ...patientFormdata } = patient
          const { files, location, ...restUserData } = patientData

          if (patient.email === '' || !emailIsValid(patient.email)) {
            setError("Please input valid email!")
          } else if (patient.password === '') {
            setError("Please input password!")
          } else if (patient.password.length  < 6) {
            setError("Password must be more than 6 characters!")
          } else if (patient.phone.length < 10) {
            setError("Please input valid phone number!")
          } else if (patient.name === '') {
            setError("Please input your name!")
          }
          else if (providers.filter(p => p.email === patient.email).length !== 0 || patients.filter(p => p.email === patient.email).length !== 0) {
            setError("Email is already in use by other one!")
          } else {

            setError("")
            await firebase.functions.httpsCallable('removeUserByEmail')({email: patient.email})

            await firebase.createAuthUser(patient.email, patient.password)
            
            await firebase.createPatient({
              ...patientFormdata,
              ...restUserData,
              // lat: location ? location.lat : null,
              // lng: location ? location.lng : null,
              address: location ? location.address.formatted_address : null,
            })
            await firebase.addImagesToModel(files, 'patients', patient.email)

            history.push(
              props.location.search
                ? props.location.search.replace('?redirect=', '')
                : '/search'
            )
          }
        }}
      />
    </>
  )
}

export default PatientSignupContainer

const SignUpForm = ({ onSubmit, initialLocation = '' }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit({ name, email, phone, password })
      }}
      style={{ margin: '30px 10px' }}>
      <TextField
        label="NAME"
        placeholder="name"
        value={name}
        onChange={e => setName(e.target.value)}
      />

      <TextField
        label="EMAIL"
        placeholder="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />

      <PhoneInput label="PHONE" value={phone} onChange={e => setPhone(e)} />

      <TextField
        type="password"
        label="CREATE PASSWORD"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        style={{ marginTop: 30 }}>
        Submit
      </Button>
    </form>
  )
}
