import React from 'react'

function StaticText(props) {
  return (
    <p style={{
        //fontFamily: 'Roboto',
        fontSize: '15px',
        lineHeight: '23px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.01em',
        
        color: '#333333',
      }}>
        {props.children}
    </p>
  )
}

export default StaticText


