import React, { useState, useEffect, useContext } from 'react'
import { QUESTIONS } from '../constants'
import QuizQuestion from '../components/QuizQuestion'
import { Grid, Button, Paper } from '@material-ui/core'
import CustomRadio from '../components/CustomizedRadio'
import CustomCheck from '../components/CustomizedCheck'
import './Quiz.css'
import { FirebaseContext } from '../utils/base'

function Quiz(props) {
  const [state, setState] = useState({
    questions: QUESTIONS,
    answers: {},
    questionIndex: 0,
  })

  const [value, setValue] = useState({
    value: -1,
  });

  const [payment, setPayment] = useState({
    payment: '',
  });

  const handleChange = event => {
    setValue(event.target.value);
  }

  const handleCheckboxChange = event => {
    if ( value % (Math.pow(2, event.target.value + 1)) >= Math.pow(2, event.target.value)) {
      setValue((value - Math.pow(2, event.target.value)) % 64);
    } else {
      setValue((value + Math.pow(2, event.target.value)) % 64);
    }
  }

  const handleCheckboxChangeUnsure = event => {
    var newVal;
    if ( value % (Math.pow(2, event.target.value + 1)) >= Math.pow(2, event.target.value)) {
      newVal = value - Math.pow(2, event.target.value)
      newVal -= newVal % 64;
      setValue(newVal);
    } else {
      newVal = value + Math.pow(2, event.target.value)
      newVal -= newVal % 64;
      setValue(newVal);
    }
  }

  const handlePayment = event => {
    setPayment(event.target.value);
  }

  const updateState = update => setState(state => ({ ...state, ...update }))
  const { questions, answers, questionIndex } = state

  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    if (questionIndex > questions.length - 1) {
      props.setPatientData({ ...answers, completedQuiz: true })
      if (props.toWhere) {
        props.history.replace('/search')
      } else {
        const updatePatient = async () => await firebase.getPatient(props.currentUser.email).update({ ...answers, completedQuiz: true })
        updatePatient()
        props.history.replace('/appointments')
      }
    }
    // eslint-disable-next-line
  }, [questionIndex, answers, questions])

  // onBack={() => updateState({ questionIndex: questionIndex - 1 })}
  // onSubmitPhoto={files =>
  //   updateState({
  //     questionIndex: questionIndex + 1,
  //     answers: { ...answers, files: [...(files || []), ...files] },
  //   })
  // }
  // onSubmit={answer => {
  //   updateState({
  //     answers: {
  //       ...answers,
  //       [questions[0].name]: answer,
  //     },
  //   })
  //   updateState({ questionIndex: questionIndex + 1 })
  // }}

  return (
    <>
    {questionIndex === 0 && <QuizQuestion
      question={questions[0]}
      active={questionIndex === 0}
      style={{ zIndex: questions.length - 0 }}
    >
      <Grid container spacing={2}>
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={0} j={0} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={0} j={1} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={0} j={2} />
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value >= 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[0].name]: value
              }
            });
            updateState({
              questionIndex: questionIndex + 1
            });
            setValue(0);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>}

    {questionIndex === 1 && <QuizQuestion
    question={questions[1]}
    active={questionIndex === 1}
    style={{ zIndex: questions.length - 1 }}
    >
      <Grid container spacing={2} className="quiz-content">
        <ImageCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={1} j={0}>
          <img alt="teeth" src="/images/image1.png" className="tooth_image"/>
        </ImageCheck>
        <ImageCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={1} j={1}>
          <img alt="teeth" src="/images/image2.png" className="tooth_image"/>
        </ImageCheck>
        <ImageCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={1} j={2}>
          <img alt="teeth" src="/images/image3.png" className="tooth_image"/>
        </ImageCheck>
        <ImageCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={1} j={3}>
          <img alt="teeth" src="/images/image4.png" className="tooth_image"/>
        </ImageCheck>
        <ImageCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={1} j={4}>
          <img alt="teeth" src="/images/image5.png" className="tooth_image"/>
        </ImageCheck>
        <ImageCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={1} j={5}>
          <img alt="teeth" src="/images/image6.png" className="tooth_image"/>
        </ImageCheck>
        <ImageCheck questions={questions} onChange={handleCheckboxChangeUnsure} checked={value} i={1} j={6}>
          <div style={{width: '93%', height: '150px', paddingRight: '10px', textAlign: 'center', backgroundColor: '#23221e'}}>
            <img alt="teeth" src="/images/unsure.png" height="120" style={{paddingTop: '15px'}}/>
          </div>
        </ImageCheck>
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
          setValue(answers[questions[questionIndex-1].name]);
          updateState({
            questionIndex: questionIndex - 1
          });
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value >= 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[1].name]: value
              }
            });
            updateState({
              questionIndex: questionIndex + 1
            });
            setValue(-1);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>}

    {questionIndex === 2 && <QuizQuestion
      question={questions[2]}
      active={questionIndex === 2}
      style={{ zIndex: questions.length - 2 }}
    >
      <Grid container spacing={2}>
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={2} j={0} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={2} j={1} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={2} j={2} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={2} j={3} />
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
          setValue(answers[questions[questionIndex-1].name]);
          updateState({
            questionIndex: questionIndex - 1
          });
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value >= 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[2].name]: value,
                [questions[3].name]: -1
              }
            });
            updateState({
              questionIndex: questionIndex + 2
            });
            setValue(-1);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>}

    {/* {questionIndex === 3 && <QuizQuestion
      question={questions[3]}
      active={questionIndex === 3}
      style={{ zIndex: questions.length - 3 }}
    >
      <Grid container spacing={2}>
        <DefaultCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={3} j={0} />
        <DefaultCheck questions={questions} onChange={handleCheckboxChange} checked={value} i={3} j={1} />
        <DefaultCheck questions={questions} onChange={handleCheckboxChangeUnsure} checked={value} i={3} j={2} />
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
          setValue(answers[questions[questionIndex-1].name]);
          updateState({
            questionIndex: questionIndex - 1
          });
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value > 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[3].name]: value
              }
            });
            updateState({
              questionIndex: questionIndex + 1
            });
            setValue(-1);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>} */}

    {questionIndex === 4 && <QuizQuestion
      question={questions[4]}
      active={questionIndex === 4}
      style={{ zIndex: questions.length - 4 }}
    >
      <Grid container spacing={2}>
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={4} j={0} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={4} j={1} />
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
          setValue(answers[questions[questionIndex-2].name]);
          updateState({
            questionIndex: questionIndex - 2
          });
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value >= 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[4].name]: value
              }
            });
            updateState({
              questionIndex: questionIndex + 1
            });
            setValue(-1);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>}

    {questionIndex === 5 && <QuizQuestion
      question={questions[5]}
      active={questionIndex === 5}
      style={{ zIndex: questions.length - 5 }}
    >
      <Grid container spacing={2}>
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={5} j={0} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={5} j={1} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={5} j={2} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={5} j={3} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={5} j={4} />
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
          setValue(answers[questions[questionIndex-1].name]);
          updateState({
            questionIndex: questionIndex - 1
          });
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value >= 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[5].name]: value,
                [questions[6].name]: -1
              }
            });
            updateState({
              //skip the question "What is your preferred appointment time?"
              questionIndex: questionIndex + 2
            });
            setValue(-1);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>}

    {/* {questionIndex === 6 && <QuizQuestion
      question={questions[6]}
      active={questionIndex === 6}
      style={{ zIndex: questions.length - 6 }}
    >
      <Grid container spacing={2}>
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={6} j={0} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={6} j={1} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={6} j={2} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={6} j={3} />
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
          setValue(answers[questions[questionIndex-1].name]);
          updateState({
            questionIndex: questionIndex - 1
          });
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value >= 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[6].name]: value
              }
            });
            updateState({
              questionIndex: questionIndex + 1
            });
            setValue(-1);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>} */}

    {questionIndex === 7 && <QuizQuestion
      question={questions[7]}
      active={questionIndex === 7}
      style={{ zIndex: questions.length - 7 }}
    >
      <Grid container spacing={2}>
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={7} j={0} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={7} j={1} />
        <DefaultRadio questions={questions} onChange={handleChange} checked={value} i={7} j={2} />
      </Grid>

      <div style={{height: '65px'}}></div>

      <div className="navigation_menu">
        <Button variant="contained" className="previous_button"
        onClick={() => {
          setValue(answers[questions[questionIndex-2].name]);
          updateState({
            questionIndex: questionIndex - 2
          });
        }}>Previous</Button>
        <Button variant="contained" className="next_button"
        onClick={() => {
          if (value >= 0) {
            updateState({ 
              answers: {
                ...answers, 
                [questions[7].name]: value
              }
            });
            updateState({
              questionIndex: questionIndex + 1
            });
            if (value !== 0) {
              updateState({ 
                answers: {
                  ...answers, 
                  [questions[7].name]: value,
                  [questions[8].name]: 'undefined'
                }
              });
              updateState({
                questionIndex: questionIndex + 2
              });
            }
            setValue(-1);
          }
        }}>Next</Button>
      </div>
    </QuizQuestion>}

    {questionIndex === 8 && <QuizQuestion
      question={questions[8]}
      active={questionIndex === 8}
      style={{ zIndex: questions.length - 8 }}
    >
      <div className="quiz-content">
      <Grid container spacing={2}>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={0} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
            <img alt="teeth" src="/images/insurance1.png"/>
          </div>
        </ImageRadio>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={1} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
          <img alt="teeth" src="/images/insurance2.png"/>
          </div>
        </ImageRadio>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={2} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
          <img alt="teeth" src="/images/insurance3.png"/>
          </div>
        </ImageRadio>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={3} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
          <img alt="teeth" src="/images/insurance4.svg"/>
          </div>
        </ImageRadio>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={4} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
          <img alt="teeth" src="/images/insurance5.svg"/>
          </div>
        </ImageRadio>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={5} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
          <img alt="teeth" src="/images/insurance6.svg"/>
          </div>
        </ImageRadio>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={6} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
          <img alt="teeth" src="/images/icon-question.svg"/>
          </div>
        </ImageRadio>
        <ImageRadio questions={questions} onChange={handleChange} checked={value} i={8} j={7} insurace={true}>
          <div style={{width: '250px', height: '60px', textAlign: 'center'}}>
          <img width="50px" alt="teeth" src="/images/other.png"/>
          </div>
        </ImageRadio>
      </Grid>

      {value === 7 && <div>
      <p>Insurance provider</p>
      <select id="js-other-provider-control" name="provider" onChange={handlePayment} className="other_provider">
        <option hidden="" value="">-- Choose Provider --</option>
        <optgroup label="Don't see your provider?">
          <option value="Other">My provider isn't listed</option>
        </optgroup>
        <optgroup label="Other insurance providers">
          <option value="Benecaid">Benecaid</option>
          <option value="Chambers of Commerce Group Insurance Plan">Chambers of Commerce Group Insurance Plan</option>
          <option value="ClaimSecure">ClaimSecure</option>
          <option value="Cowan Insurance Group">Cowan Insurance Group</option>
          <option value="Empire Life">Empire Life</option>
          <option value="Encon">Encon</option>
          <option value="Equitable Life of Canada">Equitable Life of Canada</option>
          <option value="Global Benefits">Global Benefits</option>
          <option value="GroupSource">GroupSource</option>
          <option value="Guard.me">Guard.me</option>
          <option value="HealthSource Plus">HealthSource Plus</option>
          <option value="Healthy Smiles">Healthy Smiles</option>
          <option value="IFHP - Interim Federal Health Program">IFHP - Interim Federal Health Program</option>
          <option value="Industrial Alliance">Industrial Alliance</option>
          <option value="Johnston Group">Johnston Group</option>
          <option value="League">League</option>
          <option value="Manion Wilkins &amp; Associates">Manion Wilkins &amp; Associates</option>
          <option value="MDM Insurance Services">MDM Insurance Services</option>
          <option value="Morcare">Morcare</option>
          <option value="NexgenRx">NexgenRx</option>
          <option value="ODSP - Ontario Disabilities Support Program">ODSP - Ontario Disabilities Support Program</option>
          <option value="Ontario Works">Ontario Works</option>
          <option value="OTIP - Ontario Teachers Insurance Plan">OTIP - Ontario Teachers Insurance Plan</option>
          <option value="RBC Insurance">RBC Insurance</option>
          <option value="RWAM">RWAM</option>
          <option value="SSQ Financial Group">SSQ Financial Group</option>
          <option value="Standard Life">Standard Life</option>
          <option value="The Benefits Trust">The Benefits Trust</option>
          <option value="The Co - operators">The Co - operators</option>
          <option value="University / College / Student Plan">University / College / Student Plan</option>
          <option value="Welfare">Welfare</option>
        </optgroup>
      </select>
    </div>
      }
    </div>

    <div style={{height: '65px'}}></div>

    <div className="navigation_menu">
      <Button variant="contained" className="previous_button"
      onClick={() => {
        setValue(answers[questions[questionIndex-1].name]);
        updateState({
          questionIndex: questionIndex - 1
        });
      }}>Previous</Button>
      <Button variant="contained" className="next_button"
      onClick={() => {
        if (value >= 0) {
          if (value === 7) {
            if (payment.length !== 0) {
              updateState({ 
                answers: {
                  ...answers, 
                  [questions[8].name]: payment
                }
              });
              updateState({
                questionIndex: questionIndex + 1
              });
              setValue(-1);
            }
          } else {
            updateState({ 
              answers: {
                ...answers, 
                [questions[8].name]: value
              }
            });
            updateState({
              questionIndex: questionIndex + 1
            });
            setValue(-1);
          }
        }
      }}>Next</Button>
      </div>
    </QuizQuestion>}

    </>
  )
}


export function DefaultRadio(props) {
  return (
    <Grid item className="radio_grid">
      <Paper className="radio_button" onClick={() => props.onChange({target: {value: props.j }})} style={{cursor: 'pointer'}}>
        <CustomRadio value={props.j} label={props.questions[props.i].choices[props.j]} checked={Number(props.checked) === props.j} onClick={props.onChange} />
      </Paper>
    </Grid>
  )
}

export function DefaultCheck(props) {
  return (
    <Grid item className="radio_grid">
      <Paper className="radio_button" onClick={e => {
        e.preventDefault()
        props.onChange({target: {value: props.j }})
        }} style={{cursor: 'pointer'}}>
        <CustomCheck label={props.questions[props.i].choices[props.j]} checked={Number(props.checked) % Math.pow(2, (props.j + 1)) >= Math.pow(2, props.j)}/>
      </Paper>
    </Grid>
  )
}

export function ImageCheck(props) {
  return (
    <Grid item className="radio_grid">
      <Paper className="radio_image_button" style={{paddingLeft: '10px', paddingTop: '20px', height: props.insurace ? '100px' : 'auto', cursor: 'pointer'}}  onClick={() => props.onChange({target: {value: props.j }})}>
        <CustomCheck value={props.j} label={props.questions[props.i].choices[props.j]} checked={Number(props.checked) % Math.pow(2, (props.j + 1)) >= Math.pow(2, props.j)} onClick={props.onChange}>
          {props.children}
        </CustomCheck>
      </Paper>
    </Grid>
  )
}

export function ImageRadio(props) {
  return (
    <Grid item className="radio_grid">
      <Paper className="radio_image_button" style={{paddingLeft: '10px', paddingTop: '20px', height: props.insurace ? '100px' : 'auto', cursor: 'pointer'}}  onClick={() => props.onChange({target: {value: props.j }})}>
        <CustomRadio value={props.j} label={props.questions[props.i].choices[props.j]} checked={Number(props.checked) === props.j} onClick={props.onChange}>
          {props.children}
        </CustomRadio>
      </Paper>
    </Grid>
  )
}

export default Quiz
