import React, { Fragment } from 'react'
import {
  Datagrid,
  List,
  Edit,
  Filter,
  SimpleForm,
  TextField,
  EditButton,
  DeleteButton,
  BooleanInput,
  TextInput,
  Toolbar,
  SaveButton,
  BulkDeleteButton
} from 'react-admin'
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const PostBulkActionButtons = props => (
  <Fragment>
      {/* Add the default bulk delete action */}
      <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);

const SearchFilter = props => (
  <Filter {...props}>
    <TextField label="Search" source="name" alwaysOn />
  </Filter>
)

export const SearchList = props => (
  <List {...props} filters={<SearchFilter />} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="avgRating" />
      <TextField source="numReviews" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} undoable={false}/>
    </Datagrid>
  </List>
)

export const SearchEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar/>}>
      <TextInput source="uid" disabled />
      <TextInput source="name" disabled />
      <TextInput source="avgRating" disabled />
      <TextInput source="numReviews" disabled />
      <TextInput source="address" disabled />
      <BooleanInput source="isApproved" disabled/>
    </SimpleForm>
  </Edit>
)
