import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/App'
import Firebase, { FirebaseContext } from './utils/base'
import 'font-awesome/css/font-awesome.min.css';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
)
