import React, { useState } from 'react'
import { Typography, Box, Button, Grid } from '@material-ui/core'
import FilledTextField from '../components/FilledTextField'
import { ValueProp } from './PatientHome'
import WhiteCard from '../components/WhiteCard'
import './PatientHome.css'

function PatientHomeView({ history, setPatientData }) {
  const [email, setEmail] = useState('')
  const onSearch = email => {
    history.push(`/pro/signup/${email}`)
  }

  return (
    <>
      <Box
        py={12}
        mt={-2}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}
        style={{ boxSizing: 'border-box', background: 'linear-gradient(180deg, #D0E6EF 0%, #C8E3EC 100%)' }}>
        <Box display="flex" mx="auto" maxWidth={1400}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex={1}
            maxWidth={600}
            zIndex={1}>
            <h1
              style={{ color: '#301EA1', fontWeight: 'bold' }}>
              Grow your dental
              <br />
              practice
            </h1>
            <Typography
              align="left"
              variant="body1"
              style={{ marginTop: 10, color: '#301EA1' }}>
              Grow your practice by joining a network of dentists that deliver
              exceptional patient experiences.
            </Typography>

            <Box display="flex" className="center_sign_provider">
              <Box flex={6}>
                <FilledTextField
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Your email address"
                />
              </Box>

              <Box flex={4}>
                <Button
                  variant="contained"
                  onClick={() => onSearch(email)}
                  className="button_provider_start"
                  style={{color: 'white', backgroundColor: '#0D1C86'}}>
                  Get started
                </Button>
              </Box>
            </Box>

            <img
              src="/images/background4.png"
              alt="dentist tools"
              className="dentist_back"
            />
          </Box>
        </Box>
      </Box>

      <Box 
        bgcolor="#301EA1"
        py={3}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}>
        <h1
          style={{ marginTop: 10, color: '#fff' ,fontWeight: 'bolder', textAlign: 'center', width: '100%'}}>
          How it works
        </h1>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="flex-column-mobile"
          maxWidth={1400}
          mx="auto"
          style={{backgroundColor: '#301EA1'}}>
          <ValueProp heading="Take our smile assesement" index="1">
          Take our quick online smile quiz. Just a few questions to get to know your specific needs
          </ValueProp>

          <ValueProp heading="See list of dentists" index="2">
          Assess your personalized list of vetted dentists near you 
          </ValueProp>

          <ValueProp heading="Connect with dentist" index="3">
          Pick an amazing dentist & schedule an appointment
          </ValueProp>
        </Box>
        <Typography
          align="center"
          variant="h6"
          style={{ marginTop: 10, color: '#fff', fontSize: 14  }}>
          Find the right provider for you 
        </Typography>

        <Box display="flex" className="center_sign_provider">
          <Box flex={6}>
            <FilledTextField
              value={email}
              onChange={e => setEmail(e.target.value)}
              label="Your email address"
            />
          </Box>

          <Box flex={4}>
            <Button
              variant="contained"
              onClick={() => onSearch(email)}
              className="button_provider_start"
              style={{color: 'black', backgroundColor: '#FDED5C'}}>
              Get started
            </Button>
          </Box>
        </Box>
      </Box>

      <Box 
        bgcolor="#301EA1"
        py={3}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}>
        <h1
          style={{ marginTop: 10, color: '#fff' ,fontWeight: 'bolder' , fontSize: '35px', width: '100%', marginBottom: 30, textAlign: 'center'}}>
          What people are saying about SmileConnect
        </h1>
        
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="flex-column-mobile no-margin"
          maxWidth={1400}
          mx="auto">
          <WhiteCard>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} className="topNewgal">
                <img alt="newgal" src="/images/background2.jpeg" width="100%" height="300px" style={{objectFit: 'cover', objectPosition: 'top'}}/>
              </Grid>
              <Grid item md={9}>
                <p style={{fontSize: '21px', fontWeight: 'bold', lineHeight: '35px', height: '55%'}}>New patients are getting harder and harder to attract. Smile connect gives us access to high-quality patients to help grow our practice at no risk</p>
                <p style={{fontSize: '40px', fontWeight: 'bold'}}>Dr. Karen Renny</p>
              </Grid>
              <Grid item md={3} className="bottomNewgal">
                <img alt="newgal" src="/images/background2.jpeg" width="100%" height="300px" style={{objectFit: 'cover', objectPosition: 'top'}}/>
              </Grid>
            </Grid>
          </WhiteCard>
        </Box>
      </Box>

      <Box 
          py={3}
          px={{ xs: 3, md: 60 }}
          mx={{ xs: -2, md: -60 }}
          bgcolor="white"
          >
          <Box display="flex" mx="auto" maxWidth={1400} >
          <a style={{marginRight: '40px', color: 'black'}} href="/#/terms">Terms of use</a>
          <a style={{marginRight: '40px', color: 'black'}} href="/#/privacy">Privacy Policy</a>
          </Box>
        </Box>
    </>
  )
}

export default PatientHomeView
