import React, { useEffect, useRef, useState } from 'react'
import lodashDebounce from 'lodash/debounce'
import { Typography, MenuItem, MenuList, Paper, Box } from '@material-ui/core'
import FilledTextField from './FilledTextField'

export default function PlacesAutocomplete({
  debounceDuration = 500,
  value,
  highlightFirstSuggestion,
  onError,
  searchOptions,
  onSelect,
  onChange,
  children,
}) {
  const autocompleteService = useRef()
  const autocompleteOK = useRef()
  const mousedownOnSuggestion = useRef()
  const fetchPredictions = useRef()
  const [state, setState] = useState({
    loading: false,
    suggestions: [],
    userInputValue: value,
    ready: false,
  })

  const updateState = update => {
    setState(state => ({ ...state, ...update }))
  }

  useEffect(() => {
    if (state.ready) {
      return
    }
    if (!window.google || !window.google.maps.places) {
      setTimeout(() => setState({ ...state }), 500)
      return
    }

    autocompleteService.current = new window.google.maps.places.AutocompleteService()
    autocompleteOK.current = window.google.maps.places.PlacesServiceStatus.OK
    fetchPredictions.current = lodashDebounce(
      doFetchPredictions,
      debounceDuration
    )

    updateState({ ready: true })
    // eslint-disable-next-line
  }, [state])

  const doFetchPredictions = value => {
    if (value.length) {
      updateState({ loading: true })
      autocompleteService.current.getPlacePredictions(
        {
          ...searchOptions,
          input: value,
          componentRestrictions: {country: 'ca'},
        },
        (predictions, status) => {
          updateState({ loading: false })
          if (status !== autocompleteOK.current && onError) {
            onError(status, clearSuggestions)
            return
          }

          updateState({
            suggestions: predictions.map((p, idx) => ({
              id: p.id,
              description: p.description,
              placeId: p.place_id,
              active: highlightFirstSuggestion && idx === 0 ? true : false,
              index: idx,
              formattedSuggestion: formattedSuggestion(p.structured_formatting),
              matchedSubstrings: p.matched_substrings,
              terms: p.terms,
              types: p.types,
            })),
          })
        }
      )
    }
  }

  const setActiveAtIndex = index => {
    updateState({
      suggestions: state.suggestions.map((suggestion, idx) => {
        if (idx === index) {
          return { ...suggestion, active: true }
        } else {
          return { ...suggestion, active: false }
        }
      }),
    })
  }

  const selectUserInputValue = () => {
    updateState({
      suggestions: state.suggestions.map(suggestion => ({
        ...suggestion,
        active: false,
      })),
    })
    onChange(state.userInputValue)
  }

  const getActiveSuggestion = () => {
    return state.suggestions.find(suggestion => suggestion.active)
  }

  const handleSelect = (address, placeId, redirect = false) => {
    clearSuggestions()
    if (onSelect) {
      onSelect(address, placeId, redirect)
    } else {
      onChange(address)
    }
  }

  const selectActiveAtIndex = index => {
    const activeName = state.suggestions.find(
      suggestion => suggestion.index === index
    ).description
    setActiveAtIndex(index)
    onChange(activeName)
  }

  const clearSuggestions = () => {
    updateState({ suggestions: [] })
  }

  const handleEnterKey = () => {
    const activeSuggestion = getActiveSuggestion()
    if (activeSuggestion === undefined) {
      if (state.suggestions.length !== 0) {
        const firstVal = state.suggestions.find(
          suggestion => suggestion.index === 0
        ).description
        handleSelect(firstVal, null)
      } else {
        handleSelect(value, null)
      }
    } else {
      handleSelect(activeSuggestion.description, activeSuggestion.placeId, true)
    }
  }

  const handleDownKey = () => {
    if (state.suggestions.length === 0) {
      return
    }

    const activeSuggestion = getActiveSuggestion()
    if (activeSuggestion === undefined) {
      selectActiveAtIndex(0)
    } else if (activeSuggestion.index === state.suggestions.length - 1) {
      selectUserInputValue()
    } else {
      selectActiveAtIndex(activeSuggestion.index + 1)
    }
  }

  const handleUpKey = () => {
    if (state.suggestions.length === 0) {
      return
    }

    const activeSuggestion = getActiveSuggestion()
    if (activeSuggestion === undefined) {
      selectActiveAtIndex(state.suggestions.length - 1)
    } else if (activeSuggestion.index === 0) {
      selectUserInputValue()
    } else {
      selectActiveAtIndex(activeSuggestion.index - 1)
    }
  }

  const handleInputKeyDown = async event => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault()
        handleEnterKey()
        break
      case 'ArrowDown':
        event.preventDefault()
        handleDownKey()
        break
      case 'ArrowUp':
        event.preventDefault()
        handleUpKey()
        break
      case 'Escape':
        clearSuggestions()
        break
      default:
    }
  }

  const handleInputChange = event => {
    const { value } = event.target
    onChange(value)
    updateState({ userInputValue: value })
    if (!value) {
      clearSuggestions()
      return
    }
    if (fetchPredictions.current) {
      fetchPredictions.current(value)
    }
  }

  const handleInputOnBlur = () => {
    if (!mousedownOnSuggestion.current) {
      clearSuggestions()
    }
  }

  const getActiveSuggestionId = () => {
    const activeSuggestion = getActiveSuggestion()
    return activeSuggestion
      ? `PlacesAutocomplete__suggestion-${activeSuggestion.placeId}`
      : undefined
  }

  const getIsExpanded = () => {
    return state.suggestions.length > 0
  }

  const getInputProps = (options = {}) => {
    const defaultInputProps = {
      type: 'text',
      autoComplete: 'off',
      role: 'combobox',
      'aria-autocomplete': 'list',
      'aria-expanded': getIsExpanded(),
      'aria-activedescendant': getActiveSuggestionId(),
      disabled: !state.ready,
    }

    return {
      ...defaultInputProps,
      ...options,
      onKeyDown: compose(
        handleInputKeyDown,
        options.onKeyDown
      ),
      onBlur: compose(
        handleInputOnBlur,
        options.onBlur
      ),
      value: value,
      onChange: event => {
        handleInputChange(event)
      },
    }
  }

  const getSuggestionItemProps = (suggestion, options = {}) => {
    const boundHandleSuggestionMouseEnter = handleSuggestionMouseEnter.bind(
      this,
      suggestion.index
    )
    const boundHandleSuggestionClick = handleSuggestionClick.bind(
      this,
      suggestion
    )

    return {
      ...options,
      id: getActiveSuggestionId(),
      role: 'option',
      onMouseEnter: compose(
        boundHandleSuggestionMouseEnter,
        options.onMouseEnter
      ),
      onMouseLeave: compose(
        handleSuggestionMouseLeave,
        options.onMouseLeave
      ),
      onMouseDown: compose(
        handleSuggestionMouseDown,
        options.onMouseDown
      ),
      onMouseUp: compose(
        handleSuggestionMouseUp,
        options.onMouseUp
      ),
      onTouchStart: compose(
        handleSuggestionTouchStart,
        options.onTouchStart
      ),
      onTouchEnd: compose(
        handleSuggestionMouseUp,
        options.onTouchEnd
      ),
      onClick: compose(
        boundHandleSuggestionClick,
        options.onClick
      ),
    }
  }

  const handleSuggestionMouseEnter = index => {
    setActiveAtIndex(index)
  }

  const handleSuggestionMouseLeave = () => {
    mousedownOnSuggestion.current = false
    updateState({
      suggestions: state.suggestions.map(suggestion => ({
        ...suggestion,
        active: false,
      })),
    })
  }

  const handleSuggestionMouseDown = event => {
    event.preventDefault()
    mousedownOnSuggestion.current = true
  }

  const handleSuggestionTouchStart = () => {
    mousedownOnSuggestion.current = true
  }

  const handleSuggestionMouseUp = () => {
    mousedownOnSuggestion.current = false
  }

  const handleSuggestionClick = (suggestion, event) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    const { description, placeId } = suggestion
    handleSelect(description, placeId)
    setTimeout(() => {
      mousedownOnSuggestion.current = false
    })
  }

  return children({
    getInputProps: getInputProps,
    getSuggestionItemProps: getSuggestionItemProps,
    loading: state.loading,
    suggestions: state.suggestions,
  })
}

// transform snake_case to camelCase
const formattedSuggestion = structured_formatting => ({
  mainText: structured_formatting.main_text,
  secondaryText: structured_formatting.secondary_text,
})

const compose = (...fns) => (...args) => {
  fns.forEach(fn => fn && fn(...args))
}

export function PlacesInput({ onSelect, onSubmit, placeholder, style }) {
  const [address, setAddress] = useState('')
  const ref = useRef()

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={(addr, placeId, redirect) => {

        geocodeByAddress(addr)
          .then((results = []) => {
            ref.current = {
              address: results[0],
            }
            setAddress(results[0].formatted_address)
            return getLatLng(results[0])
          })
          .then(latLng => {
            onSelect({ ...(ref.current || {}), ...latLng }, placeId, redirect)
          })
      }}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ ...style, margin: 0, position: 'relative' }}>
          <FilledTextField
            fullWidth
            label={placeholder}
            {...getInputProps({})}
          />
          <Box style={{ position: 'absolute', top: 80, left: 0, zIndex: 1 }}>
            {(true || suggestions.length > 0) && (
              <Paper>
                <MenuList>
                  {loading && <Typography>Loading...</Typography>}
                  {suggestions.map(suggestion => (
                    <MenuItem key={`suggestion${suggestion.id}`}>
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item',
                        })}
                        style={{width: '100%'}}>
                        <span>{suggestion.description}</span>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            )}
          </Box>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export const geocodeByAddress = address => {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}

export const getLatLng = result => {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      }
      resolve(latLng)
    } catch (e) {
      reject(e)
    }
  })
}

export const geocodeByPlaceId = placeId => {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}
