import React, { useState, useContext, useEffect } from 'react'
import {
  Button,
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@material-ui/core'
import { format } from 'date-fns'
import WhiteCard from '../components/WhiteCard'
import { PersonalCard } from '../components/PersonalCard'
import { FirebaseContext } from '../utils/base'
import PhoneInput from '../components/PhoneInput'
import pick from 'lodash/pick'
import { 
  BADGES,
} from '../constants'
import ReviewCard from '../components/ReviewCard'
import BadgeItem from '../components/BadgeItem'
import GiftForm from '../components/GiftCardForm'

import '../containers/Search.css'

export default function AppointmentForm({
  provider,
  setConfirmed,
  patients,
  providers,
  history,
  patientData,
}) {
  const firebase = useContext(FirebaseContext)
  //const [date, setDate] = useState()
  //const [time, setTime] = useState('')
  //const [open, setOpen] = useState(true)
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    password: '',
  });

  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const list_placeholder = [
    'Step 1: Request appointment. Done ;)', 
    'Step 2: You’ll be contacted to book appointment with the dentist in the coming days.', 
    'Step 3: Get the treatment you need.'
  ]

  var badge_arr = provider ? Object.entries(pick(provider, Object.keys(BADGES))).filter(([label, value]) => value === 'true' || value === true) : []

  const badge_key = Object.keys(BADGES)

  const badge_count = badge_arr.length;

  if (badge_arr.length > 0) {
    badge_arr = badge_arr.map(([label, value]) => [label, value, badge_key.indexOf(label)])
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  const emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  return (
    <Box maxWidth={1400} mx="auto">
      <Grid container spacing={3}>
        <Grid item md={6}>
          <WhiteCard title={"Create your account to confirm request"}>
          <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange('name')}
                  label="NAME"
                  placeholder="name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  value={values.email}
                  onChange={handleChange('email')}
                  label="EMAIL"
                  placeholder="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <PhoneInput label="PHONE" value={phone} onChange={e => setPhone(e)} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="password"
                  label="CREATE PASSWORD"
                  placeholder="CREATE PASSWORD"
                  fullWidth
                  value={values.password}
                  onChange={handleChange('password')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <p style={{opacity: error === '' ? 0 : 1, color: 'red'}}>{error}</p>
                <Button variant="contained" style={{
                  width: '100%',
                  height: '60px',
                  backgroundColor: '#0D1C86',
                  color: 'white',
                }}
                onClick={async () => {
                  const { files, location, ...restUserData } = patientData

                  if (values.email === '' || !emailIsValid(values.email)) {
                    setError("Please input valid email!")
                  } else if (values.password === '') {
                    setError("Please input password!")
                  } else if (values.password.length  < 6) {
                    setError("Password must be more than 6 characters!")
                  } else if (phone.length < 10) {
                    setError("Please input valid phone number!")
                  } else if (values.name === '') {
                    setError("Please input your name!")
                  } else if (providers.filter(p => p.email === values.email).length !== 0 || patients.filter(p => p.email === values.email).length !== 0) {
                    setError("Email is already in use!")
                  } else {
                    setError("")

                    try {
                      await firebase.functions.httpsCallable('removeUserByEmail')({email: values.email})
                    }
                    catch(err) {
                      console.log(err)
                    }

                    await firebase.createAuthUser(values.email, values.password)
                    await firebase.createPatient({
                      email: values.email,
                      phone: phone,
                      name: values.name,
                      ...restUserData,
                      // lat: location ? location.lat : null,
                      // lng: location ? location.lng : null,
                      address: location ? location.address.formatted_address : null,
                    })
        
                    await firebase.addImagesToModel(files, 'patients', values.email)
        
                    var appointment = {}
                    appointment['patient'] = values.email
                    appointment['provider'] = provider.email ? provider.email : provider.name
                    appointment['date'] = format(new Date(), 'yyyy-MM-dd')
                    appointment['pending'] = 0

                    await firebase.db.collection('appointments').add(appointment)
                    await firebase.auth.signInWithEmailAndPassword(
                      values.email,
                      values.password
                    )
                    setConfirmed(appointment)
                  }
                }}>Continue</Button>
              </Grid>
            </Grid>
          </WhiteCard>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography variant="h5">
          Remember <span role="img" aria-label="secret">&#9757;</span>
          </Typography>
          <List>
            {list_placeholder.map(item => (
              <ListItem key={`item__${item}`}>
                <ListItemText
                  primary={` •  ${item}`}
                />
              </ListItem>
            ))}
          </List>
          <GiftForm/>
          <div style={{marginTop: '10px'}}>* Gift card eligibility is based on paid proof of treatment received. See <a style={{ color: 'blue'}} href="/#/terms">terms and conditions</a> for more details.</div>
        </Grid>
        <Grid item md={12} sm={12} xs={12} key={`provider-${provider.uid}`}>
            <WhiteCard bgColor='#0D1C86'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <PersonalCard 
                  provider={provider}
                  bgColor='white'
                  isFirst={true}
                  hideAlways={true}
                  >
                    <div className="leftVerified" style={{height: '20px', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#CFF4D2', padding: '5px 0', borderRadius: '5px', marginBottom: '5px', opacity: provider.isSmileConnectVerified ? '1' : '0'}}>
                    ✓ SmileConnect Verified
                    </div>

                    <Grid container className="badge_symbol" justify="space-between" style={{marginBottom: '10px', color: 'white'}}>
                      {
                        badge_count > 4 ? 
                        badge_arr.slice(0, 3).map(([label, value, val]) => (
                          <Grid item key={`badge_${label}_${val}`}>
                            <BadgeItem badge={val}/>
                          </Grid>
                        )) : 
                        badge_arr.map(([label, value, val]) => (
                          <Grid item key={`badge_${label}_${val}`}>
                            <BadgeItem badge={val}/>
                          </Grid>
                        ))
                      }
                      {
                        badge_arr.length > 4 &&
                        <Grid item>
                          <BadgeItem badge={-1} other={badge_count-3}/>
                        </Grid>
                      }
                    </Grid>
                  </PersonalCard>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="rightCol">
                  <div style={{height: '20px', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#CFF4D2', padding: '5px 0', borderRadius: '5px', marginBottom: '5px', opacity: provider.isSmileConnectVerified ? '1' : '0'}}>
                  ✓ SmileConnect Verified
                  </div>
                  {
                    provider.review && (<div>
                      <Typography variant="h6" style={{color: 'white'}}>
                      Reviews
                      </Typography>
                      <ReviewCard
                      reviewText= {provider.review[0].reviewText}
                      reviewRating={provider.review[0].reviewRating}
                      reviewerName={provider.review[0].reviewerName}/>
                    </div>)
                  }
                </Grid>
              </Grid>
          </WhiteCard>
        </Grid>
    </Grid>

    </Box>
  )
}
