import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'

function CustomCheck(props) {
  return (
    <>
      {props.children}
      <FormControlLabel
          label={props.label}
          onClick={e => {
            e.preventDefault()
            props.onClick(e)
          }}
          control={<Checkbox checked={props.checked} onClick={props.onClick}/>}
      />
    </>
  )
}

export default CustomCheck


