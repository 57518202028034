import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin'

import { 
  PROCEDURES, 
  OFFICE_AMENITIES, 
  INSECURE_AND_PAYMENTS,
  PRACTICE_TECHNOLOGY,
  HOURS_APPOINTMENTS,
  BADGES,
  HIDE_CARD,
} from '../constants'

export const firestoreProvider = firebase => (type, _resource, params) => {
  const resource = _resource.replace('admin/', '')
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      var query =
        field === 'id'
          ? firebase.db.collection(resource)/*.limit(page * perPage)*/
          : firebase.db
              .collection(resource)
              // .limit(page * perPage)
              .orderBy(field, order.toLowerCase())

      return query.get().then(QuerySnapshot => {
        var totalCount = QuerySnapshot.docs.length
        var lastIndex =
          Math.min(page * perPage, totalCount)
        var firstDocToDisplay = QuerySnapshot.docs.slice(
          (page - 1) * perPage, lastIndex
        )
        return {
          data: firstDocToDisplay.map(doc => getDataWithId(doc)),
          total: totalCount,
        }
      })
      .then(result => {
        var result1 = result
        if (resource !== 'appointments') {
          return result
        }
        return Promise.all(
          result1.data.map(appointment => {
            return firebase.db.collection('providers').where('email', '==', appointment.provider).limit(1).get().then(snap => {
              return (snap.docs[0].data());
            }).then(provider => {
              return provider.isApproved ? {...appointment, isVerified: provider.isApproved} : appointment
            }).catch(err => {
              return appointment
            })
          })
        ).then(result2 => {
          return {data: result2, total: result1.total}
        })
      })
    }

    case GET_ONE: {
      return firebase.db
        .collection(resource)
        .doc(params.id)
        .get()
        .then(doc => {
          if (doc.exists) {
            return { data: getDataWithId(doc) }
          } else {
            throw new Error({ message: 'No such doc', status: 404 })
          }
        })
        .catch(error => {
          throw new Error({ message: error, status: 404 })
        })
    }

    case UPDATE:
    case CREATE: {
     
      var listOfFiles = [];
      if (params.data.images) {
        listOfFiles = Object.keys(params.data.images).filter(
          key => params.data.images[key].rawFile
        )
      }

      var listOfFiles1 = [];

      if (params.data.office_images) {
        listOfFiles1 = Object.keys(params.data.office_images).filter(
          key => params.data.office_images[key].rawFile
        )
      }

      return Promise.all(
        listOfFiles.map(key => {
          return createOrUpdateFile(
            resource,
            params.data.images[key].rawFile,
            uploadFileToBucket,
            firebase
          ).then(downloadURL => {
            return { key: key, downloadURL: downloadURL, type: 0 }
          })
        }).concat(listOfFiles1.map(key => {
          return createOrUpdateFile(
            resource,
            params.data.office_images[key].rawFile,
            uploadFileToBucket,
            firebase
          ).then(downloadURL => {
            return { key: key, downloadURL: downloadURL, type: 1 }
          })
        })),
        type === UPDATE && firebase.functions.httpsCallable('updateUserByEmail')({email: params.data.email, password: params.data.password}),
        type === CREATE && firebase.functions.httpsCallable('createUserByEmail')({email: params.data.email, password: params.data.password, name: params.data.name, phone: params.data.phone}),
      ).then(arrayOfResults => {
        arrayOfResults.map(keyAndUrl => {
            if (keyAndUrl.type === 0) {
              params.data.images[keyAndUrl.key].url = keyAndUrl.downloadURL
              params.data.images[keyAndUrl.key].pathname = params.data.images[keyAndUrl.key].rawFile.name.slice(0, params.data.images[keyAndUrl.key].rawFile.name.length)

              delete params.data.images[keyAndUrl.key].rawFile
            } else {
              params.data.office_images[keyAndUrl.key].url = keyAndUrl.downloadURL
              params.data.office_images[keyAndUrl.key].pathname = params.data.office_images[keyAndUrl.key].rawFile.name.slice(0, params.data.office_images[keyAndUrl.key].rawFile.name.length)

              delete params.data.office_images[keyAndUrl.key].rawFile
            }

          return params.data
        })

        if (resource === 'providers') {
          var fields = []
          fields = fields.concat(Object.keys(PROCEDURES));
          fields = fields.concat(Object.keys(OFFICE_AMENITIES));
          fields = fields.concat(Object.keys(INSECURE_AND_PAYMENTS));
          fields = fields.concat(Object.keys(PRACTICE_TECHNOLOGY));
          fields = fields.concat(Object.keys(HOURS_APPOINTMENTS));
          fields = fields.concat(Object.keys(BADGES));
          var fields1 = Object.keys(HIDE_CARD);

          fields.forEach(key => {
            if (params.data[key] === undefined) {
              params.data[key] = false;
            }
          })

          fields1.forEach(key => {
            if (params.data[key] === undefined) {
              params.data[key] = true;
            }
          })
        }

        if (type === CREATE) {
          if (resource === 'providers') {
            params.data.hours = [];
            var i;
            for (i=0;i<7;i++) {
              params.data.hours.push({availability: i < 6?true:false, start: "9:00 am", end: "5:00 pm"})
            }

            params.data.isProvider = true;
            params.data.isAdmin = false;
            params.data.isApproved = false;
          }

          else if (resource === 'patients') {
            params.data.isProvider = false;
          }
          else if (resource === 'appointments') {
            params.data.pending = 0;
          }


          return firebase.db
            .collection(resource)
            .add(params.data)
            .then(DocumentReference =>
              DocumentReference.get().then(DocumentSnapshot => {
                return { data: getDataWithId(DocumentSnapshot) }
              })
            )
        }


        if (type === UPDATE) {
          if (resource === 'providers') {
            if (!params.data.hours) {
              params.data.hours = [];
              for (i=0;i<7;i++) {
                params.data.hours.push({availability: i < 6?true:false, start: "9:00 am", end: "5:00 pm"})
              }
            }
            else if (params.data.hours.length < 7) {
              params.data.hours = [];
              for (i=0;i<7;i++) {
                params.data.hours.push({availability: i < 6?true:false, start: "9:00 am", end: "5:00 pm"})
              }
            }
          }

          const { password, ...paramsData} = params.data

          return firebase.db
            .collection(resource)
            .doc(params.id)
            .set(paramsData)
            .then(() => {
              return { data: paramsData }
            })
        }
      })
    }

    case UPDATE_MANY: {
      return params.ids.map(id =>
        firebase.db
          .collection(resource)
          .doc(id)
          .set(params.data)
          .then(() => id)
      )
    }

    case DELETE: {
      return firebase.db
        .collection(resource)
        .doc(params.id)
        .delete()
        .then(() => {
          return { data: params.previousData }
        })
    }

    case DELETE_MANY: {
      return {
        data: params.ids.map(id =>
          firebase.db
            .collection(resource)
            .doc(id)
            .delete()
            .then(() => id)
        ),
      }
    }

    case GET_MANY: {
      return Promise.all(
        params.ids.map(id =>
          firebase.db
            .collection(resource)
            .doc(id)
            .get()
        )
      ).then(arrayOfResults => {
        return {
          data: arrayOfResults.map(documentSnapshot =>
            getDataWithId(documentSnapshot)
          ),
        }
      })
    }

    case GET_MANY_REFERENCE: {
      const { target, id } = params
      const { field, order } = params.sort
      return firebase.db
        .collection(resource)
        .where(target, '==', id)
        .orderBy(field, order.toLowerCase())
        .get()
        .then(QuerySnapshot => {
          return {
            data: QuerySnapshot.docs.map(DocumentSnapshot =>
              getDataWithId(DocumentSnapshot)
            ),
            total: QuerySnapshot.docs.length,
          }
        })
    }

    default: {
      throw new Error(`Unsupported Data Provider request type ${type}`)
    }
  }
}

function getDataWithId(DocumentSnapshot) {
  var dataWithId = {}
  if (DocumentSnapshot) {
    dataWithId = {
      id: DocumentSnapshot.id,
      password: "++++++",
      ...DocumentSnapshot.data(),
    }
  }
  return dataWithId
}

async function uploadFileToBucket(rawFile, storageRef) {
  return storageRef
    .put(rawFile)
    .then(snapshot => {
      return storageRef.getDownloadURL()
    })
    .catch(error => {
      throw new Error({ message: error.message_, status: 401 })
    })
}

async function createOrUpdateFile(resource, rawFile, uploadFile, firebase) {

  var storageRef = firebase.storageRef.child(resource + '/' + rawFile.name)
  return storageRef
    .getMetadata()
    .then(metadata => {
      if (metadata && metadata.size === rawFile.size) {
        return storageRef.getDownloadURL()
      } else {
        return uploadFile(rawFile, storageRef)
      }
    })
    .catch(() => {
      return uploadFile(rawFile, storageRef)
    })
}
