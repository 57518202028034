import React, { useContext, useState, useEffect } from 'react'
import { HashRouter as Router, Route, Redirect, useLocation } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import Login from './Login'
import PatientHome from './PatientHome'
import Admin from './Admin'
import ProviderHome from './ProviderHome'
import Dashboard from './ProviderDashboard'
import ProviderShow from './ProviderShow'
import Appointments from './Appointments'
import ProviderPayment from './ProviderPayment'
import ProviderSignUp from './ProviderSignUp'
import Search from './Search'
import TopDentist from './TopDentist'
import Quiz from './Quiz'
import PatientSignUp from './PatientSignUp'
import TopBar from '../components/TopBar'
import { APP_BAR_HEIGHT } from '../constants'

import { FirebaseContext } from '../utils/base'
import { Box } from '@material-ui/core'
import Loader from '../components/Loader'
import Terms from './Terms'
import Privacy from './Privacy'
import './App.css'
//import { TitleComponent } from '../components/TitleComponent';

const App = () => {
  const GCP_KEY = process.env.REACT_APP_FIREBASE_KEY
  const firebase = useContext(FirebaseContext)
  const patients = useFirebaseCollection('patients')
  const providers = useFirebaseCollection('providers')
  const offices = useFirebaseCollection('offices')
  const appointments = useFirebaseCollection('appointments')
  const searchedDentists = useFirebaseCollection('search')
  const [state, setState] = useState({ loading: true, first: true })
  const [patientData, setPatientData] = useState()
  const [results, setResults] = useState([])

  const parsedPatientData = localStorage.getItem('patientData')

  useEffect(() => {
    parsedPatientData && setPatientData(JSON.parse(parsedPatientData))
  }, [parsedPatientData])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GCP_KEY}&libraries=places`
    document.body.appendChild(script)
    
  }, [GCP_KEY])

  useEffect(() => {
    const unsub = firebase.auth.onAuthStateChanged(authUser => {
      if (patients.length > 0 && providers.length > 0 && appointments.length > 0 && authUser) {
        setState({
          loading: false,
          first: false,
          authUser,
          currentUser: [...patients, ...providers].find(
            ({ email }) => email.toLowerCase() === authUser.email
          ),
        })
      }
    })

    return () => unsub()
  }, [providers, patients, appointments, firebase.auth])

  useEffect(() => {
    if (patients.length > 0 && providers.length > 0 && appointments.length > 0 && state.first === true) {
      setTimeout(() => setState(state => ({ ...state, loading: false, first: false })), 500)
    }
  })
  
  // const withTitle = ({ component: Component, title }) => {
  //     return class Title extends Component {
  //         render() {
  //             return (
  //                 <React.Fragment>
  //                     <TitleComponent title={title} />
  //                     <Component {...this.props} />
  //                 </React.Fragment>
  //             );
  //         }
  //     };
  // };
  const { currentUser, loading } = state

  const data = {
    firebase,
    currentUser,
    offices,
    appointments,
    patientData,
    patients,
    searchedDentists,
    results,
    setResults,
    setPatientData: update => {
      setPatientData({ ...patientData, ...update })
      localStorage.setItem(
        'patientData',
        JSON.stringify({ ...patientData, ...update })
      )
    },
    providers: providers.map(provider => {
      // const office = offices.find(office => office.provider === provider.email)
      // if (office) {
      //   return {
      //     ...provider,
      //     address: office ? office.formatted_address : '',
      //   }
      // } else {
        return { ...provider }
      // }
    }),
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Container
          currentUser={currentUser}
          loading={/*!window.location.hash.match(/^#\/(pro)?$/) && */loading}>
          {/* public*/}
          <Route
            exact
            path="/"
            render={props => <PatientHome {...props} {...data} />}
          />

          <Route
            exact
            path="/terms"
            render={props => <Terms/>}
          />

          <Route
            exact
            path="/privacy"
            render={props => <Privacy/>}
          />

          <Route
            exact
            path="/pro"
            render={props => <ProviderHome {...props} {...data} />}
          />

          <Route
            exact
            path="/login/:username?"
            render={props =>
              <Login {...props} {...data} />
            }
          />

          <Route
            exact
            path="/logout"
            render={props => <Logout {...props} {...data} />}
          />

          <Route
            exact
            path="/search"
            render={props => {
                if (currentUser) {
                  if (currentUser.completedQuiz || currentUser.isProvider) {
                    return (<Search {...props} {...data} />)
                  } else {
                    return (<Quiz {...props} {...data} toWhere={true} />)
                  }
                } else if (patientData && patientData.completedQuiz) {
                  return (<Search {...props} {...data} />)
                } else {
                  return (<Quiz {...props} {...data} toWhere={true}/>)
                }
              }
            }
          />

          <Route
            exact
            path="/top/:location"
            render={props => {
                return (<TopDentist {...props} {...data} />)
              }
            }
          />
          
          <Route
            exact
            path="/quiz"
            render={props => {
              if (currentUser && currentUser.completedQuiz) {
                return (<Quiz {...props} {...data} toWhere={false}/>)
              }
            }}
          />

          <Route
            exact
            path="/appointments/:nextSteps?"
            render={props =>
              currentUser ? (
                <Appointments {...props} {...data} />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            exact
            path="/signup"
            render={props =>
              currentUser || !patientData ? (
                <Redirect to="/search" />
              ) : (
                <PatientSignUp {...props} {...data} />
              )
            }
          />

          <Route
            exact
            path="/search/:provider/:appointment?"
            render={props => <ProviderShow {...props} {...data} />}
          />

          {/* provider */}
          <Route
            exact
            path="/pro/dashboard/:appointments?"
            render={props =>
              currentUser.isProvider ? (
                <Dashboard {...props} {...data} />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            exact
            path="/pro/signup/:email?"
            render={props =>
              currentUser ? (
                <Redirect to="/" />
              ) : (
                <ProviderSignUp {...props} {...data} />
              )
            }
          />

          <Route
            exact
            path="/pro/payment"
            render={props =>
              currentUser.isProvider ? (
                <ProviderPayment {...props} {...data} />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          {/* admin */}
          <Route
            path="/admin"
            render={props =>
              currentUser.isAdmin ? <Admin {...props} {...data} /> : <Redirect to="/" />
            }
          />
        </Container>
      </Router>
    </MuiThemeProvider>
  )
}

export default App

const Logout = ({ history }) => {
  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    firebase.auth.signOut()
    localStorage.removeItem('patientData')
    history.push('/')

    setTimeout(() => {
      window.location.reload()
    }, 500)
    // eslint-disable-next-line
  }, [])

  return null
}

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#2CD5A2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D52CAF',
      contrastText: '#fff',
    },
  },
})

const useFirebaseCollection = collectionName => {
  const firebase = useContext(FirebaseContext)
  const [collection, setCollection] = useState([])

  useEffect(() => {
    const unsub = firebase.db
      .collection(collectionName)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let collection = []
          snapshot.forEach(doc =>
            collection.push({ ...doc.data(), uid: doc.id })
          )
          setCollection(collection)
        }
      })

    return unsub
    // eslint-disable-next-line
  }, [])

  return collection
}

const Container = ({ currentUser, children, loading }) => (
  <Box minHeight="100vh"
       maxWidth={'100%'}
       minWidth={350}
       bgcolor="#CCDAEB"
       mx="auto"
       padding={2}
       paddingTop={`${APP_BAR_HEIGHT + 15}px`}
       display="flex"
       flexDirection="column"
       className="total_container">
        
    <Box maxWidth={1800} mx="auto" style={{ width: '100%' }}>
      <Helmet>
        <link rel="canonical" href={"https://smileconnect.co" + useLocation().pathname} />
      </Helmet>
      <TopBar currentUser={currentUser} />
      {loading && window.location.hash !== '#/' ? <Loader label="Loading..." /> : children}
    </Box>
  </Box>
)
