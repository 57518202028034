import React, { useContext, useState } from 'react'
import { FirebaseContext } from '../utils/base'
import { Typography, Box } from '@material-ui/core'
import { Button } from '@material-ui/core'
import TextField from '../components/FilledTextField'
import PhoneInput from '../components/PhoneInput'
import Checkboxes from '../components/Checkboxes'
import { 
  SERVICES, 
  FEATURES,
  PROCEDURES, 
  OFFICE_AMENITIES, 
  INSECURE_AND_PAYMENTS,
  PRACTICE_TECHNOLOGY,
  HOURS_APPOINTMENTS,
  BADGES,
  HIDE_CARD, 
} from '../constants'

const ProviderSignUpContainer = ({ history, patientData = {}, match, providers, patients }) => {
  const firebase = useContext(FirebaseContext)
  const [error, setError] = useState('')

  const emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  return (
    <ProviderSignUpView
      patientData={patientData}
      email={match.params.email}
      error={error}
      onSubmit={async provider => {
        if (provider.email === '' || !emailIsValid(provider.email)) {
          setError("Please input valid email!")
        } else if (provider.password === '') {
          setError("Please input password!")
        } else if (provider.password.length  < 6) {
          setError("Password must be more than 6 characters!")
        } else if (provider.phone.length < 10) {
          setError("Please input valid phone number!")
        } else if (provider.name === '') {
          setError("Please input your name!")
        }
        else if (providers.filter(p => p.email === provider.email).length !== 0 || patients.filter(p => p.email === provider.email).length !== 0) {
          setError("Email is already in use by other one!")
        } else {
          setError("")
          try {
            await firebase.functions.httpsCallable('removeUserByEmail')({email: provider.email})
            await firebase.createAuthUser(provider.email, provider.password)
            var { password, ...providerObject } = provider

            console.log("providerObject",   )

            var fields = []
            fields = fields.concat(Object.keys(PROCEDURES));
            fields = fields.concat(Object.keys(OFFICE_AMENITIES));
            fields = fields.concat(Object.keys(INSECURE_AND_PAYMENTS));
            fields = fields.concat(Object.keys(PRACTICE_TECHNOLOGY));
            fields = fields.concat(Object.keys(HOURS_APPOINTMENTS));
            fields = fields.concat(Object.keys(BADGES));
            var fields1 = Object.keys(HIDE_CARD);

            fields.forEach(key => {
              if (providerObject[key] === undefined) {
                providerObject[key] = false;
              }
            })

            fields1.forEach(key => {
              if (providerObject[key] === undefined) {
                providerObject[key] = true;
              }
            })

            await firebase.getProviders().add(providerObject)

            await firebase.auth.signInWithEmailAndPassword(
              provider.email, provider.password
            )

          } catch (error) {
            console.log(error)
          } finally {

            setTimeout(() => history.push('/pro/dashboard'), 500)

            if (patientData.location && patientData.location.address) {
              const offices = firebase.geo.collection('offices')
              const point = firebase.geo.point(
                patientData.location.lat,
                patientData.location.lng
              )
              offices.add({
                position: point.data,
                address_components: JSON.stringify(
                  patientData.location.address.address_components
                ),
                formatted_address: patientData.location.address.formatted_address,
                place_id: patientData.location.address.place_id,
                provider: provider.email,
              })
            }
          }
        }
      }}
    />
  )
}

export default ProviderSignUpContainer

const ProviderSignUpView = ({ error, patientData, onSubmit, email }) => {
  const address = patientData.location && patientData.location.address
    ? patientData.location.address.formatted_address
    : undefined
  return (
    <>
      <Typography variant="h6">Start Receiving New Patients</Typography>

      <Typography variant="body1">
        Tell us more about your clinic to get started
      </Typography>

      <SignUpForm error={error} email={email} address={address} onSubmit={onSubmit} />
    </>
  )
}

const SignUpForm = ({ error, email: _email = '', onSubmit, address = '' }) => {
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState(_email)
  const [phone, setPhone] = useState('')
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        var hours = []
        for (var i=0;i<7;i++) {
          hours.push({availability: i < 6?true:false, start: "9:00 am", end: "5:00 pm"})
        }
        onSubmit({
          email,
          name,
          password,
          phone,
          address,
          avgRating: 5,
          numReviews: parseInt(Math.random() * 100 + 300),
          isProvider: true,
          isAdmin: false,
          isApproved: false,
          hours: hours,
        })
      }}
      style={{ margin: '30px 0' }}>
      <Box display="flex" className="flex-column-mobile">
        <Box flex={1} ml={1} mr={1}>
          <TextField
            label="name"
            placeholder="name"
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <TextField
            label="email"
            placeholder="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <p style={{opacity: error === '' ? 0 : 1, color: 'red'}}>{error}</p>
        </Box>
        <Box flex={1} ml={1} mr={1}>
          <TextField
            type="password"
            label="password"
            placeholder="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <PhoneInput label="phone" value={phone} onChange={e => setPhone(e)} />
        </Box>
      </Box>
      <Box display="flex" className="flex-column-mobile">
        <ProviderServices style={{ flex: 1 }} />
        <ProviderFeatures style={{ flex: 1 }} />
      </Box>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{ minWidth: 300, display: 'block', margin: '30px auto' }}>
        Submit
      </Button>
    </form>
  )
}

function ProviderServices({ style }) {
  const [services, setServices] = useState({ ...SERVICES })

  return (
    <Checkboxes
      className="buffer-y"
      heading="Services"
      style={style}
      boxes={Object.entries(services).map(([label, value]) => ({
        label,
        value,
        onChange: e =>
          setServices(services => ({ ...services, [label]: !services[label] })),
      }))}
    />
  )
}

export function ProviderFeatures({ style }) {
  const [features, setFeatures] = useState({ ...FEATURES })

  return (
    <Checkboxes
      className="buffer-y"
      heading="Features"
      style={style}
      boxes={Object.entries(features).map(([label, value]) => ({
        label,
        value,
        onChange: e =>
          setFeatures(features => ({ ...features, [label]: !features[label] })),
      }))}
    />
  )
}
