import React from 'react'
import {
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Box,
  Grid,
} from '@material-ui/core'
import SignupButton from '../components/SignupButton'
import WhiteCard from '../components/WhiteCard'
import './PatientHome.css'
import { Helmet } from 'react-helmet'
import GiftForm from '../components/GiftCardForm'

function PatientHomeView({ history, setPatientData, currentUser, patientData }) {
  const onSearch = location => {
    setPatientData({ location })
    history.push('/search')
  }

  const provider = currentUser

  var currentLocation;
  if (provider && provider.address) {
    currentLocation = provider.address;
  } else if(patientData && patientData.location && patientData.location.address) {
    currentLocation = patientData.location;
  } else {
    currentLocation = '';
  }

  return (
    <>
      <Helmet>
      <title>Find the right Invisalign provider near you today - Smile Connect</title>
      <meta name="description" content="Get started with your custom smile assessment" />
      <meta name="keywords" content="smileconnect, dentist, canada" />
      </Helmet>
      <Box
        bgcolor="#fff"
        py={{xs: 0, sm: 0, md: 8}}
        mt={-2}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}
        style={{ boxSizing: 'border-box' }}>
        <Box display="flex" mx="auto" maxWidth={1400}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex={1}
            maxWidth={600}
            zIndex={1}>
            <h1
              style={{ color: '#301EA1', fontWeight: 'bold', textAlign: 'left' }}>
              Find the right Invisalign provider near you today
            </h1>
            <Typography
              align="left"
              variant="body1"
              style={{ marginTop: 10, color: '#301EA1', fontSize: '20px' }}>
              Get started with your custom smile assessment.
            </Typography>
            <SignupButton
              maxWidth={900}
              placeholder="Where are you located?"
              onSearch={onSearch}
              currentLocation={currentLocation}
              label_color="#301EA1">
              Get Started
            </SignupButton>
            <GiftForm/>
            <Typography
              align="left"
              variant="body1"
              style={{ marginTop: 10, color: '#7A7A80', fontSize: '12px'  }}>
              <a style={{ color: 'blue'}} href="/#/terms">Terms and conditions</a> apply. Upon successful treatment completion
            </Typography>

            <img
              src="/images/111image_2-removebg-preview.png"
              alt="smile"
              className="smile_back"
            />
          </Box>
        </Box>
      </Box>

      <Box
        bgcolor="#301EA1"
        py={3}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}>
        <h1
          style={{ marginTop: 10, color: '#fff' ,fontWeight: 'bolder', textAlign: 'center', width: '100%'}}>
          How it works
        </h1>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="flex-column-mobile"
          maxWidth={1400}
          mx="auto"
          style={{backgroundColor: '#301EA1'}}>
          <ValueProp heading="Take our smile assesement" index="1">
          Take our quick online smile quiz. Just a few questions to get to know your specific needs
          </ValueProp>

          <ValueProp heading="See list of dentists" index="2">
          Assess your personalized list of vetted dentists near you
          </ValueProp>

          <ValueProp heading="Connect with dentist" index="3">
          Pick an amazing dentist & schedule an appointment
          </ValueProp>
        </Box>
        <Typography
          align="center"
          variant="h6"
          style={{ marginTop: 10, color: '#fff', fontSize: 14  }}>
          Find the right provider for you
        </Typography>

        <SignupButton
          maxWidth={900}
          placeholder="Where are you located?"
          onSearch={onSearch}
          center={true}
          currentLocation={currentLocation}>
          Get Started
        </SignupButton>
      </Box>

      <Box
        bgcolor="#301EA1"
        py={3}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}>
        <h1
          style={{ marginTop: 10, color: '#fff', width: '100%', marginBottom: 30, textAlign: 'center'}}>
          What people are saying about SmileConnect
        </h1>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="flex-column-mobile no-margin"
          maxWidth={1400}
          mx="auto">
          <WhiteCard>
            <Grid container spacing={1}>
              <Grid item md={4} className="topNewgal">
                <img alt="newgal" src="/images/AdobeStock_174544330.jpeg" width="100%" height="auto"/>
              </Grid>
              <Grid item md={8} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <p style={{fontSize: '21px', fontWeight: 'bold', lineHeight: '35px'}}>"I'm really happy that I found Smile Connect! I'm new to the city and I needed a new dentist. I looked on google, yelp and scoured the internet to find someone, but it was a bit overwhelming.   The site is so easy to use, it saved me so much time and energy surfing the internet.  My dentist was amazing! He explained everything and the staff was so helpful and friendly. Great way to find a dentist! Highly recommended!"</p>
                <p style={{fontSize: '40px', fontWeight: 'bold'}}>Meghan H.</p>
              </Grid>
              <Grid item md={4} className="bottomNewgal" style={{textAlign: 'center', overflow: 'hidden', backgroundSize: 'cover', backgroundImage: 'url("/images/AdobeStock_174544330.jpeg")', backgroundPosition: '50% 50%'}}>
              </Grid>
            </Grid>
          </WhiteCard>
        </Box>

        <SignupButton
          maxWidth={900}
          placeholder="Where are you located?"
          onSearch={onSearch}
          center={true}
          currentLocation={currentLocation}>
          Get Started
        </SignupButton>
      </Box>

      <Box
        bgcolor="#301EA1"
        py={3}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}>
        <h1
          style={{ marginTop: 10, color: 'white' ,fontWeight: 'bolder', textAlign: 'center', width: '100%' }}>
          Learn more about Invisalign
        </h1>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="flex-column-mobile"
          maxWidth={1400}
          mx="auto"
          style={{backgroundColor: '#301EA1'}}>
          <LearnProp heading="7 Million" index="Patients treated">
          Patients treated worldwide - and growing
          </LearnProp>

          <LearnProp heading="93%" index="Highly recommend">
          Survey of Toronto based Invisalign patients.
          </LearnProp>

          <LearnProp heading="12 months" index="Fast results">
          Or less
          </LearnProp>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="flex-column-mobile"
          maxWidth={1400}
          mx="auto"
          style={{backgroundColor: '#301EA1'}}>
          <InvisalignCard url="https://blog.smileconnect.co/blog/2019-07-20-how-much-does-invisalign-cost/"
            text="How Much Does Invisalign Cost?"
            image="/images/AdobeStock_169232877.jpeg">
          </InvisalignCard>

          <InvisalignCard url="https://blog.smileconnect.co/blog/2019-07-19-invisalign-who-do-you-trust/"
            text="Invisalign - Who Do You Trust?"
            image="/images/AdobeStock_170121006.jpeg">
          </InvisalignCard>

        </Box>
      </Box>

      <Box
        py={3}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}
        style={{background: "linear-gradient(0deg, rgba(48, 30, 161, 0.35), rgba(48, 30, 161, 0.35)), url('/images/greatdentist.png')", backgroundPosition: 'center top', backgroundSize: 'cover'}}>
        <Box display="flex" mx="auto" maxWidth={1400} 
          flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex={1}
            maxWidth={900}>
            <h1
              style={{ color: '#fff', fontWeight: 'bold' }}>
              Are you a great dentist?
            </h1>
            <Typography
              align="left"
              variant="body1"
              style={{ marginTop: 10, color: '#fff', marginBottom: 30, fontSize: '20px' }}>
              Do your patients rave about you and your practice?
            </Typography>
            <Button variant="contained" className="button_signup"
              onClick={() => history.push('/pro/signup')}>Dentist Sign Up</Button>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flex={2}
            style={{marginTop: 50}}>
              <Grid container spacing={2}>
                <WhiteProp image="/images/serve_more_smiles.png" heading="Serve More Smiles">
                Serve more smiles by having your practice visible to new patients on Smile Connect.
                </WhiteProp>
                <WhiteProp image="/images/best_of_best.png" heading="Showcase Your Practice">
                Submit your application to become a Smile Connect provider.
                </WhiteProp>
              </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        py={3}
        px={{ xs: 3, md: 60 }}
        mx={{ xs: -2, md: -60 }}
        bgcolor="white"
        >
        <Box display="flex" mx="auto" maxWidth={1400} >
          <a style={{marginRight: '40px', color: 'black'}} href="/#/terms">Terms of use</a>
          <a style={{marginRight: '40px', color: 'black'}} href="/#/privacy">Privacy Policy</a>
        </Box>
      </Box>
    </>
  )
}

export default PatientHomeView

export function ValueProp({ children, index, heading }) {
  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      flexDirection="column"
      m={2}
      style={{textAlign: 'center', height: '200px'}}
      >
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        flexDirection="column"
        maxWidth={450}
        >
        <h4 className="numberWhite" style={{ color: '#fff' }}>{index}</h4>
        <Typography variant="h6" style={{ color: '#fff' }}>{heading}</Typography>
        <Typography variant="body1" style={{ color: '#fff', fontSize: 12 }}>{children}</Typography>
      </Box>
    </Box>
  )
}

export function InvisalignCard({ url, image, text }) {
  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      flexDirection="column"
      m={2}
      style={{textAlign: 'center', paddingTop: '20px'}}
      >
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        flexDirection="column"
        maxWidth={600}
        style={{width: '100%'}}
        bgcolor="white"
        >
        <div style={{textAlign: 'center', overflow: 'hidden', backgroundSize: 'cover', backgroundImage: `url(${image})`, backgroundPosition: '50% 50%', height: 'calc(100vw / 2.5 / 2.4)', minHeight: '180px', width: '100%'}}>

        </div>
        <Typography variant="h6" style={{ color: 'black', margin: '20px 10px', height: '64px' }}>{text}</Typography>
        <a href={url} target="_parent" style={{textDecoration: 'none', width: 'calc(100% - 50px)', padding: '25px'}}>
          <Button variant="contained" style={{
            width: '100%',
            height: '60px',
            backgroundColor: '#0D1C86',
            color: 'white',
          }}
          >Read article</Button>
        </a>
      </Box>
    </Box>
  )
}


export function LearnProp({ children, index, heading }) {
  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      flexDirection="column"
      m={2}
      style={{textAlign: 'center', height: '90px', paddingTop: '20px'}}
      >
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        flexDirection="column"
        maxWidth={450}
        >
        <Typography variant="h6" style={{ color: 'white' }}>{index}</Typography>
        <Typography variant="h4" style={{ color: 'white' }}>{heading}</Typography>
        <Typography variant="body1" style={{ color: 'white', fontSize: 12 }}>{children}</Typography>
      </Box>
    </Box>
  )
}

export function WhiteProp({ children, heading, image }) {
  return (
    <Grid item md={4}
      style={{textAlign: 'center', paddingTop: '20px', borderRadius: '10px', background: 'white', margin: '10px', width: '100%'}}
      >
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        flexDirection="column"
        >
        <img src={image} alt="dentist" width="100px"/>
        <Typography variant="h5" style={{ color: 'black' }}>{heading}</Typography>
        <Typography variant="body1" style={{ color: 'black', fontSize: 14 }}>{children}</Typography>
      </Box>
    </Grid>
  )
}

export function ImgMediaCard({
  title,
  body,
  size = 140,
  onClick,
  image = `https://via.placeholder.com/${size}`,
}) {
  return (
    <Card style={{ margin: 16 }}>
      <CardActionArea style={{ backgroundColor: '#ccc' }}>
        <CardMedia
          component="img"
          alt={title}
          height={size}
          width={size}
          image={image}
          title={title}
          style={{ height: 'auto', width: '100%', margin: '0 auto' }}
        />
        <CardContent style={{ backgroundColor: '#fff' }}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {body}
          </Typography>
        </CardContent>
      </CardActionArea>
      {onClick && (
        <CardActions>
          <Button onClick={onClick} size="small" color="primary">
            Learn More
          </Button>
        </CardActions>
      )}
    </Card>
  )
}
