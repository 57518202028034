import React from 'react'

function StyledText(props) {
  return (
    <div>
      <label style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)'}}>{props.label}</label>
      <div style={{marginTop: '5px'}}>{props.value}</div>
    </div>
  )
}

export default StyledText


