import React from 'react'
import { Box, Paper } from '@material-ui/core'

function WhiteCard(props) {
  return (
    <Box my={1}>
        <Paper style={{backgroundColor: props.bgColor ? props.bgColor : 'white'}}>
            <Box p={2}>
                <h1 style={{color: props.bgColor ? 'white' : 'black', fontSize: '2rem', display: props.title === '' || props.title === undefined ? 'none' : 'block'}}>{props.title}</h1>
                {props.children}
            </Box>
        </Paper>
    </Box>
  )
}

export default WhiteCard


