import React from 'react'
import { Box, Paper } from '@material-ui/core'

function GrayCard(props) {
  return (
    <Box my={1}>
        <Paper style={{backgroundColor: '#F4F5F9'}}>
            <Box p={2}>
                <h4>{props.title}</h4>
                {props.children}
            </Box>
        </Paper>
    </Box>
  )
}

export default GrayCard


