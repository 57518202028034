import React from 'react'

function BadgeItem({badge, other}) {
    return (
    <div style={{width: '70px', margin: '0 auto'}}>
        { badge === 0 &&
          <>
            <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
              <img width="70px" alt="Invisalign Gold" src="/images/badges-svg/gold.svg"/>
            </div>
            <div style={{textAlign: 'center'}}>
            Invisalign Gold
            </div>
          </>
        }

        { badge === 1 &&
          <>
            <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
              <img width="70px" alt="Invisalign Platinum" src="/images/badges-svg/platinum.svg"/>
            </div>
            <div style={{textAlign: 'center'}}>
            Invisalign Platinum
            </div>
          </>
        }

        { badge === 2 &&
          <>
            <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
              <img width="70px" alt="Invisalign Diamond" src="/images/badges-svg/diamond.svg"/>
            </div>
            <div style={{textAlign: 'center'}}>
            Invisalign Diamond
            </div>
          </>
        }

        { badge === 3 &&
          <>
            <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
              <img width="70px" alt="Many positive reviews for this doctor" src="/images/badges-svg/patientfavorite.svg"/>
            </div>
            <div style={{textAlign: 'center'}}>
              Patient Favourite
            </div>
          </>
        }
        { badge === 4 &&
          <>
            <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
              <img width="70px" alt="Creature comforts" src="/images/badges-svg/office.svg"/>
            </div>
            <div style={{textAlign: 'center'}}>
              Office Amenities 
            </div>
          </>
        }
        { badge === 5 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Use latest dental technology" src="/images/badges-svg/hitech.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Hi-Tech Office
            </div>
          </>
        }
        { badge === 6 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Work with your insurance provider" src="/images/badges-svg/insurance.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Insurance Friendly
            </div>
          </>
        }
        { badge === 7 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Specific areas in practice geared towards children" src="/images/badges-svg/kid.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Kid Friendly
            </div>
          </>
        }
        { badge === 8 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Known for friendly staff" src="/images/badges-svg/staff.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Friendly Staff
            </div>
          </>
        }

        { badge === 9 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Patient experience is top-notch" src="/images/badges-svg/convinient.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Convenient
            </div>
          </>
        }

        { badge === 10 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Highly skilled doctor in Cosmetics" src="/images/badges-svg/costraning.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Advanced Cosmetic Training
            </div>
          </>
        }

        { badge === 11 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Highly skilled doctor in Implants" src="/images/badges-svg/imptraining.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Advanced Implant Training
            </div>
          </>
        }

        { badge === 12 &&
          <>
          <div style={{margin: '20px auto 0 auto', textAlign: 'center'}}>
            <img width="70px" alt="Not their first rodeo!" src="/images/badges-svg/doc.svg"/>
          </div>
            <div style={{textAlign: 'center'}}>
            Experienced Doctor (10yrs+)
            </div>
          </>
        }

        {
          badge === -1 &&
          <>
          <div style={{margin: '20px auto 0 auto', width: '70px', height: '70px', backgroundImage: `url("/images/badges-svg/more.svg")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', textAlign: 'center', fontSize: '28px', color: '#301EA1', fontWeight: 'bold', lineHeight: '70px'}}>
            +{other}
          </div>
            <div style={{textAlign: 'center'}}>
            {other} more badges
            </div>
          </>
        }
    </div>
  )
}

export default BadgeItem
