import React from 'react'
import { Typography, Box, Grid } from '@material-ui/core'
import './PersonalCard.css'

const Rating = ({ provider: { avgRating = 'NA' }}) => {
  var rating = avgRating
  if (rating > 5) {
    rating = 5;
  }
  return (
    <Grid container spacing={3}>
      <Grid item >
        <Grid container>
          {Array(Math.round(rating))
            .fill()
            .map((_, index) => (
              <Grid item key={`grid-rating-${index}`}>
                <Dot key={`dot${index}`} />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item >
        {rating > 0 ? (
          <Typography>{rating} star rating</Typography>
        ) : (
          <Typography>No stars</Typography>
        )}
      </Grid>
    </Grid>
  );
}

const MobileRating = ({ provider: { avgRating = 'NA' }}) => {
  var rating = avgRating
  if (rating > 5) {
    rating = 5;
  }
  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={6} style={{margin: '0 auto', width: '50%'}}>
        <Grid container justify="space-evenly">
          {Array(Math.round(rating))
            .fill()
            .map((_, index) => (
              <Grid item key={`grid-rating-${index}`}>
                <Dot key={`dot${index}`} />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={6} style={{margin: '0 auto'}}>
        {rating > 0 ? (
          <Typography>{rating} star rating</Typography>
        ) : (
          <Typography>No stars</Typography>
        )}
      </Grid>
    </Grid>
  );
}

const Dot = () => (
  <div style={{
    color: '#EC9545',
    fontSize: "20px",
    display: 'inline',
    paddingRight: '8px'
  }}>
    ★
  </div>
)

export const PersonalCard = ({ provider, children, isFirst, bgColor, hideAlways }) => {
  const photo = provider.images && provider.images[0]
  return (
    <Box>
      <div style={{height: '20px', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#CFF4D2', padding: '5px 0', borderRadius: '5px', marginBottom: '5px', display: isFirst || hideAlways || !provider.isSmileConnectVerified ? 'none' : 'block'}}>
      ✓ SmileConnect Verified
      </div>
      <Box className="desktopVersion">
        <div style={{display: 'flex'}}>
          <Box
            width={120}
            height={120}
            mr={2}
            style={{
              overflow: 'hidden',
              borderRadius: '50%',
              maxWidth: '120px',
              minWidth: '120px',
              backgroundSize: 'cover',
              backgroundColor: 'grey',
              backgroundImage: photo ? `url('${photo.url}')` :  `url('/images/dentist_default.png')`,
              backgroundPositionX: 'center',
              backgroundPositionY: 'center'
            }}>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="center" color={bgColor?bgColor:'black'} width="100%" textAlign="left">
            <Box my={1}>
              <Typography variant="h5" style={{fontWeight: '400'}}>{provider.name}</Typography>
            </Box>
            <Box my={1}>
              <Typography>{provider.address}</Typography>
            </Box>
            <Box my={1} style={{display: isFirst ? 'none' : 'block'}}>
              <Rating provider={provider}/>
            </Box>
          </Box>
        </div>
        <div style={{display: isFirst ? 'block' : 'none', marginTop: '10px'}}>
          <Grid container>
            <Grid item md={6} lg={6} sm={6} xs={6} xl={6}>
              <Rating provider={provider}/>
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={6} xl={6}>
              <div style={{height: '20px', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#CFF4D2', padding: '5px 0', borderRadius: '5px', marginBottom: '5px'}}>
              ✓ SmileConnect Verified
              </div>
            </Grid>
          </Grid>
        </div>
      </Box>

      <Box className="mobileVersion" color={bgColor?bgColor:'black'}>
        <div style={{height: '20px', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#CFF4D2', padding: '5px 0', borderRadius: '5px', marginBottom: '5px', display: isFirst && !hideAlways && provider.isSmileConnectVerified ? 'block' : 'none'}}>
          ✓ SmileConnect Verified
        </div>

        <div style={{margin: '0 auto'}}>
          <Box
            width={120}
            height={120}
            style={{
              overflow: 'hidden',
              borderRadius: '50%',
              maxWidth: '120px',
              minWidth: '120px',
              backgroundSize: 'cover',
              backgroundColor: 'grey',
              backgroundImage: photo ? `url('${photo.url}')` :  `url('/images/dentist_default.png')`,
              backgroundPositionX: 'center',
              backgroundPositionY: 'center'
            }}>
          </Box>
        </div>

        <Box my={1}>
          <Typography variant="h5" style={{fontWeight: '700'}}>{provider.name}</Typography>
        </Box>

        <Box my={1}>
          <MobileRating provider={provider} />
        </Box>

        <Box my={1}>
          <Typography>{provider.address}</Typography>
        </Box>

      </Box>

      <Box>
        {children}
      </Box>
    </Box>
  )
}
