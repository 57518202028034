import React, {Component} from 'react'
import { Box } from '@material-ui/core'
import './Terms.css'

export default class Terms extends Component{

  constructor(props){
    super(props);
    this.myRef = React.createRef()
  }

  componentDidMount() {
    this.myRef.current.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }

  render() {
  return (
    <Box py={6}
    mt={-2}
    px={{ xs: 3, md: 60 }}
    mx={{ xs: -2, md: -60 }}
    style={{ boxSizing: 'border-box', color: '#6f6a71', background: 'white' }} ref={this.myRef}>
      <Box px={3} maxWidth={1000} mx="auto">
      <h2>Terms of Use</h2>
      <p>Last updated September 5, 2019</p>
      <p>
      This website/digital platform, owned and operated by Smile Connect Inc., (“Smile Connect”/ “we”/”us”), a company incorporated under the provincial laws of Canada. This document sets out important information regarding the terms and conditions of accessing and using the features and functionality of the Smile Connect website and services (<strong>“Service”</strong>, collectively <strong>“Services”</strong>) and applies to all users whether you access the Services as a Smile Connect Provider, Patient User or as a Guest to this Site (as defined below.).
      </p>
      <p>
      PLEASE READ THE FOLLOWING CAREFULLY. BY USING THIS SITE, YOU SIGNIFY YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS OF USE. IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS OF USE, PLEASE DO NOT USE THIS SITE.
      </p>
      <p>
        This agreement is the user agreement (the
        <strong>“Agreement”</strong>
         or
        <strong>“User Agreement”</strong>
        ) for Smile Connect. This Agreement describes the terms and conditions applicable to your use of our services available under the domain and sub-domains of <a href="/#" style={{color: '#35b5b9'}}>https://www.smileconnect.co</a> (the
        <strong>“Site”</strong>
        ). If you do not agree to be bound by the terms and conditions of this Agreement, do not access the Site or use the Services.
        <br /> You must read, agree with and accept all of the terms and conditions contained in this User Agreement.
      </p>

      <h4>1. Access Rights</h4>
      <ol>
        <li type="a">
          Users of the Site shall be considered as either “Guests,” “Members,” or “Referrals” as follows:
        </li>
        <ul>
          <li>
            “
            <strong>Guests</strong>
            ” are allowed access to a minimal level of Services available on the Site. Access to some features may be limited;
          </li>
          <li>
            “
            <strong>Members</strong>
            ” who are allowed to access additional features that are not otherwise available to Guests.
          </li>
          <li>
            “
            <strong>Referrals</strong>
            ” who are invited by Members to access certain Services.
          </li>
        </ul>
      </ol>

      <h4>2. Electronic Communications</h4>
      <ol type="a" start="2">
        <li>
        When you visit the Site or send e-mails, texts or other electronic messages to us, you are communicating with us electronically and you consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
        </li>
        <li>
        By signing up to use the member features of the Site, you acknowledge and agree that Smile Connect will send service-related e-mails relating to your account, including service updates, appointment reminders and booking confirmations. These communications can be managed through user features made available through the Site from time to time.
        </li>
        <li>
        If you are a member of the Smile Connect mailing list you will also receive email communications from us regarding our products, services and initiatives (including collaborations and partnerships). If you do not wish to receive these communications, you can unsubscribe from such promotional e-mails at any time by clicking on the unsubscribe link in any of our e-mail communications.
        </li>
        <li>
        If you sign up through Smile Connect to receive special offers regarding products and services from our partners, you authorize us, on behalf of your dentist, to share your email address and other Personal Information you authorize with the provider whose offer you wish to receive (for example, a free electronic toothbrush). If you request these special offers, you acknowledge the partner may also send you future offers for health-related benefits, products, and services that may interest you. You can opt out of future communications from Smile Connect by changing your user settings or emailing info@smileconnect.co; you can unsubscribe from future partner communications by following their unsubscribe and opt out instructions. You do not have to agree to participate in these offers in order to use Smile Connect or receive care from my dentist. You understand that the dentist and/or Smile Connect may receive financial remuneration in exchange for sharing your email address and other Personal Information you authorize with partners if you choose to sign up for an offer. Once you sign up for an offer, you can change your mind at any time, but if the dentist or Smile Connect has already relied on your authorization to share your email or other Personal Information for a particular offer, any action already taken cannot be undone.
        </li>
        <li>
        By using the member features of the Site (eg. to book appointments), you acknowledge and agree that Smile Connect will be transmitting your personal information (which may include protected health information (“PHI”) as that term is defined under the applicable legislation), electronically. You also agree that if you book an appointment with a dentist, we may use your PHI to send you additional offers and communications related to our services. Please see our <a href="/#/privacy" style={{color: '#35b5b9'}}>Privacy Policy</a> for further information.
        </li>
        <li>
        You acknowledge and agree that you are solely responsible for providing Smile Connect with accurate contact information, including your mobile device number and email address, where we may send communications containing your PHI. Smile Connect is not responsible for user errors and omissions.
        </li>
      </ol>

      <h4>3. Materials Posted on Site: Reviews and Ratings</h4>
      <ol type="a" start="8">
        <li>
          It is your sole responsibility to review the content of all materials submitted by you on the Site. If you are a health provider, in the event you discover any material about you that is inappropriate, it is your responsibility to report the issue to us for investigation and possible action. You acknowledge that we do not and cannot monitor all the material that is posted on the Site.
        </li>
        <li>
          We reserve the right to monitor all communications made by you through the Site to ensure compliance with this Agreement. Where we have determined in our sole discretion that a rating, review, posting, email, image, or communication (the “
          <strong>Inappropriate Material</strong>
          “) by you through the Site is in violation of this Agreement or any content guidelines, or is otherwise unacceptable, we reserve the right to remove, edit, move the Inappropriate Material.
        </li>
      </ol>

      <h4>4. Your Conduct When Using the Site</h4>
      <p>When using the Services available on the Site, you agree to use the Services in accordance with the following:</p>
      <ol type="a" start="10">
        <li>
          you will use the Site only for any the following general purposes and for no other purpose:
        </li>
          <ul>
            <li>
              finding a health care provider for yourself or another person;
            </li>
            <li>
              reading reviews and ratings of health care providers submitted by other Guests and Members;
            </li>
            <li>
              submitting your own reviews and ratings of health care providers who you have experience with;
            </li>
            <li>
            •	or any other purpose authorized by Smile Connect from time to time
            </li>
          </ul>
        <li>
          any review of a health care provider that you submit to be included on the Site:
        </li>
          <ul>
            <li>
              shall be a true representation of your experience with the particular health care provider;
            </li>
            <li>shall not be false, misleading, or untrue;</li>
            <li>
              shall not include your name or any other personally identifiable information that may be used to identify you or another patient;
            </li>
          </ul>
        <li>
          you acknowledge that:
        </li>
          <ul>
            <li>
              the list of health care providers listed on the Site may not be a complete list of every health care provider within the specified specialty category or specified distance of the addressed specified and may not be updated on a regular basis even if we have been advised of incorrect or incomplete information;
            </li>
            <li>
              the information contained about a particular health care provider may not be accurate or complete and may not be updated on a regular basis even if we have been advised of incorrect or incomplete information;
            </li>
            <li>
              any map displayed may not be complete, accurate, or up-to-date;
            </li>
            <li>
              any request to show results of health care providers who are accepting new patients may not be complete, accurate, or up-to-date and may not be updated on a regular basis even if we have been advised of incorrect or incomplete information;
            </li>
            <li>
              any records of a particular health care provider that that states such health care provider accepts new patients may not be complete, accurate, or up-to-date and may not be updated on a regular basis even if we have been advised of incorrect or incomplete information;
            </li>
          </ul>
        <li>
          any ratings or reviews on the Site:
        </li>
          <ul>
            <li>are submitted by other Users of the Site;</li>
            <li>may not be complete, accurate, or up-to-date;</li>
            <li>do not represent the views or opinions of Smile Connect;</li>
          </ul>
        <li>
          the inclusion of any health care provider on the Site shall not be considered as an endorsement of such health care provider by Smile Connect and shall not in any way mean that Smile Connect has conducted any due diligence or other investigation regarding the health care provider;
        </li>
        <li>
          you agree that the Site is NOT to be used by you as a source of medical advice or for the diagnosis of any medical condition;
        </li>
        <li>
          you agree that in the event of an emergency, you will call 9-1-1 and not rely on the information contained in the Site;
        </li>
        <li>
          you agree that you will not submit ratings or reviews of any health care provider which contains material that in the sole discretion of Smile Connect constitutes libel, slander, harassment, abuse or defamation, or is pornographic, racist, obscene, or otherwise offensive as determined by Smile Connect in its sole discretion;
        </li>
        <li>
          you agree that you will not use any of the Services in any way that infringes the privacy rights, intellectual property rights, or any other rights of any health care provider, patient, person or entity;
        </li>
        <li>
          you will not post or otherwise distribute messages or pictures or use any of the Services in any way which:
        </li>
        <ul>
          <li>
            violates, plagiarizes or infringes upon the rights of any third party, including but not limited to any copyright or trade-mark law, privacy or other personal or proprietary rights, including intellectual property rights; or
          </li>
          <li>
            is misleading, fraudulent, or is otherwise unlawful or violates any law;
          </li>
        </ul>
        <li>
          you will not use any of the Services to distribute, promote or otherwise publish any material containing any solicitation for funds, advertising or solicitation for goods or services, promoting any website;
        </li>
        <li>
          you will not post or transmit in any manner any contact information including, but not limited to, email addresses, instant messenger usernames, telephone numbers, postal mail addresses, website URLs, or full names through your publicly posted information;
        </li>
        <li>
          you will not use any paid advertising platform to promote links that direct to the Smile Connect domain or use the Smile Connect trademark.
        </li>
      </ol>

      <h4>5. Your Smile Connect Password</h4>
      <p>Smile Connect is entitled to act on instructions received under your password. You are responsible for keeping your user ID, password, and any and all other account information confidential. Smile Connect is not responsible for any actions taken or transaction made to or from your accounts by any other party using your password.</p>

      <h4>6. Proprietary Rights</h4>
      <ol type="a" start="23">
        <li>
          All materials on this Site, including, without limitation, ratings reviews, information, images, illustrations, audio clips, video clips, surveys and special promotions, are protected by intellectual property rights owned and controlled either by Smile Connect or its affiliates and subsidiaries, or by other Users or by third parties who have licensed their material to Smile Connect. Material from the Site may not be copied, downloaded, reproduced, republished, uploaded, posted, transmitted or distributed in any way without the prior written consent of Smile Connect or except as specifically permitted on the Site. Use of material for any purpose and other than your own personal, non-commercial or home use is a violation of copyrights and other intellectual property rights. The use of any such material on any other website, social network, blog, handheld device application, web based application, or networked computer is prohibited.
        </li>
        <li>
          The trade marks, logos and service marks that you see on this Site are registered and unregistered marks of Smile Connect or its affiliates and subsidiaries, or third parties who have licensed the use of such marks to Smile Connect. Nothing in this Site grants any license or right to use any marks displayed on this site without the written permission of the owner of the mark. Your misuse of the marks displayed on this Site or any other content on this Site is strictly prohibited.
        </li>
        <li>
          Smile Connect makes no representation that materials on the Site are appropriate or available for use in locations other than Canada. Those who choose to access this site from locations outside Canada do so on their own initiative and are responsible for compliance with local laws, if and to the extent, local laws apply.
        </li>
        <li>
          Smile Connect welcomes your comments on our services, including the Smile Connect Site. However, any notes, messages, billboard postings, ideas, suggestions or other material that you submit to Smile Connect will become the sole and exclusive property of Smile Connect. Smile Connect is entitled to use any material that you submit for any type of use forever in any media, whether now known or later developed. When you submit material to Smile Connect's Site, you agree that Smile Connect has the right to publish the material for any use as outlined above including, but not limited to, promotional and advertising purposes, without compensation to you of any kind. As a result, we must ask that you do not send us any original creative materials such as stories, product ideas or artwork.
        </li>
      </ol>

      <h4>7. Disclaimer and Disruptions or Interruptions in Service</h4>
      <p>THE MATERIALS IN THE Smile Connect SITE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED, UNLESS OTHERWISE SPECIFICALLY NOTED HEREIN. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, Smile Connect DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO THE Smile Connect SITE AND ANY WEB SITE WITH WHICH THE Smile Connect SITE IS LINKED. Smile Connect DOES NOT WARRANT THE FUNCTIONS, INFORMATION OR LINKS CONTAINED ON THESE SITES OR THAT THEIR CONTENTS WILL MEET YOUR REQUIREMENTS, THAT THESE SITES OR THEIR CONTENTS ARE FIT FOR ANY PARTICULAR PURPOSE OR THAT THE OPERATIONS OF THE Smile Connect NETWORK OR ITS CONTENTS WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT THESE SITES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES, WORMS OR OTHER HARMFUL COMPONENTS. WITHOUT LIMITATION, Smile Connect MAKES NO REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO ANY SPECIFIC MERCHANDISE OR SERVICE THAT WILL BE AVAILABLE FOR OR OBTAINED BY MEMBERS.</p>
      <p>IN THE EVENT THAT THERE IS AN INTERRUPTION OR DISRUPTION IN ANY OF THE SERVICES OFFERED BY Smile Connect FOR WHATEVER REASON, EVEN IF Smile Connect HAS BEEN MADE AWARE OF AN ISSUE THAT COULD RESULT IN OR IS LIKELY TO RESULT IN AN INTERRUPTION OR DISRUPTION OF SERVICES, Smile Connect SHALL NOT BE REQUIRED TO ISSUE A REFUND FOR ANY PURCHASE MADE BY YOU. AS SOON AS Smile Connect HAS IDENTIFIED THE CAUSE OF AN INTERRUPTION OR DISRUPTION, Smile Connect SHALL USE IT REASONABLE EFFORTS TO RETURN SERVICE AS SOON AS REASONABLY POSSIBLE.</p>

      <h4>8. Use of the Term “Partner”</h4>
      <p>The use of term “partner” on this Site and in any other materials is not intended to indicate that the “partners” are in a legal partnership with Smile Connect. Rather, the term is used in a marketing sense to show participation by the other parties involved in the Smile Connect products and services.</p>

      <h4>9. Termination</h4>
      <p>These terms and conditions of use constitute an agreement that is effective until terminated by Smile Connect at any time without notice. Smile Connect may terminate your right to use this Site immediately, without notice to you if, in the sole discretion of Smile Connect, you fail to comply with any of these terms and conditions of use or engage in any fraudulent activity. In the event of termination, you are no longer authorized to access the benefits of this Site; and the restrictions imposed on you with respect to material copied or downloaded, the disclaimers and limitations of liabilities set forth in these terms and conditions of use, shall survive the termination of this agreement. These terms and conditions of use together with the Privacy Policy of the Site constitute the entire agreement between you and Smile Connect related to your use and access to this Site.</p>

      <h4>10. Governing Law</h4>
      <p>This agreement is governed by and shall be construed in accordance with the laws of the Province of Ontario and the federal laws of Canada without giving effect to any principles of conflicts of law. You agree to bring any claims against Smile Connect exclusively in the courts of the Province of Ontario. If any provision of these Terms of Use proves unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this agreement and will not affect the validity and enforceability of any remaining provisions.</p>

      <h4>11. Compliance with Laws</h4>
      <ol type="a" start="27">
        <li>
          You agree that your use of the Site shall not violate any applicable local, national or international law, including but not limited to any regulations having the force of law. Some jurisdictions may have restrictions on the use of the Internet by their residents.
        </li>
      </ol>

      <ol type="a" start="54">
        <li>
          You agree not to impersonate another person in your use of the Site including without limiting the generality of the foregoing any content of a review submitted.
        </li>
      </ol>

      <h4>12. Assignment</h4>
      <p>You do not have any right to assign this Agreement or any of your rights to the Services to anyone. Smile Connect may assign any or all of its rights and obligations under this Agreement or to the Services to any third party. At the election of Smile Connect, if Smile Connect’s obligations herein are assumed by a third party, Smile Connect shall be relieved of any and all obligations and liability under this Agreement.</p>

      <h4>13. Viruses</h4>
      <p>While every effort is made to ensure that all information provided at the site does not contain computer viruses, you should take reasonable and appropriate precautions to scan for computer viruses and should ensure that you have a complete and current backup of the applicable items of information contained on your computer system. You should pay specific attention to some of the newer viruses that have been written to automatically execute when an infected word processing document is loaded into certain word processing programs.</p>

      <h4>14. Service Providers</h4>
      <p>To provide and process the services to you, Smile Connect shares your personally identifiable information with our authorized service providers that perform certain services or functions on our behalf.</p>

      <h4>15. Our Obligations</h4>
      <p>Smile Connect may be required by law to disclose information you have provided in using any of the services on the Website. Smile Connect may also disclose information where someone poses a threat to Smile Connect’s interests (such as user fraud) or whose activities could bring harm to others. In addition, it may be necessary in the reasonable opinion of officials of Smile Connect that certain information be provided to third parties, such as law enforcement authorities or governing bodies for a particular health care provider. In the event that Smile Connect receives a court order to disclose information on the Website about you or your activities on the Website, you agree that such information may be disclosed.</p>

      <h4>16. Other Web Sites</h4>
      <ol type="a" start="81">
        <li>
        There are several places throughout Smile Connect that may link you to other websites that do not operate under Smile Connect’s information privacy practices. When you click through to these websites, Smile Connect information privacy practices no longer apply. We recommend that you examine the privacy statements for all third party Web sites to understand their procedures for collecting, using, and disclosing your information.
        </li>
      </ol>
      <ol type="a" start="108">
        <li>
        You have the option of sending e-mail to Customer Support requesting a change to the information you provided to us or to receive a copy of the information we have stored. We will make every reasonable effort to honour your wishes. Proof of identity may be required.
        </li>
      </ol>

      <h4>17. Surveys and Disclosure of Information</h4>
      <ol type="a" start="135">
        <li>
        To help us improve Smile Connect and serve you better, from time to time we may e-mail surveys to you to learn more about your experience with our site. You are under no obligation to respond to these surveys, and you may unsubscribe from receiving them by following the unsubscribe instructions in any e-mail survey you receive.
        </li>
      </ol>
      <ol type="a" start="162">
        <li>
        Smile Connect may contract a reputable third party to facilitate the distribution of survey, promotion, or sweepstakes programs. Upon completion of these projects, all information is returned to Smile Connect.
        </li>
      </ol>
      <ol type="a" start="189">
        <li>
        We reserve the right to disclose certain information about your usage of the Site in a form that does not disclose your identity.
        </li>
      </ol>

      <h4>18. Request Customer Support or Submit Questions/Suggestions</h4>
      <p>When you request assistance with viewing your profile, logging on to the Smile Connect Web site, or accessing your account, we will respond to you via e-mail or telephone.</p>

      <h4>19. Privacy</h4>
      <p>We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. We view protection of users’ privacy as a very important community principle. We understand clearly that you and your information are one of our most important assets. We store and process your information on computers located in Canada or the United States that are protected by physical as well as technological security devices. Our current Privacy Policy is available on the Site. If you object to your information being transferred or used in this way, please do not use the Service.</p>

      <h4>20. Liability Limit</h4>
      <p>IN NO EVENT SHALL WE, OUR SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES OR OUR SUPPLIERS BE LIABLE FOR LOST PROFITS OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH OUR SITE, OUR SERVICES OR THIS AGREEMENT, HOWEVER ARISING, INCLUDING NEGLIGENCE. Neither Smile Connect, its parent, nor its affiliated or related entities or its content providers are responsible or liable to any person or entity whatsoever for any direct or indirect loss, damage (whether actual, consequential, punitive, special or otherwise), injury, claim, liability or other cause of any kind or character whatsoever based upon or resulting from any information or reviews, ratings or opinions provided on the Smile Connect site or from the use of, or the inability to use Smile Connect materials. You specifically agree that Smile Connect is not liable for any defamatory, offensive, or illegal conduct of any user. If you are dissatisfied with the Smile Connect site or any materials on the site, or with any of Smile Connect’s terms and conditions of use or the Smile Connect Privacy Policy, your sole and exclusive remedy is to discontinue using the Site.</p>

      <h4>21. Indemnity</h4>
      <p>You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, officers, directors, agents, and employees, harmless from any claim or demand, including reasonable legal fees, made by any third party due to or arising out of your breach of this Agreement or the documents it incorporates by reference, or your violation of any law or the rights of a third party.</p>

      <h4>22. No Agency</h4>
      <p>You and Smile Connect are independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this Agreement.</p>

      <h4>23. Changes to These Terms of Use</h4>
      <p>Smile Connect reserves the right, at any time, to modify, alter, or update these Terms and Conditions of Use by posting the modified, altered or updated terms on the Site, and you agree to be bound by such modifications, alterations or updates. If you do not agree to such modifications, alterations or updates, you should terminate your use of the Services. Your continued use of any of the Services following the posting of notice of any changes in this Agreement shall constitute your acceptance of such modifications, alterations and updates.</p>
      <br/>
      <br/>
      </Box>
    </Box>
  )
  }
}
