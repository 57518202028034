import React, { useState, useEffect, useContext } from 'react'
import pick from 'lodash/pick'
import { format } from 'date-fns'
import Dropdowns from '../components/Dropdowns'
import { 
  PROCEDURES, 
  OFFICE_AMENITIES, 
  INSECURE_AND_PAYMENTS,
  PRACTICE_TECHNOLOGY,
  HOURS_APPOINTMENTS,
  BADGES,
  ABOUT_DENTIST,
} from '../constants'
// import { format } from 'date-fns'
import {
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  Button,
  Grid,
  Box,
  Paper,
} from '@material-ui/core'
import BadgeItem from '../components/BadgeItem'
import AppointmentForm from '../components/AppointmentForm'
import WhiteCard from '../components/WhiteCard'
import StaticText from '../components/StaticText'
import { PersonalCard } from '../components/PersonalCard'
import ReviewCard from '../components/ReviewCard'
import OfficePhoto from '../components/OfficePhoto'
import LocationCard from '../components/LocationCard'
import GoogleMapReact from 'google-map-react'
import Loader from '../components/Loader'
import StyledText from '../components/StyledText'
import { Helmet } from 'react-helmet'
import { FirebaseContext } from '../utils/base'
import GiftForm from '../components/GiftCardForm'

const BOOTSTRAP_KEYS = {
  key: process.env.REACT_APP_FIREBASE_KEY,
  libraries: ['places', 'distance-matrix'],
}

function formatted_time(str) {
  var timeString = str;
  timeString = `${str.slice(0, 2)}:${str.slice(2)}`

  const d = new Date('1970-01-01 '+ timeString);

  var hr = d.getHours();
  var min = d.getMinutes();
  var ampm = 'am';
  if (hr > 12) {
    hr -= 12;
    ampm = 'pm';
  } else if (hr === 12) {
    ampm = 'pm';
  }

  if (min < 10) {
    min = '0' + min;
  }

  return hr + ':' + min + ' ' + ampm;
}

const ProviderShow = ({ currentUser, results, match, history, providers, patients, searchedDentists, patientData }) => {
    const params_provider = match.params.provider.split('&-&')

    console.log(match.params.provider)
    console.log(params_provider)
    console.log(results)
    
  const provider =
    providers.find(r => (r.name === params_provider[0] && r.address.replace(/#/g, '') === params_provider[1])) ||
    results.find(r => r.name === params_provider[0] && r.address.replace(/#/g, '') === params_provider[1]) || searchedDentists.find(r => r.name === params_provider[0] && r.address.replace(/#/g, '') === params_provider[1])

    console.log('----- provider -----')
    console.log(provider)
    
  if (!provider) {
    return null
  }


  return (
    <ProviderShowView
      history={history}
      patient={currentUser}
      provider={provider}
      providers={providers}
      patients={patients}
      showAppointment={!!match.params.appointment}
      patientData={patientData}
    />
  )
}

export default ProviderShow

function ProviderShowView({ provider, patient, history, showAppointment, patientData, providers, patients }) {
  const [openTease, setOpenTease] = useState(!patient && showAppointment)

  const firebase = useContext(FirebaseContext)

  if (patient && showAppointment) {
    const appointment = {
      patient: patient.email,
      provider: provider.email || provider.name,
      date: format(new Date(), 'yyyy-MM-dd'),
      pending: 0,
    }

    firebase.db.collection('appointments').add(appointment)

    history.push(`/appointments/nextSteps`);
  }
  
  return (
    <>
      {/* <TeaseSignupModal
        open={openTease}
        onClose={() => setOpenTease(false)}
        onClickSignUp={() =>
          history.push(`/signup?redirect=/search/${provider.uid}`)
        }
      /> */}

      {openTease ? (
        <AppointmentForm
          provider={provider}
          patient={patient}
          providers={providers}
          patients={patients}
          setConfirmed={() => history.push(`/appointments/nextSteps`)}
          history={history}
          patientData={patientData}
        />
      ) : (
        <ProviderShowDetails
          sprovider={provider}
          onClick={() => {
            if (patient) {
              history.push(`/search/${provider.name}&-&${provider.address.replace(/#/g, '')}/appointment`)
              setOpenTease(true)
            } else {
              setOpenTease(true)
            }
          }}
        />
      )}
    </>
  )
}

const Hours_Label = {
  fontWeight: '500',
  textTransform: 'uppercase',
  width: '3em',
  fontSize: '12px',
  lineHeight: '18px',
  color: '#9e9a9f',
  display: 'inline-flex',
}

const Hours_Time = {
  fontSize: '11.9px',
  lineHeight: '17px',
  fontWeight: '400',
  display: 'inline-flex'
}

function ProviderShowDetails({ sprovider, onClick }) {
  const weekday = [
    "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"
  ]

  var currentProvider = sprovider;
  const [current, setCurrent] = useState()

  useEffect(() => {
    if (currentProvider) {
      if (currentProvider.place_id) {
        GoogleMapReact.googleMapLoader(BOOTSTRAP_KEYS).then(maps => {
          const placesService = new maps.places.PlacesService(
            new maps.Map(document.createElement('div'), {
              zoom: 8,
              center: { lat: -34.397, lng: 150.644 },
            })
          )

          placesService.getDetails({placeId: currentProvider.place_id}, placeResult => {
              currentProvider.website = placeResult.website;

              if (placeResult.reviews)
              {
                  currentProvider.review = placeResult.reviews.map(p => ({reviewRating: p.rating,reviewText: p.text, reviewerName: p.author_name}));
              }
              else
              {
                  currentProvider.review = [];
              }
              
              currentProvider.hours = [];
              
              for (let i = 1; i <= 7; i ++)
              {
                  const result = placeResult.opening_hours ? placeResult.opening_hours.periods.find(weekday => weekday.open.day === i) : undefined
                  
                  if (!result)
                  {                      
                      currentProvider.hours.push({availability: false})
                  }
                  else
                  {
                      currentProvider.hours.push({availability: true, start: formatted_time(result.open.time), end: formatted_time(result.close.time)})
                  }
              }

              if (!placeResult.opening_hours)
              {
                  currentProvider.hours.push({availability: false})
              }

              setCurrent(currentProvider)
          })
        })
      } else {
        setCurrent(currentProvider)
      }
    }
    // eslint-disable-next-line
  }, [])


  if (!current) return ( <Loader/> )

  const provider = current;

  const office_photos = provider.office_images || []

  var badge_arr = provider ? Object.entries(pick(provider, Object.keys(BADGES))).filter(([label, value]) => value === 'true' || value === true) : []

  const badge_key = Object.keys(BADGES)

  if (badge_arr.length > 0) {
    badge_arr = badge_arr.map(([label, value]) => [label, value, badge_key.indexOf(label)])
  }

  // ############### Boolean array for display cards ################### //
  var display_marker = []
  display_marker.push(true)
  display_marker.push(provider.Biography && provider["Biography Card"] !== false)
  display_marker.push(provider["Why do patients love your practice?"] && provider["Why Love Card"] !== false)
  display_marker.push((provider["Top 3 treatments you offer in your practice1"] || provider["Top 3 treatments you offer in your practice2"] || provider["Top 3 treatments you offer in your practice3"]) && provider["Top 3 Card"] !== false)
  display_marker.push(Object.entries(pick(provider, Object.keys(PROCEDURES))).filter(([label, value]) => value === 'true' || value === true).length !== 0 && provider["Procedures Card"] !== false)
  display_marker.push(Object.entries(pick(provider, Object.keys(ABOUT_DENTIST))).filter(([label, value]) => value !== '' && value !== undefined).length !== 0 && provider["About Card"] !== false)
  display_marker.push(Object.entries(pick(provider, Object.keys(OFFICE_AMENITIES))).filter(([label, value]) => value === 'true' || value === true).length !== 0 && provider["Office Amenities Card"] !== false)
  display_marker.push(Object.entries(pick(provider, Object.keys(INSECURE_AND_PAYMENTS))).filter(([label, value]) => value === 'true' || value === true).length !== 0 && provider["Insurance and Payments Card"] !== false)
  display_marker.push(provider["Hours Card"] !== false)
  display_marker.push(Object.entries(pick(provider, Object.keys(BADGES))).filter(([label, value]) => value === 'true' || value === true).length !== 0 && provider["Badge Card"] !== false)
  display_marker.push(provider["Our location Card"] !== false)
  display_marker.push(office_photos.length !== 0 && provider["Office photos Card"] !== false)
  display_marker.push(provider.review && provider["Reviews Card"] !== false)
  display_marker.push(Object.entries(pick(provider, Object.keys(PRACTICE_TECHNOLOGY))).filter(([label, value]) => value === 'true' || value === true).length !== 0 && provider["Practice Technology Card"] !== false)

  // ############### Boolean array for booking cards ################ //
  var display_booking = []
  var flag = true
  display_marker.forEach(val => {
    if (val) {
      display_booking.push(flag)
      flag = !flag
    } else {
      display_booking.push(false)
    }
  })

  return (
    
    <Box maxWidth={1400} mx="auto">
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>

          {/* Description Card */}
          <WhiteCard>
            <PersonalCard 
            provider={provider}
            >
            {/* Action Buttons */}
              {/* <Grid container spacing={3}>
                <Grid item md={6} sm={6} xs={12}>
                  <Button variant="contained" style={{
                    width: '100%',
                    height: '60px',
                    backgroundColor: '#0D1C86',
                    color: 'white',
                    marginTop: 10,
                  }}
                  onClick={onClick}>Request an Appointment</Button>
                </Grid>
              </Grid> */}
            </PersonalCard>

            <Bookingcard
              name={provider.name}
              onClick={onClick}
            ></Bookingcard>
          </WhiteCard>

          

          {/* Biography Card */}
          { 
            display_marker[1] &&
            <WhiteCard title={"About US"}>
            {
              provider.Biography && provider.Biography.split("\n").map(item => {
                return (<StaticText key={`biography-${item}`}>
                  {item}
                </StaticText>)
              })
            }
            {
              display_booking[1] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
            </WhiteCard>
          }

          {/* Why Card */}
          {
            display_marker[2] &&
          <WhiteCard title={"Why patients love our practice?"}>
            {
              provider["Why do patients love your practice?"] && provider["Why do patients love your practice?"].split("\n").map((item, index) => {
                return (<StaticText key={`Why-${index}`}>
                  {item}
                </StaticText>)
              })
            }
            {
              display_booking[2] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
          </WhiteCard>
          }

          {/* Top3 Card */}
          {
            display_marker[3] &&
          <WhiteCard title={"Top Treatments Offered"}>
              {
                provider["Top 3 treatments you offer in your practice1"] && provider["Top 3 treatments you offer in your practice1"].split('\n').map(item => {
                  return (
                    <StaticText key={`Top1-${item}`}>
                    {item}
                    </StaticText>
                )})
              }{
                provider["Top 3 treatments you offer in your practice2"] && provider["Top 3 treatments you offer in your practice2"].split('\n').map(item => {
                  return (
                    <StaticText key={`Top2-${item}`}>
                    {item}
                    </StaticText>
                )})
              }{
                provider["Top 3 treatments you offer in your practice3"] && provider["Top 3 treatments you offer in your practice3"].split('\n').map(item => {
                  return (
                    <StaticText key={`Top3-${item}`}>
                    {item}
                    </StaticText>
                )})
              }
              {
                display_booking[3] &&
                <Bookingcard
                  name={provider.name}
                  onClick={onClick}
                ></Bookingcard>
              }
          </WhiteCard>
          }

          {/* Procedures Card */}
          { 
            display_marker[4] &&
          <WhiteCard title={"Procedures"}>
            <Dropdowns
              boxes={Object.entries(pick(provider, Object.keys(PROCEDURES))).filter(([label, value]) => value === 'true' || value === true).map(
                ([label, value]) => ({ label, value: !!value })
              )}
            />
            {
              display_booking[4] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
          </WhiteCard>
          }

          {/* About the dentist Card */}
          { display_booking[5] &&
          <WhiteCard title={"About the dentist"}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <StyledText value={provider["Year of graduation"]} label="Year of graduation"></StyledText>
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <StyledText value={provider["School name"]} label="School"></StyledText>
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <StyledText value={provider["RCDSO certification year"]} label="RCDSO certification year "></StyledText>
              </Grid>

              <Grid item lg={4} md={12} sm={12} xs={12}>
                <StyledText value={provider["Specialty"]} label="Specialty"></StyledText>
              </Grid>
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <StyledText value={provider["Memberships and certifications"]} label="Memberships and certifications"></StyledText>
              </Grid>
            </Grid>

            {
              display_booking[5] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
          </WhiteCard>
          }

          {/* Office Amenities Card */}
          { display_marker[6] &&
          <WhiteCard title={"Office Amenities"}>
            <Dropdowns
              boxes={Object.entries(pick(provider, Object.keys(OFFICE_AMENITIES))).filter(([label, value]) => value === 'true' || value === true).map(
                ([label, value]) => ({ label, value: !!value })
              )}
            />

            {
              display_booking[6] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
          </WhiteCard>
          }

          {/* Insurance and Payments Card */}
          { display_marker[7] &&
          <WhiteCard title={"Insurance and Payments"}>
            <Dropdowns
              boxes={Object.entries(pick(provider, Object.keys(INSECURE_AND_PAYMENTS))).filter(([label, value]) => value === 'true' || value === true).map(
                ([label, value]) => ({ label, value: !!value })
              )}
            />
            {
              display_booking[7] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
          </WhiteCard>
          }


        {/* Hours Card */}
        {
          display_marker[8] &&
          <WhiteCard title={"Hours"}>
            <Grid container spacing={2}>
              { Object.entries(pick(provider, Object.keys(HOURS_APPOINTMENTS))).filter(([label, value]) => value === 'true' || value === true).length !== 0 &&
              <Grid item md={6}>
                <Dropdowns
                    boxes={Object.entries(pick(provider, Object.keys(HOURS_APPOINTMENTS))).filter(([label, value]) => value === 'true' || value === true).map(
                      ([label, value]) => ({ label, value: !!value })
                    )}
                    onlyone={true}
                />
              </Grid>
              }

              <Grid item md={6}>
              {
                weekday.map((day, index) => {
                    if (provider.hours[index].availability === 'false' || provider.hours[index].availability === false) return (<div key={`day-${index}`}/>);
                    return (<div key={`day-${index}`}>
                      <p style={Hours_Label}>{day}</p>
                      <p style={Hours_Time}>{provider.hours[index].start} - {provider.hours[index].end}</p>
                    </div>)
                })
              }
              </Grid>
            </Grid>
            
            {
              display_booking[8] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
          </WhiteCard>
        }

      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        {/* Badges Card */}
        { display_marker[9] &&
        <WhiteCard title={"Badges"}>
          {/* <BadgeCard provider={provider} /> */}
            <Grid container direction="row" style={{marginBottom: '10px', color: '#301EA1'}}>
            {
              badge_arr.map(([label, value, val]) => (
                <Grid item lg={2} md={3} sm={3} xs={4} key={`badge_${label}_${val}`}>
                  <BadgeItem badge={val}/>
                </Grid>
              )) 
            }
          </Grid>

          {
              display_booking[9] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
        </WhiteCard>
        }

        {/* Our location Card */}
        {
          display_marker[10] &&
        <WhiteCard title={"Our location"}>
          <LocationCard location={provider.address}></LocationCard>

          {
              display_booking[10] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
        </WhiteCard>
        }

        {/* Office photos Card */}
        { display_marker[11] &&
        <WhiteCard title={"Office photos"}>
            <Grid container spacing={4}>
              {office_photos.map((image, index) => (
                <Grid item lg={6} md={6} sm={6} xs={12} key={`image-${index}`}>
                  <OfficePhoto
                    image={image}
                  />
                </Grid>
              ))}
            </Grid>
            {
              display_booking[11] &&
              <Bookingcard
                name={provider.name}
                onClick={onClick}
              ></Bookingcard>
            }
        </WhiteCard>
        }

        {/* Reviews Card */}
        { display_marker[12] && (provider.review.length !== 0) &&
        <WhiteCard title={"Reviews"}>
          {
            (provider.review.length !== 0) && provider.review.map((item, index) => {
              return (
                <ReviewCard
                key={`Reviews-${index}`}
                reviewText= {item.reviewText}
                reviewRating={item.reviewRating}
                reviewerName={item.reviewerName}/>
              )
            })
          }
          {
            display_booking[12] &&
            <Bookingcard
              name={provider.name}
              onClick={onClick}
            ></Bookingcard>
          }
        </WhiteCard>
        }


        {/* Practice Technology Card */}
        { display_marker[13] &&
        <WhiteCard title={"Practice Technology"}>
          <Dropdowns
              boxes={Object.entries(pick(provider, Object.keys(PRACTICE_TECHNOLOGY))).filter(([label, value]) => value === 'true' || value === true).map(
                ([label, value]) => ({ label, value: !!value })
              )}
          />
          {
            display_booking[13] &&
            <Bookingcard
              name={provider.name}
              onClick={onClick}
            ></Bookingcard>
          }
        </WhiteCard>
        }
      </Grid>

    </Grid>
    </Box>
    // <>
    //   <Box display="flex" mx={-1} flexWrap="wrap">
    //     <Paper style={{ margin: 8, padding: 16, flex: 1, minWidth: 300 }}>
    //       <Typography variant="h5" style={{ marginBottom: 16 }}>
    //         Book through SmileConnect and get a $100 giftcard
    //       </Typography>
    //       <Button variant="outlined" color="primary" onClick={onClick}>
    //         Book Invisalign consult
    //       </Button>
    //     </Paper>

    //     <Paper style={{ margin: 8, padding: 16, flex: 1, minWidth: 300 }}>
    //       <Typography
    //         variant="h5"
    //         style={{
    //           marginBottom: 16,
    //         }}>
    //         Book through SmileConnect and get a $100 giftcard
    //       </Typography>
    //       <Button variant="outlined" color="primary" onClick={onClick}>
    //         Book general cleaning
    //       </Button>
    //     </Paper>
    //   </Box>
  )
}

function Bookingcard({name, onClick}) {
  return (
    <Box my={1}>
      <Helmet>
        <title>{`${name} - Appointments - SmileConnect`}</title>
        <meta name="description" content={`${name}. See their Invisalign team, read patient reviews, and make an appointment online for free 24/7.`} />
        <meta name="keywords" content="top, dentists" />
      </Helmet>
        <Paper style={{backgroundColor: '#f4f4f4', boxShadow: 'none'}}>
            <Box p={2}>
                <h2 style={{color: '#301EA1' , fontSize: '1.5rem', textAlign: 'left'}}>Interested in booking with {name}?</h2>
                <Grid container spacing={3}>
                  <Grid item md={6} sm={6} xs={12}>
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '50px',
                      backgroundColor: '#301EA1',
                      color: 'white',
                      marginTop: 10,
                    }}
                    onClick={onClick}>Request a booking</Button>
                  </Grid>
                </Grid>

                <GiftForm/>
                <p><a style={{ color: 'blue'}} href="/#/terms">Terms and conditions</a> apply. Upon successful treatment completion</p>
            </Box>
        </Paper>
    </Box>
  )
}

// function TeaseSignupModal({
//   date = new Date(),
//   open = false,
//   onClose,
//   onClickSignUp,
// }) {
//   return (
//     <Dialog onClose={onClose} open={open}>
//       <DialogTitle>Sign up</DialogTitle>

//       <DialogContent>
//         <p>
//           In order to book your appointment for:{' '}
//           <b>{format(date, 'MMMM do hh:mm a')}</b>
//         </p>
//       </DialogContent>

//       <DialogContent>
//         <p>Please create an account now</p>
//       </DialogContent>

//       <DialogActions>
//         <Button onClick={onClickSignUp}>Ok</Button>
//         <Button onClick={onClose} color="primary">
//           Cancel
//         </Button>
//       </DialogActions>
//     </Dialog>
//   )
// }
