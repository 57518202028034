import React, { useCallback, Component } from 'react'
import { 
  Typography, 
  Box,
  Grid,
} from '@material-ui/core'
import { useDropzone } from 'react-dropzone'

import { APP_BAR_HEIGHT } from '../constants'
import './QuizQuestion.css'

class QuizQuestion extends Component{

  constructor(props){
    super(props);
    this.myRef = React.createRef()
  }

  componentDidMount() {
    this.myRef.current.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        className="quiz-question"
        style={{
          opacity: this.props.active ? 1 : 0,
          transition: 'opacity 400ms ease-in-out',
          pointerEvents: this.props.active ? 'all' : 'none',
          position: 'absolute',
          // marginTop: -APP_BAR_HEIGHT,
          marginTop: `calc(${APP_BAR_HEIGHT} + 20px)`,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          transitionDelay: 200,
          //background: `linear-gradient(180deg, #23A2BE 0%, #0097B6 100%)`,
          height: 'fit-content', 
          minHeight: `100vh`,
          overflowY: 'auto'
        }}>
          <Grid container className="grid-container-quiz" style={{height: '100%'}}>
            <Grid item md={8} sm={12} xs={12} className="quiz-container" ref={this.myRef}>
              {this.props.question.heading && (
                <h2 className="quiz-quesion__heading__text">{this.props.question.heading}</h2>
              )}
              {this.props.children}
            </Grid>
            {/* <Grid item md={4} sm={12} xs={12} className="quiz-question__back">
              
            </Grid> */}
          </Grid>
      </Box>
    )
  }
}

export default QuizQuestion

export const AddPhotoThumbnail = ({
  callback,
  label = 'Add photo',
  width = 120,
  circle,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(callback),
  })

  return (
    <Box
      {...getRootProps()}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={width}
      height={width}
      m={2}
      border="1px dashed #90A5B9"
      style={{ borderRadius: circle ? '50%' : ''}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="3 3 20 20">
        <path
          fill="#90A5B9"
          d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
      <Typography style={{ color: '#90A5B9', marginTop: 5 }}>
        {label}
      </Typography>
      <input {...getInputProps()} />
    </Box>
  )
}
